<template>
  <div class="boutonOptionGM">
    <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouter"/>
  </div>
<!-- Nouveau -->
  <div v-if="ajouterForm" class="tile is-ancestor" style="margin-bottom: 3vh;">
    <div class="tile is-12 is-vertical is-parent">
      <div class='une tile is-child box nouveau'>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Nom</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                <input type='texte' class='input' placeholder="Nom" v-model="nouveauNom">
                <span class="icon is-left">
                  <i class="fad fa-signature"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Prénom</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                <input type='texte' class='input' placeholder="Prénom" v-model="nouveauPrenom">
                <span class="icon is-left">
                  <i class="fad fa-signature"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Email</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control has-icons-left">
                <input type='email' class='input' placeholder="Email" v-model="nouveauEmail">
                <span class="icon is-left">
                  <i class="fad fa-at"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="field is-horizontal">
          <div class="field-label is-normal">
            <label class="label">Biographie</label>
          </div>
          <div class="field-body">
            <div class="field">
              <div class="control">
                <textarea class='textarea' placeholder="Biographie" v-model="nouveauBio"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<!-- Liste -->
  <h1 v-if="liste.length === 0" class="has-text-centered subtitle rien">Aucune personne dans l'annuaire du groupe !</h1>
  <article v-else class="columns is-multiline">
    <div
      v-for="membre in liste"
      :key="membre"
      class="column unSeul is-12-tablet is-4-desktop"
      @click="voirProfil(membre)">
      <div class="columns is-vcentered">
        <div class="column">
          <p>{{ membre.prenom }} {{ membre.nom.toUpperCase() }}</p>
        </div>
        <div class="column" style="height: 7rem;">
          <div v-if="membre.competences.length > 0" class="tags">
            <span
            v-for="tag in membre.competences.slice(0,3)"
            :key="tag"
            class="tag is-dark is-normal"
            > {{ tag }}</span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios'
import Ajouter from '@/components/Options/Ajouter.vue'

export default {
  name: 'AnnuaireGroupe.vue',
  components: {
    Ajouter
  },
  emits: ['afficher-profil'],
  data () {
    return {
      liste: [],
      ajouterForm: false,
      nouveauNom: '',
      nouveauPrenom: '',
      nouveauEmail: '',
      nouveauBio: ''
    }
  },
  created: function () {
    this.$store.commit('chargement')
    axios.post(this.$store.getters.url + 'back/Annuaire/ChargerAnnuaire.php?i=' + this.$store.getters.numMembre, {
      groupe: this.$store.getters.infoMembre('groupe')
    }).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.liste = reponse.data.donnees
        this.trier()
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  methods: {
    voirProfil: function (membre) {
      this.$emit('afficher-profil', membre)
    },
    trier: function () {
      this.liste.sort(function (a, b) {
        return a.nom.toLowerCase() < b.nom.toLowerCase() ? -1 : a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : 0
      })
    },
    ajouterAnnuaire: function (membre, utilisateur) {
      this.$store.commit('chargement')
      const lien = utilisateur === 1 ? 'back/Annuaire/AjouterAnnuaire.php?i=' : 'back/Annuaire/AjouterAnnuaireNouveau.php?i='
      axios.post(this.$store.getters.url + lien + this.$store.getters.numMembre, {
        groupe: this.$store.getters.infoMembre('groupe'),
        utilisateur: utilisateur,
        info: membre
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          if (utilisateur === 1) {
            this.liste.push(membre)
          } else {
            this.liste.push({
              numero_membre: reponse.data.nouveauId,
              adresse_mail: membre.email,
              statut: 4,
              nom: membre.nom,
              prenom: membre.prenom,
              avatar: null,
              biographie: membre.biographie,
              competences: [],
              annuaire: true
            })
            this.annulerAjout()
            this.$refs.ajouter.validerOk()
          }
          this.trier()
          this.$store.commit('afficherNotification', { affiche: true, texte: membre.prenom + ' ajouté(e) à l\'annuaire du groupe', type: 'ok' })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    supprimerAnnuaire: function (membre) {
      this.$store.commit('chargement')
      let utilisateur = 1
      if (membre.statut === 4) {
        utilisateur = 0
      }
      axios.post(this.$store.getters.url + 'back/Annuaire/SupprimerAnnuaire.php?i=' + this.$store.getters.numMembre, {
        groupe: this.$store.getters.infoMembre('groupe'),
        utilisateur: utilisateur,
        info: membre
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          for (var i = 0; i < this.liste.length; i++) {
            if (this.liste[i].numero_membre === membre.numero_membre) {
              this.liste.splice(i, 1)
              break
            }
          }
          this.$store.commit('afficherNotification', { affiche: true, texte: membre.prenom + ' supprimé(e) de l\'annuaire du groupe', type: 'ok' })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouveauNom = ''
      this.nouveauPrenom = ''
      this.nouveauEmail = ''
      this.nouveauBio = ''
    },
    verifForm: function () {
      let i = 0
      let message = ''
      if (this.nouveauNom === '') {
        i++
        message += 'Aucun nom.'
      }
      if (this.nouveauPrenom === '') {
        i++
        message += 'Aucun prenom.'
      }
      if (this.nouveauEmail === '') {
        i++
        message += 'Aucun email.'
      } else {
        if (!this.$store.state.regex.email.test(this.nouveauEmail)) {
          i++
          message += 'Email non conforme.'
        }
      }
      if (this.nouveauBio === '') {
        i++
        message += 'Aucune biographie.'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    validerAjout: function () {
      if (this.verifForm()) {
        const info = {
          nom: this.nouveauNom,
          prenom: this.nouveauPrenom,
          email: this.nouveauEmail,
          biographie: this.nouveauBio
        }
        this.ajouterAnnuaire(info, 0)
      }
    }
  }
}
</script>

<style scoped>
.boutonListe {
  text-align: center;
}
.unSeul > div {
  padding: 0.3rem!important;
  cursor: pointer;
}
@media (prefers-color-scheme: light) {
  .unSeul > div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
.unSeul > div {
  height: 100%;
  border-radius: 6px;
}
.tags {
  margin-left: 0.5rem;
  display: grid!important;
}
.tag {
  margin-right: 0!important;
}
.rien {
  margin-top: 7vh;
}
</style>
