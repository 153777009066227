<template>
  <div class="field">
    <div class="control">
      <input @change="changeText($event)" :id="switchId" type="checkbox" class="switch is-rounded is-outlined" :checked='valeur'>
      <label :for="switchId"> {{ text }}</label>
    </div>
  </div>
</template>

<script>
import 'bulma-switch/dist/css/bulma-switch.min.css'

export default {
  name: 'SwitchLive',
  props: ['html', 'etat', 'switchId'],
  emits: ['changerValeur'],
  data () {
    return {
      text: this.html[this.etat],
      valeur: this.etat
    }
  },
  watch: {
    etat: function (val, oldVal) {
      this.valeur = val
      this.text = this.html[val]
    }
  },
  methods: {
    changeText: function (e) {
      if (e.target.checked) {
        this.text = this.html[1]
        this.valeur = 1
      } else {
        this.text = this.html[0]
        this.valeur = 0
      }
      this.getValeur()
    },
    getValeur: function () {
      this.$emit('changerValeur', { valeur: this.valeur, ref: this.switchId })
    }
  }
}
</script>
