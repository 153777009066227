<template>
  <transition name="fade">
    <div v-if="show" class="preloader">
      <div class="logo"></div>
      <p class="subtitle"><i>Chargement . . .</i></p>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'preloader',
  data () {
    return {
      show: true
    }
  },
  mounted: function () {
    if (this.show) this.showToggle()
  },
  methods: {
    showToggle () {
      setTimeout(() => {
        this.show = false
      }, 700)
    }
  }
}
</script>

<style>
.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.logo {
  width: 12.5rem;
  height: 12.5rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/images/site/loader.gif');
}
@media (prefers-color-scheme: light) {
  .preloader {
    background-color: hsl(0, 0%, 96%)!important;
  }
}
@media (prefers-color-scheme: dark) {
  .preloader {
    background-color: hsl(0, 0%, 10%)!important;
  }
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
