<template>
  <div class="modal is-active">
    <div class="modal-background" @click="fermerModal"></div>
    <div class="modal-content">
      <div class="boutonOptionGM">
        <div v-if="membre.annuaire !== 'membre'" class="option">
          <span v-if="!membre.annuaire" @click="ajouterAnnuaire" class="icon is-medium has-text-primary fauxLien" title="Ajouter à l'annuaire">
            <i class="fad fa-plus"></i>
          </span>
          <span v-else @click="supprimerAnnuaire" class="icon is-medium has-text-danger fauxLien" title="Supprimer de l'annuaire">
            <i class="fad fa-trash"></i>
          </span>
        </div>
      </div>
      <div class="columns is-centered" style="height: 35vh;">
        <div class="column is-half">
          <Avatar :avatar="yesavatar()" :initial="noavatar()" />
        </div>
        <div class="column is-half">
          <p><b>Nom :</b> {{ membre.nom }}</p>
          <p><b>Prénom :</b> {{ membre.prenom }}</p>
          <br>
          <p><b>Email :</b>
            <span @click="mailto" class="fauxLien icon is-medium has-text-info mailIcon">
              <i class="fad fa-2x fa-envelope"></i>
            </span>
          </p>
          <br>
          <p><b>Compétences :</b></p>
          <div class="tags" v-if="membre.competences.length > 0">
            <span
            v-for="tag in membre.competences"
            :key="tag"
            class="tag is-dark is-normal"
            > {{ tag }}</span>
          </div>
          <p v-else>Aucune compétence renseignée.</p>
        </div>
      </div>
      <br>
      <div class="columns is-centered">
        <div class="column is-12">
          <p class="has-text-centered"><b>Biographie</b></p>
          <div v-if="membre.biographie!=='' && membre.biographie!==null" v-html="membre.biographie"></div>
          <div v-else>Aucune biographie renseignée.</div>
        </div>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="fermerModal"></button>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
export default {
  name: 'VoirProfil',
  components: {
    Avatar
  },
  props: ['membre'],
  emits: ['fermer', 'ajouter-annuaire', 'supprimer-annuaire'],
  methods: {
    noavatar: function () {
      return this.membre.prenom + ' ' + this.membre.nom
    },
    yesavatar: function () {
      if (this.membre.avatar !== null) {
        return 'images/avatar/' + this.membre.avatar + '.png'
      } else {
        return null
      }
    },
    mailto: function () {
      window.location.href = 'mailto:' + this.membre.adresse_mail
    },
    fermerModal: function () {
      this.$emit('fermer')
    },
    ajouterAnnuaire: function () {
      this.$emit('ajouter-annuaire')
    },
    supprimerAnnuaire: function () {
      this.$emit('supprimer-annuaire')
      this.fermerModal()
    }
  }
}
</script>

<style>
.modal-content {
  border-radius: 6px;
  padding: 1rem;
  position: relative;
}
.modal-background {
  cursor: pointer;
}
.mailIcon {
  vertical-align: middle;
  margin-left: 0.5rem;
  height: 1.5rem!important;
}
@media (prefers-color-scheme: light) {
  .modal-content {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .modal-content {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
</style>
