<template>
  <div class="option">
    <span
      v-if="!active && !estPlanifier"
      @click="planifier"
      class="icon is-medium has-text-primary fauxLien has-tooltip-bottom"
      title='Planifier'>
      <i class="fad fa-calendar-day"></i>
    </span>
    <span
      v-if="!active && estPlanifier"
      @click="supprimer"
      class="icon is-medium has-text-danger fauxLien has-tooltip-bottom"
      title='Annuler la planification'>
      <i class="fad fa-calendar-times"></i>
    </span>
    <span
      v-if="active"
      @click="annuler"
      class="icon is-medium has-text-danger fauxLien has-tooltip-bottom"
      title="Annuler">
      <i class="fad fa-ban"></i>
    </span>
    <span v-if="active" style="width: 1vh;"></span>
    <span
      v-if="active"
      @click="valider"
      class="icon is-medium has-text-primary fauxLien has-tooltip-bottom"
      title="Valider la planification">
      <i class="fad fa-check"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Planifier',
  emits: ['planifier', 'annulerPlanification', 'validerPlanification', 'supprimerPlanification'],
  props: ['estPlanifier'],
  data () {
    return {
      active: false
    }
  },
  methods: {
    planifier: function () {
      this.active = true
      this.$emit('planifier')
    },
    annuler: function () {
      this.active = false
      this.$emit('annulerPlanification')
    },
    valider: function () {
      this.$emit('validerPlanification')
    },
    validerOk: function () {
      this.active = false
    },
    supprimer: function () {
      this.$emit('supprimerPlanification')
    }
  }
}
</script>

<style>
.option {
  display: inline-grid;
  height: 100%;
}
</style>
