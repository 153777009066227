<template>
  <div class="option">
    <span
      v-if="!active"
      @click="ajouter"
      class="icon is-medium has-text-primary fauxLien has-tooltip-bottom"
      title='Ajouter'>
      <i class="fad fa-plus"></i>
    </span>
    <span
      v-if="active"
      @click="annuler"
      class="icon is-medium has-text-danger fauxLien has-tooltip-bottom"
      title="Annuler l'ajout">
      <i class="fad fa-ban"></i>
    </span>
    <span v-if="active" style="width: 1vh;"></span>
    <span
      v-if="active"
      @click="valider"
      class="icon is-medium has-text-primary fauxLien has-tooltip-bottom"
      title="Valider l'ajout">
      <i class="fad fa-check"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Ajouter',
  emits: ['ajouter', 'annulerAjout', 'validerAjout'],
  data () {
    return {
      active: false
    }
  },
  methods: {
    ajouter: function () {
      this.active = true
      this.$emit('ajouter')
    },
    annuler: function () {
      this.active = false
      this.$emit('annulerAjout')
    },
    valider: function () {
      this.$emit('validerAjout')
    },
    validerOk: function () {
      this.active = false
    }
  }
}
</script>

<style>
.option {
  display: inline-grid;
  height: 100%;
}
</style>
