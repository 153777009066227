<template>
  <section class="Profil">
    <article>
      <h1 class="title has-text-centered">VOTRE PROFIL</h1>
    </article>
    <div class="boutonOptionGM">
      <Modifier @modifier="modifier" @annulerModification="annulerModification" @validerModification="validerModification" ref="profil" />
    </div>
    <article class="tile is-ancestor">
      <div class="tile is-4 is-vertical is-parent">
        <div class="tile is-child box">
          <h1 class="subtitle has-text-centered">Informations générales</h1>
          <!-- Nom -->
          <p v-if="!aModifier">
            <b>Nom :</b> {{ $store.state.membre.nom }}
          </p>
          <div v-else class="field">
            <div class="control has-icons-left">
              <input type='text' class='input' placeholder="Nom" v-model="$store.state.membre.nom">
              <span class="icon is-left">
                <i class="fad fa-signature"></i>
              </span>
            </div>
          </div>
          <!-- Prénom -->
          <p v-if="!aModifier">
            <b>Prénom :</b> {{ $store.state.membre.prenom }}
          </p>
          <div v-else class="field">
            <div class="control has-icons-left">
              <input type='text' class='input' placeholder="Prénom" v-model="$store.state.membre.prenom">
              <span class="icon is-left">
                <i class="fad fa-signature"></i>
              </span>
            </div>
          </div>
          <!-- Statut -->
          <p>
            <b>Statut dans le groupe :</b> {{ $store.getters.getStatut() }}
          </p>
          <!-- Date Inscription -->
          <p>
            <b>Date d'inscription :</b> {{ $store.state.membre.dateInscription }}
          </p>
        </div>
        <div class="tile is-child box">
          <h1 class="subtitle has-text-centered">Informations de connexion</h1>
          <!-- Adresse mail -->
          <p v-if="!aModifier">
            <b>Adresse email :</b> {{ $store.state.membre.adresseMail }}
          </p>
          <div v-else class="field">
            <div class="control has-icons-left">
              <input type='email' class='input' autocomplete="email" placeholder="Email" v-model="$store.state.membre.adresseMail">
              <span class="icon is-left">
                <i class="fad fa-at"></i>
              </span>
            </div>
          </div>
          <!-- Mot de passe -->
          <p v-if="!aModifier">
            <b>Mot de passe :</b> ************
          </p>
          <div v-else class="field">
            <div class="control has-icons-left">
              <input type='password' class='input' autocomplete="new-password" placeholder="Nouveau mot de passe" v-model="nouveauMdp">
              <span class="icon is-left">
                <i class="fad fa-key"></i>
              </span>
            </div>
          </div>
          <!-- Dernière fois changer Mdp -->
          <p>
            <b>Mot de passe changé il y a </b>
            <span :style="styleMdp($store.state.membre.dateDiffMdp)">{{ $store.state.membre.dateDiffMdp }} {{ jourS($store.state.membre.dateDiffMdp) }}</span>
          </p>
        </div>
        <div v-if="aModifier" class="tile is-child box">
          <h1 class="subtitle has-text-centered">Entrez votre mot de passe actuel pour valider :</h1>
          <form>
            <div class="field">
              <div class="control has-icons-left">
                <input type='password' class='input' autocomplete="current-password" placeholder="Mot de passe actuel" v-model="ancienMdp">
                <span class="icon is-left">
                  <i class="fad fa-key"></i>
                </span>
              </div>
            </div>
          </form>
        </div>
        <div class="tile is-child box">
          <h1 class="subtitle has-text-centered">S'ajouter dans un groupe</h1>
          <div class="field">
            <div class="control has-icons-left">
              <input type='text' class='input' placeholder="Code du groupe" v-model="codeGroupe">
              <span class="icon is-left">
                <i class="fad fa-plus"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="tile is-4 is-vertical is-parent">
        <div class="tile is-child box profilAvatar">
          <Avatar :avatar="$store.state.membre.avatar" :initial="$store.state.membre.prenom + ' ' + $store.state.membre.nom"/>
          <div v-if='aModifier'>
            <UploadImage :inputId="'Profil_Avatar'" :position="'abs'" @uploadOk="avatarNew" @deleteOk="avatarDelete" @backOk='avatarAncien' ref="changerAvatar"/>
          </div>
        </div>
        <div class="tile is-child box">
          <h1 class="subtitle has-text-centered">Information personnelle</h1>
          <!-- Type du profil -->
           <p v-if="!aModifier">
            <b>Profil :</b> {{ $store.state.membre.public === 0 ? 'Privé' : 'Public' }}
          </p>
          <div v-else>
            <Switch :html="['Privé', 'Public']" :etat="$store.state.membre.public" ref="switch" switchId="switchPublic"/>
          </div>
          <!-- Compétences -->
          <p class="has-text-centered"><b>Compétences :</b></p>
          <div v-if="!aModifier">
            <div v-if="$store.state.membre.competences.length > 0" class="tags">
              <span
              v-for="tag in $store.state.membre.competences"
              :key="tag"
              class="tag is-dark"
              > {{ tag.competence }}</span>
            </div>
            <p v-else>Aucune compétence renseignée.</p>
          </div>
          <div v-else>
            <TagsInput :tags="$store.state.membre.competences" ref="tagsinput"/>
          </div>
          <!-- Biographie -->
          <p class="has-text-centered"><b>Biographie :</b></p>
          <div v-if="!aModifier && $store.state.membre.biographie!==''" data="texte" v-html="$store.state.membre.biographie"></div>
          <div v-else-if="!aModifier && $store.state.membre.biographie==='' && membre.biographie!==null">Aucune biographie.</div>
          <div v-else class="field">
            <div class="control">
              <div class='textarea' @focusout="updateText($event)" contenteditable="true" placeholder="Biographie" v-html="$store.state.membre.biographie"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tile is-4 is-vertical is-parent">
        <Appareil />
      </div>
    </article>
  </section>
</template>

<script>
import axios from 'axios'
import Modifier from '@/components/Options/Modifier.vue'
import Switch from '@/components/Formulaire/Switch.vue'
import UploadImage from '@/components/Formulaire/UploadImage.vue'
import TagsInput from '@/components/Formulaire/TagsInput.vue'
import Appareil from '@/components/PartieDroite/Profil/Appareil.vue'
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'Profil',
  components: {
    Modifier,
    Switch,
    UploadImage,
    TagsInput,
    Appareil,
    Avatar
  },
  data () {
    return {
      aModifier: false,
      nouveauMdp: '',
      ancienMdp: '',
      nouveauAvatar: '',
      codeGroupe: ''
    }
  },
  methods: {
    styleMdp: function (dateDiff) {
      const pourcentMdp = (100 * dateDiff) / 90
      return 'color: hsla(360, ' + pourcentMdp + '%, 55%, 1)'
    },
    jourS: function (dateDiff) {
      return dateDiff > 1 ? 'jours' : 'jour'
    },
    // Gestion de l'avatar
    avatarNew: function () {
      this.$store.state.membre.avatar = this.$refs.changerAvatar.base64
      this.nouveauAvatar = 'images/avatar/' + this.$store.getters.numMembre + '_temp.png'
    },
    avatarDelete: function () {
      this.$store.state.membre.avatar = null
      this.nouveauAvatar = null
    },
    avatarAncien: function () {
      this.$store.state.membre.avatar = this.$refs.profil.recupererAncienneValeur().avatar
      this.nouveauAvatar = ''
    },
    // Modifier son profil
    modifier: function () {
      this.aModifier = true
      this.$refs.profil.valeur(JSON.parse(JSON.stringify(this.$store.state.membre)))
    },
    annulerModification: function (anciennesValeur) {
      this.nouveauMdp = ''
      this.ancienMdp = ''
      this.nouveauAvatar = ''
      this.aModifier = false
      this.$store.state.membre = anciennesValeur
    },
    verifForm: function () {
      let i = 0
      let message = ''
      if (this.ancienMdp === '') {
        i++
        message += 'Veuillez valider avec votre ancien mot de passe.<br>'
      }
      if (this.nouveauMdp !== '' && this.nouveauMdp.length < 8) {
        i++
        message += 'Votre nouveau mot de passe est trop court (min: 8).<br>'
      }
      if (!this.$store.state.regex.email.test(this.$store.getters.infoMembre('adresseMail'))) {
        i++
        message += 'Email non conforme.<br>'
      }
      if (this.$store.getters.infoMembre('nom') === '') {
        i++
        message += 'Aucun nom renseigné.<br>'
      }
      if (this.$store.getters.infoMembre('prenom') === '') {
        i++
        message += 'Aucun prénom renseigné.<br>'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    updateText: function (event, item) {
      this.$store.state.membre.biographie = event.target.innerHTML
    },
    validerModification: function () {
      // Vérifier le formulaire
      if (this.verifForm()) {
        this.$store.commit('chargement')
        // Récupérer competences
        const competencesSupprimer = this.$refs.tagsinput.getTagsSupprimer()
        const competencesAjouter = this.$refs.tagsinput.getTagsAjouter()
        // Récupérer Switch
        const typeCompte = this.$refs.switch.getValeur()
        // Nouveau avatar aussi
        axios.post(this.$store.getters.url + 'back/Profil/Modifier.php?i=' + this.$store.getters.numMembre, {
          membre: this.$store.state.membre,
          competencesSupprimer: competencesSupprimer,
          competencesAjouter: competencesAjouter,
          typeCompte: typeCompte,
          nouveauAvatar: this.nouveauAvatar,
          nouveauMdp: this.nouveauMdp,
          ancienMdp: this.ancienMdp
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.aModifier = false
            this.$store.commit('modifierProfil', { avatar: this.nouveauAvatar, typeCompte: typeCompte, mdp: this.nouveauMdp, data: reponse.data })
            this.$refs.profil.validerOk()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Profil modifié avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    }
  }
}
</script>

<style>
.profilAvatar {
  padding: 0 0 0 0!important;
  height: 40vh!important;
}

.profilAvatar img {
  height: 100%!important;
  object-fit: cover!important;
  border-radius: 6px;
}

.profilAvatar h1 {
  color: white!important;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}
</style>
