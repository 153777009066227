<template>
  <div class="boutonOptionDM">
    <Trier @trier="changeTrie"/>
  </div>
    <div v-if="$store.getters.isCreateur" class="boutonOptionGM">
      <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouter"/>
      <Supprimer @supprimer="supprimer" @annulerSuppression="annulerSuppression" @validerSuppression="validerSuppression" ref="supprimer"/>
    </div>
    <article>
      <!-- Nouveau -->
      <div v-if="ajouterForm" class="tile is-ancestor" style="margin-bottom: 3vh;">
        <div class="tile is-12 is-vertical is-parent">
          <div class='une tile is-child box nouveau'>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Date</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type='date' class='input' name='date' id="dateInput" placeholder="Date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="nouveauDate">
                    <span class="icon is-left">
                      <i class="fad fa-calendar-day"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Description</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <textarea class='textarea' placeholder="Description de l'historique" v-model="nouveauDesc"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Liste -->
      <div class="timeline is-centered">
        <div
        v-for="timeline in timelines"
        :key="timeline"
        class="timeline-item"
        :aSupprimer="timeline.aSupprimer"
        :aSelectionner="timeline.aSelectionner"
        @click="aSelectionner(timeline.id)">
          <div v-if="$store.getters.isCreateur" class="boutonOptionGM" style="top:-4vh;">
            <Modifier tooltipSens="top" @modifier="modifier(timeline.id)" @annulerModification="annulerModification" @validerModification="validerModification(timeline.id)" :ref="timeline.id" />
          </div>
          <div class="timeline-marker"></div>
          <div class="timeline-content" style="width:100%;display:block;">
            <div class="heading">
              <span v-if="!timeline.aModifier">{{ timeline.dateHtml }}</span>
              <div v-else class="field">
                <div class="control has-icons-left">
                  <input type='date' class='input' name='date' id="dateInput" placeholder="Date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="timeline.dateInput">
                  <span class="icon is-left">
                    <i class="fad fa-calendar-day"></i>
                  </span>
                </div>
              </div>
            </div>
            <div v-if="!timeline.aModifier" data="texte" v-html="timeline.texte"></div>
            <div v-else class="field">
              <div class="control">
                <div class='textarea' @focusout="updateText($event, timeline.id)" contenteditable="true" placeholder="Description de l'historique" v-html="timeline.texte"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
</template>

<script>
import axios from 'axios'
import Ajouter from '@/components/Options/Ajouter.vue'
import Supprimer from '@/components/Options/Supprimer.vue'
import Trier from '@/components/Options/Trier.vue'
import Modifier from '@/components/Options/Modifier.vue'
import 'bulma-timeline/dist/css/bulma-timeline.min.css'

export default {
  name: 'Histoire',
  components: {
    Ajouter,
    Supprimer,
    Trier,
    Modifier
  },
  data () {
    return {
      timelines: [],
      sens: 'DESC',
      sensListe: { ASC: 'DESC', DESC: 'ASC' },
      ajouterForm: false,
      nouveauDate: 0,
      nouveauDesc: ''
    }
  },
  created () {
    this.chargerDonnees()
  },
  methods: {
    chargerDonnees: function () {
      this.$store.commit('chargement')
      axios.get(this.$store.getters.url + 'back/Histoire/Charger.php?i=' + this.$store.getters.numMembre).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.timelines = reponse.data.donnees
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    changeTrie: function () {
      this.sens = this.sensListe[this.sens]
      this.trier()
    },
    trier: function () {
      this.timelines.sort(function (a, b) {
        return a.date - b.date
      })
      if (this.sens === 'DESC') {
        this.timelines.reverse()
      }
    },
    verifForm: function (date, desc) {
      let i = 0
      let message = ''
      if (typeof parseInt(date) !== 'number') {
        i++
        message += 'La date n\'est pas un nombre.<br>'
      }
      if (desc === '') {
        i++
        message += 'Aucune description.'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouveauDate = 0
      this.nouveauDesc = ''
    },
    validerAjout: function () {
      if (this.verifForm(this.nouveauDate, this.nouveauDesc)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Histoire/Ajouter.php?i=' + this.$store.getters.numMembre, {
          date: this.nouveauDate,
          desc: this.nouveauDesc
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.timelines.push({
              id: reponse.data.id,
              date: reponse.data.date,
              dateHtml: reponse.data.dateHtml,
              dateInput: reponse.data.dateInput,
              texte: this.nouveauDesc,
              aSupprimer: false,
              aSelectionner: false,
              aModifier: false
            })
            this.trier()
            this.$refs.ajouter.validerOk()
            this.ajouterForm = false
            this.nouveauDate = 0
            this.nouveauDesc = ''
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Date ajoutée avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    supprimer: function () {
      for (var i = 0; i < this.timelines.length; i++) {
        this.timelines[i].aSupprimer = true
      }
    },
    annulerSuppression: function () {
      for (var i = 0; i < this.timelines.length; i++) {
        this.timelines[i].aSupprimer = false
        this.timelines[i].aSelectionner = false
      }
    },
    aSelectionner: function (item) {
      for (var i = 0; i < this.timelines.length; i++) {
        if (this.timelines[i].id === item) {
          if (this.timelines[i].aSelectionner) {
            this.timelines[i].aSelectionner = false
          } else {
            this.timelines[i].aSelectionner = true
          }
          break
        }
      }
    },
    validerSuppression: function () {
      const liste = []
      for (var i = 0; i < this.timelines.length; i++) {
        if (this.timelines[i].aSelectionner) {
          liste.push(this.timelines[i].id)
        }
      }
      if (liste.length > 0) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Histoire/Supprimer.php?i=' + this.$store.getters.numMembre, {
          liste: liste
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            const nonSupprimer = []
            for (var i = 0; i < this.timelines.length; i++) {
              if (liste.indexOf(this.timelines[i].id) === -1) {
                nonSupprimer.push(this.timelines[i])
              }
            }
            this.timelines = nonSupprimer
            this.trier()
            this.$refs.supprimer.validerOk()
            this.annulerSuppression()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Date(s) supprimée(s) avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien a supprimer !', type: 'info' })
      }
    },
    modifier: function (item) {
      for (var i = 0; i < this.timelines.length; i++) {
        if (this.timelines[i].id === item) {
          this.timelines[i].aModifier = true
          this.$refs[this.timelines[i].id].valeur({
            id: this.timelines[i].id,
            date: this.timelines[i].date,
            dateHtml: this.timelines[i].dateHtml,
            dateInput: this.timelines[i].dateInput,
            texte: this.timelines[i].texte
          })
          break
        }
      }
    },
    annulerModification: function (item) {
      for (var i = 0; i < this.timelines.length; i++) {
        if (this.timelines[i].id === item.id) {
          this.timelines[i].aModifier = false
          this.timelines[i].date = item.date
          this.timelines[i].dateHtml = item.dateHtml
          this.timelines[i].dateInput = item.dateInput
          this.timelines[i].texte = item.texte
          break
        }
      }
    },
    updateText: function (event, item) {
      for (var i = 0; i < this.timelines.length; i++) {
        if (this.timelines[i].id === item) {
          this.timelines[i].texte = event.target.innerHTML
          break
        }
      }
    },
    validerModification: function (item) {
      let date = 0
      let texte = ''
      for (var i = 0; i < this.timelines.length; i++) {
        if (this.timelines[i].id === item) {
          date = this.timelines[i].dateInput
          texte = this.timelines[i].texte
          break
        }
      }
      if (this.verifForm(date, texte)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Histoire/Modifier.php?i=' + this.$store.getters.numMembre, {
          id: item,
          date: date,
          desc: texte
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            for (var i = 0; i < this.timelines.length; i++) {
              if (this.timelines[i].id === item) {
                this.timelines[i].date = reponse.data.date
                this.timelines[i].dateHtml = reponse.data.dateHtml
                this.timelines[i].aModifier = false
                break
              }
            }
            this.trier()
            this.$refs[item].validerOk()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Date modifiée avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    }
  }
}
</script>

<style>
.boutonOptionGM {
  top: -1vh;
}
.timeline.is-centered .timeline-item:nth-of-type(2n) > .boutonOptionGM {
  left: 0vw;
}
.timeline-item[aSupprimer=true] {
  cursor: pointer;
}
.timeline-item[aSupprimer=true][aSelectionner=true] {
  border: 3px dashed #f14668;
}
div[contenteditable=true] {
  height: auto;
}
</style>
