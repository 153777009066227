<template>
  <div :class="barreLaterale">
    <Top @vers-app="versApp" @bouger-barre="changerBarre"/>
    <div class="titreProjet">
      <h1 class="title has-text-centered">Projets</h1>
      <h5 class="subtitle has-text-centered">
        <div class="select is-small">
          <select v-model="$store.state.membre.groupeEnCours.idGroupe">
            <option v-for="groupe in $store.state.membre.groupe" :key="groupe" :value="groupe.idGroupe"> {{ groupe.nomGroupe }} </option>
          </select>
        </div>
      </h5>
    </div>
    <div class="listeProjet" v-show="taille === 1">
      <BarreLaterale @vers-app-projet="versAppProjet"/>
    </div>
    <div class="listeProjet" v-show="taille === 0">
      <BarreLateralePetit @vers-app-projet="versAppProjet" ref="BarreLateralePetit"/>
    </div>
    <Bottom @vers-app="versApp"/>
  </div>
</template>

<script>
import Top from '@/components/PartieGauche/Top.vue'
import BarreLaterale from '@/components/PartieGauche/BarreLaterale.vue'
import BarreLateralePetit from '@/components/PartieGauche/BarreLateralePetit.vue'
import Bottom from '@/components/PartieGauche/Bottom.vue'

export default {
  name: 'PartieGauche',
  components: {
    Top,
    Bottom,
    BarreLaterale,
    BarreLateralePetit
  },
  emits: ['changer-droite', 'changer-droite-projet'],
  data () {
    return {
      barreLaterale: 'barreLaterale',
      taille: 1
    }
  },
  methods: {
    versApp: function (compo) {
      this.$emit('changer-droite', compo)
    },
    versAppProjet: function (compo) {
      this.$emit('changer-droite-projet', compo)
    },
    changerBarre: function () {
      if (this.barreLaterale.includes('petit')) {
        this.barreLaterale = 'barreLaterale'
        this.taille = 1
      } else {
        this.barreLaterale = 'barreLaterale petit'
        this.taille = 0
        this.$refs.BarreLateralePetit.charger()
      }
    }
  }
}
</script>

<style>
.figure_avatar {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  border-radius: 15%;
  overflow: hidden;
}
.image_avatar {
  width: inherit;
  height: inherit;
  border-radius: inherit;
  object-fit: fill;
  object-position: 50% 50%;
  max-height: 100%!important
}
.imageProfil {
  object-fit: cover!important;
}
.titreProjet .select {
  margin-top: 0.5rem;
}

/*Barre Latérale*/
/*Tablette*/
@media (max-width: 1215px) {
  .barreLaterale {
    height: 100%;
    width: 20vw;
  }
  .barreLaterale > div:nth-of-type(1) {
    height: 10%;
  }
  .barreLaterale .titreProjet {
    height: 10%;
    margin-bottom: 0;
  }
  .barreLaterale .listeProjet {
    height: 55%;
  }
  .barreLaterale.petit .listeProjet {
    height: 60%;
  }
  .barreLaterale section.bottom {
    height: 25%;
    position: relative;
  }
  /*Retrecissement de la barre laterale*/
  .barreLaterale.petit .burger {
    text-align: center;
  }
  .barreLaterale.petit {
    width: 5vw;
  }
  .barreLaterale.petit section.bottom {
    height: 23%;
  }
}

/*PC*/
@media(min-width:1216px) and (max-width: 1504px) {
  .barreLaterale {
    height: 100%;
    width: 20vw;
  }
  .barreLaterale > div:nth-of-type(1) {
    height: 10%;
  }
  .barreLaterale .titreProjet {
    height: 10%;
    margin-bottom: 0;
  }
  .barreLaterale .listeProjet,
  .accordionPetit {
    height: 60%;
  }
  /*Retrecissement de la barre laterale*/
  .barreLaterale.petit {
    width: 4vw;
  }
  .barreLaterale.petit section.bottom {
    height: 23%;
  }
}

/*Ecran*/
@media (min-width: 1505px) {
  .barreLaterale {
    height: 100%;
    width: 20vw;
  }
  .barreLaterale > div:nth-of-type(1) {
    height: 10%;
  }
  .barreLaterale .titreProjet {
    height: 10%;
    margin-bottom: 0;
  }
  .barreLaterale .listeProjet,
  .accordionPetit {
    height: 60%;
  }
  /*Retrecissement de la barre laterale*/
  .barreLaterale.petit {
    width: 3vw;
  }
  .barreLaterale.petit section.bottom {
    height: 23%;
  }
}
.barreLaterale.petit .titreProjet,
.barreLaterale.petit .burger.burgerInfini,
.barreLaterale.petit .bottom .goDroiteTexte,
.barreLaterale.petit .accordions .goDroiteTexte,
.barreLaterale.petit .accordion-body:not(.accordionPetit),
.barreLaterale.petit .afficheListe.icon.is-medium {
  display: none;
}
.barreLaterale.petit .icon.is-large,
.barreLaterale.petit .bottom .icon.is-medium {
  height: 2.5rem;
}
.barreLaterale.petit .icon.is-large > svg {
  font-size: 1.5rem;
}
.barreLaterale.petit .bottom .icon.is-medium > svg,
.barreLaterale.petit .accordionPetit .icon.is-medium > svg {
  font-size: 1.3rem;
}
.barreLaterale.petit .figure_logo {
  width: 2rem;
  height: 2rem;
}
.barreLaterale.petit .listeGoDroite .is-large {
  width: 3rem;
  height: 2rem;
}
.barreLaterale.petit > .top {
  height: 17%;
}
.barreLaterale.petit .bottom .goDroite,
.barreLaterale.petit .accordions {
  text-align: center;
}
/*Sans Logo*/
.barreLaterale.petit .avatar {
  height: 2rem!important;
  font-size: 1.2rem!important;
  min-width: 2rem!important;
  max-width: fit-content;
}
</style>
