<template>
  <transition name="slide">
    <div v-show='$store.state.Notif.affiche' :class="$store.state.Notif.couleur">
      <button @click="cacherNotification" class="delete croixNotif"></button>
      <div class="columns is-vcentered">
        <div class="column is-2">
          <span class="icon messageIcon" id="messageIcon">
          </span>
        </div>
        <div class="column">
          <p v-html="$store.state.Notif.texte"></p>
        </div>
      </div>
        <div class="loaderDisparition">
          <transition name="barre">
            <div v-if="afficheOk" :class="$store.state.Notif.fondProgress"></div>
          </transition>
        </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Notification',
  data () {
    return {
      afficheOk: false,
      timer: 0
    }
  },
  watch: {
    '$store.state.Notif': {
      deep: true,
      handler (newVal) {
        if (this.$store.state.Notif.affiche) {
          this.changerIcon()
          this.afficheOk = true
          this.timer = setTimeout(this.cacherNotification, 7000)
        } else {
          this.afficheOk = false
        }
      }
    }
  },
  methods: {
    cacherNotification: function () {
      this.$store.commit('cacherNotification')
      clearTimeout(this.timer)
    },
    changerIcon: function () {
      document.getElementById('messageIcon').innerHTML = this.$store.state.Notif.icon
    }
  }
}
</script>
<style>
.notification {
  position: absolute!important;
  bottom: 1vh;
  right: 1vw;
  z-index: 100;
}
.notification.is-danger .icon svg > g path {
  color: #f14668!important;
}
.notification.is-success .icon svg > g path {
  color: #23D160!important;
}
.notification.is-info .icon svg > g path {
  color: #3298dc!important;
}
.notification.is-warning .icon svg > g path {
  color: #634f01!important;
}
.loaderDisparition {
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.progressDiv {
  height: 100%;
  border-bottom-left-radius: 4px;
}
@media (prefers-color-scheme: dark) {
  .notification.is-info {
    background-color: #0f3046!important;
  }
  .notification.is-danger {
    background-color: #440c17!important;
  }
  .notification.is-success {
    background-color: #114222!important;
  }
  .notification.is-warning {
    background-color: #af9017!important;
  }
  .progressDiv.has-background-info {
    background-color: #1d72aa!important;
  }
  .progressDiv.has-background-danger {
    background-color: #cc0f35!important;
  }
  .progressDiv.has-background-success {
    background-color: #257942!important;
  }
  .progressDiv.has-background-warning {
    background-color: #947600!important;
  }
}
.notification {
    width: 30%;
  }
/*Tablette*/
@media (max-width: 1215px) {
  .notification > button.delete {
    max-width: 30px!important;
    max-height: 30px!important;
    height: 30px!important;
    width: 30px!important;
  }
}
/*Mouvement notification*/
.slide-enter-active,
.slide-leave-active {
  transition: all 1s ease-in;
}
.slide-enter-from {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0);
}
.slide-leave-from {
  transform: translateX(0);
}
.slide-leave-to {
   transform: translateX(100%);
}

/*Mouvement progress barre*/
.barre-enter-active {
  transition: width 7s linear;
  width: 100%;
}
.barre-enter-from {
  width: 0%;
}
.barre-enter-to {
  width: 100%;
}
</style>
