<template>
  <form>
    <div class="field">
      <div class="control has-icons-left">
        <input class="input" type="email" placeholder="Adresse email" autocomplete="username" autofocus v-model="email">
        <span class="icon is-small is-left">
          <i class="fas fa-user"></i>
        </span>
        <p v-show="etat==0" class="help is-danger" @click='envoieMail'>Email non conforme</p>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Etape1',
  emits: ['etatSuite', 'suiteOK', 'setEmail'],
  data () {
    return {
      email: '',
      etat: 1
    }
  },
  watch: {
    email (n, a) {
      this.emailChange()
    }
  },
  methods: {
    emailChange: function () {
      if (this.$store.state.regex.email.test(this.email) || this.email.length === 0) {
        this.etat = 1
        this.$emit('etatSuite', 1)
      } else {
        this.etat = 0
        this.$emit('etatSuite', 0)
      }
    },
    envoieMail: function () {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/MDPoublie/Etape1.php', {
        email: this.email
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous avez reçu un email contenant le code de vérification !', type: 'ok' })
          this.$emit('setEmail', this.email)
          this.$emit('suiteOK')
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'L\'adresse email ne correspond pas ou a un mauvais format !', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    }
  }
}
</script>

<style scoped>
div {
  margin: auto;
}
</style>
