<template>
  <!-- Menu de base -->
  <div v-if="editor && droit === 2" class="has-text-centered">
    <button @click="editor.chain().focus().toggleBold().run()" :class="'button buttonWysiwyg ' + ( editor.isActive('bold') ? 'is-info' : '' )">
      <i class="fad fa-bold"></i>
    </button>
    <button @click="editor.chain().focus().toggleItalic().run()" :class="'button buttonWysiwyg ' + ( editor.isActive('italic') ? 'is-info' : '' )">
      <i class="fad fa-italic"></i>
    </button>
    <button @click="editor.chain().focus().toggleStrike().run()" :class="'button buttonWysiwyg ' + ( editor.isActive('strike') ? 'is-info' : '' )">
      <i class="fad fa-strikethrough"></i>
    </button>
    <span class="icon is-medium" style="font-size: 2rem;">
      |
    </span>
    <button @click="editor.chain().focus().setTextAlign('left').run()" :class="'button buttonWysiwyg ' + ( editor.isActive({ textAlign: 'left' }) ? 'is-info' : '' )">
        <i class="fad fa-align-left"></i>
    </button>
    <button @click="editor.chain().focus().setTextAlign('center').run()" :class="'button buttonWysiwyg ' + ( editor.isActive({ textAlign: 'center' }) ? 'is-info' : '' )">
      <i class="fad fa-align-center"></i>
    </button>
    <button @click="editor.chain().focus().setTextAlign('right').run()" :class="'button buttonWysiwyg ' + ( editor.isActive({ textAlign: 'right' }) ? 'is-info' : '' )">
      <i class="fad fa-align-right"></i>
    </button>
    <button @click="editor.chain().focus().setTextAlign('justify').run()" :class="'button buttonWysiwyg ' + ( editor.isActive({ textAlign: 'justify' }) ? 'is-info' : '' )">
      <i class="fad fa-align-justify"></i>
    </button>
    <span class="icon is-medium" style="font-size: 2rem;">
      |
    </span>
    <button @click="editor.chain().focus().setParagraph().run()" :class="'button buttonWysiwyg ' + ( editor.isActive('paragraph') ? 'is-info' : '' )">
      <i class="fad fa-paragraph"></i>
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="'button buttonWysiwyg ' + ( editor.isActive('heading', { level: 1 }) ? 'is-info' : '' )">
      <i class="fad fa-h1"></i>
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="'button buttonWysiwyg ' + ( editor.isActive('heading', { level: 2 }) ? 'is-info' : '' )">
      <i class="fad fa-h2"></i>
    </button>
    <button @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="'button buttonWysiwyg ' + ( editor.isActive('heading', { level: 3 }) ? 'is-info' : '' )">
      <i class="fad fa-h3"></i>
    </button>
    <span class="icon is-medium" style="font-size: 2rem;">
      |
    </span>
    <button @click="editor.chain().focus().toggleBulletList().run()" :class="'button buttonWysiwyg ' + ( editor.isActive('bulletList') ? 'is-info' : '' )">
      <i class="fad fa-list"></i>
    </button>
    <button @click="editor.chain().focus().toggleOrderedList().run()" :class="'button buttonWysiwyg ' + ( editor.isActive('orderedList') ? 'is-info' : '' )">
      <i class="fad fa-list-ol"></i>
    </button>
    <button @click="setLink" :class="'button buttonWysiwyg ' + ( editor.isActive('link') ? 'is-info' : '' )">
      <i class="fad fa-link"></i>
    </button>
    <button @click="editor.chain().focus().unsetLink().run()" class="button buttonWysiwyg" v-if="editor.isActive('link')">
      <i class="fad fa-unlink"></i>
    </button>
    <button @click="editor.chain().focus().setHorizontalRule().run()" class="button">
      <i class="fad fa-horizontal-rule"></i>
    </button>
    <button @click="editor.chain().focus().setHardBreak().run()" class="button">
      <i class="fad fa-paragraph-rtl"></i>
    </button>
    <button @click="addImage" class="button buttonWysiwyg">
      <i class="fad fa-image"></i>
    </button>
    <span class="icon is-medium" style="font-size: 2rem;">
      |
    </span>
    <button @click="editor.chain().focus().unsetAllMarks().run()" class="button buttonWysiwyg">
      <i class="fad fa-remove-format"></i>
    </button>
    <button @click="editor.chain().focus().undo().run()" class="button buttonWysiwyg">
      <i class="fad fa-undo-alt"></i>
    </button>
    <button @click="editor.chain().focus().redo().run()" class="button buttonWysiwyg">
      <i class="fad fa-redo-alt"></i>
    </button>
  </div>
  <!-- Bubble Menu -->
  <bubble-menu
    class="bubble-menu"
    :tippy-options="{ duration: 100 }"
    :editor="editor"
    v-if="editor && droit === 2"
  >
    <button @click="editor.chain().focus().toggleBold().run()" :class="'button buttonWysiwyg is-small ' + ( editor.isActive('bold') ? 'is-info' : 'has-background-light' )">
      <i class="fad fa-bold"></i>
    </button>
    <button @click="editor.chain().focus().toggleItalic().run()" :class="'button buttonWysiwyg is-small ' + ( editor.isActive('italic') ? 'is-info' : 'has-background-light' )">
      <i class="fad fa-italic"></i>
    </button>
    <button @click="editor.chain().focus().toggleStrike().run()" :class="'button buttonWysiwyg is-small ' + ( editor.isActive('strike') ? 'is-info' : 'has-background-light' )">
      <i class="fad fa-strikethrough"></i>
    </button>
  </bubble-menu>
  <!-- Floating Menu -->
  <floating-menu
      class="floating-menu"
      :tippy-options="{ duration: 100 }"
      :editor="editor"
      v-if="editor && droit === 2"
    >
      <button @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="'button buttonWysiwyg is-small ' + ( editor.isActive('heading', { level: 1 }) ? 'is-info' : 'has-background-light' )">
        <i class="fad fa-h1"></i>
      </button>
      <button @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="'button buttonWysiwyg is-small ' + ( editor.isActive('heading', { level: 2 }) ? 'is-info' : 'has-background-light' )">
        <i class="fad fa-h2"></i>
      </button>
      <button @click="editor.chain().focus().toggleBulletList().run()" :class="'button buttonWysiwyg is-small ' + ( editor.isActive('bulletList') ? 'is-info' : 'has-background-light' )">
        <i class="fad fa-list"></i>
      </button>
  </floating-menu>
  <!-- Input pour les images -->
  <input type="file" name="upload" class="is-hidden" id="imagesTipTap" @change="chargerImage($event)">
  <editor-content :editor="editor" class="content" />
</template>

<script type="text/javascript">
import { Editor, EditorContent, BubbleMenu, FloatingMenu } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import Dropcursor from '@tiptap/extension-dropcursor'
import Link from '@tiptap/extension-link'
import axios from 'axios'

export default {
  name: 'Tiptap',
  emits: ['change-texte'],
  components: {
    EditorContent,
    BubbleMenu,
    FloatingMenu
  },
  props: ['texte', 'quoi', 'info', 'droit'],
  data () {
    return {
      editor: null,
      base64: ''
    }
  },
  watch: {
    base64: function () {
      this.envoieImage()
    }
  },
  mounted () {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        TextAlign.configure({
          types: ['heading', 'paragraph']
        }),
        Image,
        Dropcursor,
        Link.configure({
          openOnClick: false
        })
      ],
      content: this.texte,
      autofocus: false,
      editable: this.droit === 2
    })
    if (this.droit === 2) {
      this.editor.on('blur', ({ editor, event }) => {
        this.modifierTexte()
      })
    }
  },
  methods: {
    addImage: function () {
      document.getElementById('imagesTipTap').click()
    },
    chargerImage: function (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (evt) => {
        this.base64 = evt.target.result
      }
      reader.readAsDataURL(image)
    },
    envoieImage: function () {
      if (this.base64 !== '' && this.droit === 2) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/' + this.quoi + '/ImageTipTap.php?i=' + this.$store.getters.numMembre, {
          image: this.base64,
          info: this.info
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Image uploadée avec succès !', type: 'ok' })
            this.editor.chain().focus().setImage({ src: reponse.data.source }).run()
            this.changement = true
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'L\'image ne se trouve pas dans un bon format ! L\'image doit être inférieure ou égale à 12Mo et doit être sous un des formats suivants : \'png\', \'jpg\', \'jpeg\', \'gif\'.', type: 'attention' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    modifierTexte: function () {
      if (this.droit === 2) {
        const html = this.editor.getHTML()
        this.$emit('change-texte', html)
      }
    },
    setLink: function () {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)
      // cancelled
      if (url === null) {
        return
      }
      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()
        return
      }
      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    }
  },
  beforeUnmount () {
    this.editor.destroy()
  }
}
</script>

<style>
.buttonWysiwyg {
  margin-right: 0.1rem;
  margin-left: 0.1rem;
  margin-bottom: 0.2rem;
  padding-left: 0.8rem!important;
  padding-right: 0.8rem!important;
}
.buttonWysiwyg.is-small {
  margin-right: 0.1rem;
  margin-left: 0.1rem;
}
.ProseMirror {
  height: auto;
  min-height: 30rem;
  max-height: 70vh;
  overflow: auto;
  padding: calc(0.75em - 1px);
  box-shadow: inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);
  border-radius: 4px;
  border: 1px solid #dbdbdb;
  background-color: white;
}
.ProseMirror > * + * {
  margin-top: 0.75em;
}

.ProseMirror ul,
.ProseMirror ol {
  padding: 0 1rem;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
}

.ProseMirror code {
  background-color: rgba(#616161, 0.1);
  color: #616161;
}

.ProseMirror pre {
  background: #0D0D0D;
  color: #FFF;
  font-family: 'JetBrainsMono', monospace;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;

}
.ProseMirror pre code {
  color: inherit;
  padding: 0;
  background: none;
  font-size: 0.8rem;
}

.ProseMirror img {
  max-width: 100%;
  width: 30%;
  margin-left: 35%;
  height: auto!important;
}

.ProseMirror blockquote {
  padding-left: 1rem;
  border-left: 2px solid rgba(#0D0D0D, 0.1);
}

.ProseMirror hr {
  border: none;
  border-top: 2px solid rgba(#0D0D0D, 0.6);
  margin: 2rem 0;
}
</style>
