<template>
  <section>
    <article>
      <h1 class="title has-text-centered">&Agrave; PROPOS</h1>
    </article>
    <article class="tile is-ancestor nav">
      <div class="tile is-parent is-vertical is-1"></div>
      <div class="tile is-parent is-vertical is-4">
        <div class="tile box is-child is-12">
          <p @click="changerAffiche('Histoire')" id="Histoire" class="fauxLien has-text-centered active">
            <span class="icon is-medium"><i class="fad fa-history"></i></span>
            <span><b>Histoire de Dream Web</b></span>
          </p>
        </div>
      </div>
      <div class="tile is-parent is-vertical is-2">
        <img src="images/site/logo.svg">
      </div>
      <div class="tile is-parent is-vertical is-4">
        <div class="tile box is-child is-12">
          <p @click="changerAffiche('Maj')" id="Maj" class="fauxLien has-text-centered">
            <span class="icon is-medium"><i class="fad fa-wrench"></i></span>
            <span><b>Historique des mises à jour</b></span>
          </p>
        </div>
      </div>
    </article>
    <div v-show="affiche==='Histoire'">
      <Histoire />
    </div>
    <div v-show="affiche==='Maj'">
      <Maj />
    </div>
  </section>
</template>

<script>
import Histoire from '@/components/PartieDroite/APropos/Histoire.vue'
import Maj from '@/components/PartieDroite/APropos/Maj.vue'
export default {
  name: 'APropos',
  components: {
    Histoire,
    Maj
  },
  data () {
    return {
      affiche: 'Histoire'
    }
  },
  methods: {
    changerAffiche: function (comp) {
      document.getElementById(this.affiche).classList.remove('active')
      this.affiche = comp
      document.getElementById(comp).classList.add('active')
    }
  }
}
</script>

<style>
img {
  height: 9vh!important;
}
.nav {
  margin-bottom: 6vh!important;
  margin-top: 5vh!important;
}
</style>
