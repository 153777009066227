<template>
  <Preloader />
  <Notification ref="afficherNotification"/>
  <Connexion
    v-if="$store.getters.numMembre==0"
  />
  <section v-if="$store.getters.numMembre>0" class="columns">
    <div class="partieGauche">
     <PartieGauche @changer-droite="changerDroite" @changer-droite-projet="changerDroiteProjet"/>
    </div>
    <div class="partieDroite">
      <Profil v-if="affiche==='Profil'" />
      <GestionProjet v-else-if="affiche==='GestionProjet'" />
      <GestionGroupe v-else-if="affiche==='GestionGroupe'" />
      <Annuaire v-else-if="affiche==='Annuaire'" />
      <MentionLegale v-else-if="affiche==='MentionLegale'"/>
      <APropos v-else-if="affiche==='APropos'"/>
      <!-- Page par projet -->
      <Logiciels :projet="projet" v-else-if="affiche==='Logiciels'"/>
      <Statistiques :projet="projet" v-else-if="affiche==='Statistiques'"/>
      <Newsletters :projet="projet" v-else-if="affiche==='Newsletters'"/>
    </div>
  </section>
  <Loader />
</template>

<script>
// Fonctionnalités de base
import Preloader from '@/components/Preloader.vue'
import Notification from '@/components/Notification.vue'
import Loader from '@/components/Loader.vue'
import PartieGauche from '@/components/PartieGauche.vue'
import Connexion from '@/components/Connexion.vue'

// Composants fonctionnelles
import Profil from '@/components/PartieDroite/Profil.vue'
import GestionGroupe from '@/components/PartieDroite/GestionGroupe.vue'
import GestionProjet from '@/components/PartieDroite/GestionProjet.vue'
import Annuaire from '@/components/PartieDroite/Annuaire.vue'
import MentionLegale from '@/components/PartieDroite/MentionLegale.vue'
import APropos from '@/components/PartieDroite/APropos.vue'

// Composants par projet
import Logiciels from '@/components/PartieDroite/Logiciels.vue'
import Statistiques from '@/components/PartieDroite/Statistiques.vue'
import Newsletters from '@/components/PartieDroite/Newsletters.vue'

export default {
  name: 'App',
  components: {
    Preloader,
    PartieGauche,
    Connexion,
    Notification,
    Loader,
    Profil,
    GestionGroupe,
    GestionProjet,
    Annuaire,
    MentionLegale,
    APropos,
    Logiciels,
    Statistiques,
    Newsletters
  },
  data () {
    return {
      affiche: '',
      id: '',
      projet: 0
    }
  },
  methods: {
    afficherNotification: function (texte, type) {
      this.$refs.afficherNotification.afficherNotification(texte, type)
    },
    changerDroite: function (compo) {
      this.$store.commit('chargement')
      if (this.affiche !== '') {
        document.getElementById(this.id).classList.remove('active')
      }
      if (compo === this.affiche || compo === '') {
        this.affiche = ''
        this.id = ''
      } else {
        document.getElementById(compo).classList.add('active')
        this.affiche = compo
        this.id = compo
      }
      this.$store.commit('finChargement', 1100)
    },
    changerDroiteProjet: function (compo) {
      this.$store.commit('chargement')
      if (this.affiche !== '') {
        document.getElementById(this.id).classList.remove('active')
      }
      this.projet = compo.split('_')[1]
      const onglet = compo.split('_')[0]
      if (onglet === this.affiche || onglet === '') {
        this.affiche = ''
        this.id = ''
      } else {
        document.getElementById(compo).classList.add('active')
        this.affiche = onglet
        this.id = compo
      }
      this.$store.commit('finChargement', 1100)
    }
  }
}
</script>

<style>
html {
  background-size: cover;
  height: 100%;
  overflow: hidden!important;
}

body {
  height: 100%;
}

body section.columns,
div#app {
  height: 100%;
  margin-top: 0!important;
  margin-bottom: 0!important;
}

.columns {
  margin: 0 0 0 0!important;
}
span.icon[data-tooltip] {
  border: none;
}

/*Tablette*/
@media (max-width: 1215px) {
  .barreLaterale.petit .partieDroite {
    width: 95vw;
  }
}

/*PC*/
@media(min-width:1216px) and (max-width: 1504px) {
  .barreLaterale.petit .partieDroite {
    width: 96vw;
  }
}

/*Ecran*/
@media (min-width: 1505px) {
  .barreLaterale.petit .partieDroite {
    width: 97vw;
  }
}

/*Scroll*/
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-track {}

::-webkit-scrollbar-thumb {
  height: 50px;
  border-radius: 10px;
}

::-webkit-scrollbar-corner {}

::-webkit-resizer {}

.partieDroite {
  height: 100%;
  overflow: auto;
  width: fill;
  padding: 1.5vh 1vw 1vh 1.5vw;
  position: relative;
}

@media (prefers-color-scheme: light) {
  html {
    background-color: hsl(0, 0%, 96%)!important;
  }
  .fauxLien:hover,
  .fauxLien:hover *,
  .fauxLien:hover .icon svg .fa-group path,
  .fauxLien:hover .goDroiteTexte {
    color: #6E0E67!important;
    cursor: pointer;
  }
  .fauxLien.active .goDroiteTexte,
  .fauxLien.active svg > g path,
  .fauxLien.active span,
  .fauxLien.active b {
    color: #6E0E67!important;
  }
  .icon:not(.neutre) svg > g path,
  .icon:not(.neutre) svg > path,
  a.fauxLien {
    color: #47479F;
  }
  span.icon.has-text-primary svg .fa-group path {
    color: #00d1b2;
  }
  span.icon.has-text-danger svg .fa-group path {
    color: #f14668;
  }
  span.icon.has-text-success svg .fa-group path {
    color: #23D160;
  }
  span.icon.has-text-info svg .fa-group path {
    color: #3298dc;
  }
  span.icon.has-text-warning svg .fa-group path {
    color: #ffdd57;
  }
  button.is-info,
  .tag:not(body).is-medium {
    background-color: #47479F!important;
    color: white;
  }
  button.is-info:hover {
    background-color: #6E0E67!important;
  }
  /*Partie gauche*/
  .partieGauche {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .partieDroite {
    background-color: #47479f05;
  }
  /*Scroll*/
  ::-webkit-scrollbar-track-piece {
    background-color: #c1c1c1 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #969696 !important;
  }
  .zone,
  .tile.is-child,
  .boutonOptionGM .option,
  .boutonOptionDM .option {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .profilAvatarModifier {
    background-color: #e6e6e6b3;
  }
  /*A propos*/
  .timeline-marker,
  .timeline-item::before {
    background-color: #6E0E67!important;
    border-color: #6E0E67!important
  }
}
@media (prefers-color-scheme: dark) {
  /*Default*/
  .title,
  .subtitle {
    color: white!important;
  }
  html {
    background-color: hsl(0, 0%, 10%)!important;
  }
  /*Lien*/
  .fauxLien:not(.bloquer) {
    color: #0174CF;
  }
  .fauxLien:hover,
  .fauxLien:hover *,
  .fauxLien:hover .icon svg .fa-group path,
  .fauxLien:hover .goDroiteTexte {
    color: #01BBC8!important;
    cursor: pointer;
  }
  .fauxLien:hover .chargementGoDroite {
    border-bottom-color: #0174CF!important;
    border-left-color: #01BBC8!important;
  }
  .fauxLien.active .goDroiteTexte,
  .fauxLien.active svg > g path,
  .fauxLien.active span,
  .fauxLien.active b {
    color: #01BBC8!important;
  }
  .icon:not(.neutre) svg > g path,
  .icon:not(.neutre) svg > path {
    color: #0174CF;
  }
  span.icon.has-text-primary svg .fa-group path {
    color: #00d1b2;
  }
  span.icon.has-text-danger svg .fa-group path {
    color: #f14668;
  }
  span.icon.has-text-success svg .fa-group path {
    color: #23D160;
  }
  span.icon.has-text-info svg .fa-group path {
    color: #3298dc;
  }
  span.icon.has-text-warning svg .fa-group path {
    color: #ffdd57;
  }
  button.is-info,
  .tag:not(body).is-medium {
    background-color: #0174CF!important;
    color: white;
  }
  button.is-info:hover {
    background-color: #01BBC8!important;
  }
  /*Partie gauche*/
  .partieGauche {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  /*Scroll*/
  ::-webkit-scrollbar-track-piece {
    background-color: #3e4346 !important;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #242424 !important;
  }
  .zone,
  .tile.is-child,
  .boutonOptionGM .option,
  .boutonOptionDM .option {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .card {
    background-color: hsl(0, 0%, 12%)!important;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, .4), 0 0 0 1px rgba(10, 10, 10, .02)!important;
  }
  .profilAvatarModifier {
    background-color: #040404b3;
  }
  /*A propos*/
  .timeline-marker,
  .timeline-item::before {
    background-color: #01BBC8!important;
    border-color: #01BBC8!important
  }
  .label {
    color: white!important;
  }
  /*Fin*/
  select,
  option,
  .select {
    color: black!important;
  }
  * {
    color: white;
  }
}

.partieDroite section article:nth-of-type(1) {
  margin-bottom: 5vh;
}

.tile {
  flex-basis: auto!important;
  flex-grow: unset!important;
}

.tile > .subtitle {
  font-weight: bold;
}

.tile.is-child .scrollable {
  overflow-y: hidden;
  overflow-x: auto;
}

.buttons {
  display: block;
}
.boutonOptionGM {
  display: inline-flex;
  position: absolute;
  top: 1vh;
  right: 0vw;
}

.boutonListe {
  display: inline-flex;
}

.boutonOptionGM svg,
.fauxLien.bouton {
  font-size: 1.5rem;
}

.boutonOptionGM .option,
.fauxLien.bouton {
  margin-right: 1vw;
  border-radius: 6px;
  padding: 0.3rem;
}

.boutonOptionDM {
  display: inline-flex;
  position: absolute;
  top: 1vh;
  left: 0vw;
}

.boutonOptionDM svg {
  font-size: 1.5rem;
}

.boutonOptionDM .option {
  margin-left: 1vw;
  border-radius: 6px;
  padding: 0.3rem;
}
div[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #afacac;
}
</style>
