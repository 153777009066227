<template>
  <form>
    <div class="field">
      <div class="control has-icons-left">
        <input class="input" type="password" placeholder="Nouveau mot de passe" autocomplete="new-password" v-model="mdp">
        <span class="icon is-small is-left">
          <i class="fas fa-key"></i>
        </span>
      </div>
      <p v-show="etatMdp==0" class="help is-danger has-text-right">Le mot de passe est trop court (min: 8 caractères) !</p>
    </div>
    <div class="field">
      <div class="control has-icons-left">
        <input class="input" type="password" placeholder="Retaper le mot de passe" autocomplete="new-password" v-model="mdpValide">
        <span class="icon is-small is-left">
          <i class="fas fa-key"></i>
        </span>
      </div>
      <p v-show="etatMdpValide==0" class="help is-danger has-text-right">Les 2 mots de passe ne correspondent pas !</p>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Etape3',
  emits: ['etatSuite', 'suiteOK'],
  data () {
    return {
      mdp: '',
      mdpValide: '',
      etatMdp: 1,
      etatMdpValide: 1
    }
  },
  watch: {
    mdp (n, a) {
      if (n.length < 8) {
        this.etatMdp = 0
      } else {
        this.etatMdp = 1
      }
      this.checkValide()
    },
    mdpValide (n, a) {
      if (n !== this.mdp) {
        this.etatMdpValide = 0
      } else {
        this.etatMdpValide = 1
      }
      this.checkValide()
    }
  },
  methods: {
    checkValide: function () {
      if (this.etatMdp === 1 && this.etatMdpValide === 1 && this.mdpValide.length >= 8) {
        this.$emit('etatSuite', 1)
      } else {
        this.$emit('etatSuite', 0)
      }
    },
    envoieMdp: function (id) {
      if (id !== 0) {
        // Si tout est OK alors on verifie si le code correspond
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/MDPoublie/Etape3.php', {
          mdp: this.mdp,
          mdpValide: this.mdpValide,
          id: id
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Si tout correspond
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Mot de passe modifié avec succès.', type: 'ok' })
            this.$emit('suiteOK')
          } else {
            // En cas d'erreur
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Les informations saisies ne correspondent pas !', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Il y a eu un problème dans les informations envoyées !', type: 'erreur' })
      }
    }
  }
}
</script>
