<template>
 <div class="columns is-centered top">
    <div class="column">
      <span class="icon is-large burger" @click="bougerBarre">
        <i class="fad fa-2x fa-bars"></i>
      </span>
      <span class="icon is-small burger burgerInfini"></span>
      <span @click="deconnexion" class="icon is-large droit has-tooltip-right" title="Déconnexion">
        <i class="fad fa-2x fa-power-off"></i>
      </span>
      <span class="icon is-large droit has-tooltip-right" title="Profil">
        <figure class="figure_avatar goDroite" id="Profil" @click="changerDroite('Profil')">
          <Avatar :avatar="$store.state.membre.avatar" :initial="$store.state.membre.prenom + ' ' + $store.state.membre.nom"/>
        </figure>
      </span>
    </div>
  </div>
</template>

<script>
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'Top',
  components: {
    Avatar
  },
  emits: ['vers-app', 'bouger-barre'],
  methods: {
    deconnexion: function () {
      this.$store.commit('deconnexion')
      this.changerDroite('')
    },
    changerDroite: function (compo) {
      this.$emit('vers-app', compo)
    },
    bougerBarre: function () {
      this.$emit('bouger-barre')
    }
  }
}
</script>

<style scoped>
.droit {
  float: right;
  cursor: pointer;
}
.burger {
  cursor: pointer;
}
.burger.burgerInfini {
  cursor: default;
}
/*Tablette*/
@media (max-width: 1215px) {
  .top {
    height: 10%;
  }
}

/*PC*/
@media(min-width:1216px) and (max-width: 1504px) {
  .top {
    height: 10%;
  }
}

/*Ecran*/
@media (min-width: 1505px) {
  .top {
    height: 10%;
  }
}
/*Sans Logo*/
.avatar {
  height: 2rem!important;
  font-size: 1.2rem!important;
}
</style>
