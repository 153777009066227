<template>
  <div class="option">
    <span
      @click="trier"
      class="icon is-medium has-text-primary fauxLien has-tooltip-right"
      title="Inverser le triage">
      <i class="fad fa-sort-amount-down"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Trier',
  emits: ['trier'],
  methods: {
    trier: function () {
      this.$emit('trier')
    }
  }
}
</script>
