<template>
  <section>
    <div v-if="$store.getters.droitOnglet(projet, 2) === 2" class="boutonOptionGM">
      <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouter"/>
      <Supprimer @supprimer="supprimer" @annulerSuppression="annulerSuppression" @validerSuppression="validerSuppression" ref="supprimer"/>
    </div>
    <article>
      <h1 class="title has-text-centered">Logiciels</h1>
      <h6 class="subtitle has-text-centered" style="font-size: 0.7rem;">Cliquez sur l'icône pour accéder au logiciel / page Internet</h6>
    </article>
    <!-- Ajouter un logiciel -->
    <div v-if="ajouterForm" class="tile is-ancestor" style="margin-bottom: 3vh;">
      <div class="tile is-12 is-vertical is-parent">
        <div class='une tile is-child box nouveau columns is-vcentered'>
          <div class="column nouveauLogo" style="position: relative;height: 16rem;">
            <Avatar :avatar="nouveauLogo !== null ? nouveauLogo : null" :initial="nouveauNom"/>
            <UploadImage :num="nouveauIdTemp" :inputId="'Logiciels_Logo_0'" :position="'abs'" @uploadOk="logoNew('nouveau')" @deleteOk="logoDelete('nouveau')" @backOk="logoAncien('nouveau')" ref="changerLogicielNouveau" />
          </div>
          <div class="column is-three-quarters">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Nom</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control" style="position: relative;">
                    <input @keyup="chercherListe($event, 'nouveau')" type='text' placeholder="Nom du logiciel" class='input' v-model="nouveauNom">
                    <div v-if="nouveauAffiche" class="select is-multiple" style="position: absolute;top: 100%;left: 0;width: 100%;z-index: 100;">
                      <select multiple style="width: 100%;">
                        <option
                        v-for="lo in Logiciels"
                        :key="lo"
                        v-show="lo.afficher"
                        @click="choisir(lo.id, 'nouveau')">
                        {{ lo.nom }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Lien</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type='url' class='input' placeholder="Lien" v-model="nouveauLien">
                    <span class="icon is-left">
                      <i class="fad fa-hashtag"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="field">
              <div class="field-label is-normal">
                <label class="label has-text-left">Description</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <div class='textarea' @focusout="updateText($event, 'nouveau')" contenteditable="true" v-html="nouveauDescription"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Liste des logiciels -->
    <article v-if="listeLogiciel.length > 0" class="columns is-multiline">
      <div
        v-for="logiciel in listeLogiciel"
        :key='logiciel'
        class="column unSeul is-12-tablet is-4-desktop">
        <div
          :aSupprimer="logiciel.aSupprimer"
          :aSelectionner="logiciel.aSelectionner"
          @click="aSelectionner(logiciel)"
          :aModifier="logiciel.aModifier"
          class="container">
          <div v-if="$store.getters.droitOnglet(projet, 2) === 2" class="boutonOptionGM">
            <Modifier @modifier="modifier(logiciel)" @annulerModification="annulerModification" @validerModification="validerModification(logiciel)" :ref="'logiciel' + logiciel.id" />
          </div>
          <!-- Titre -->
          <div class="columns is-centered is-vcentered">
            <div class="column is-8">
              <h3 class="subtitle has-text-centered" v-if="!logiciel.aModifier"><b>{{ logiciel.nom }}</b></h3>
              <div v-else class="field" style="margin-top: 3rem;">
                <div class="control" style="position: relative;">
                  <input @keyup="chercherListe($event, logiciel)" type='text' placeholder="Nom du logiciel" class='input' v-model="logiciel.nom">
                  <div v-if="logiciel.afficheListe" class="select is-multiple" style="position: absolute;top: 100%;left: 0;width: 100%;z-index: 100;">
                    <select multiple style="width: 100%;">
                      <option
                      v-for="lo in Logiciels"
                      :key="lo"
                      v-show="lo.afficher"
                      @click="choisir(lo.id, logiciel)">
                      {{ lo.nom }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <div class="column is-6 fauxLien" @click='ouvrirLogiciel(logiciel)'>
              <Avatar :avatar="logiciel.logo !== null ? logiciel.logo : null" :initial="logiciel.nom" />
            </div>
          </div>
          <div v-if="logiciel.aModifier" class="columns">
            <div class="column is-12">
              <div class="field">
                <div class="control has-icons-left">
                  <input type='url' class='input' placeholder="Lien" v-model="logiciel.lien">
                  <span class="icon is-left">
                    <i class="fad fa-hashtag"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <!-- Description du logiciel -->
            <div class="column has-text-centered">
              <div v-if="!logiciel.aModifier" v-html="logiciel.description" style="overflow-y: auto;height: 6rem;"></div>
              <div v-else class="field">
                <div class="control">
                  <div class='textarea' @focusout="updateText($event, logiciel)" contenteditable="true" placeholder="Description du logiciel" v-html="logiciel.description"></div>
                </div>
              </div>
            </div>
          </div>
          <!-- Logo du projet P2 -->
          <div class="uploadImage" v-if='logiciel.aModifier'>
            <UploadImage :num="logiciel.id" :inputId="'Logiciels_Logo_' + logiciel.id" :position="'ini'" @uploadOk="logoNew(logiciel)" @deleteOk="logoDelete(logiciel)" @backOk='logoAncien(logiciel)' :ref="'changerLogiciel' + logiciel.id" />
          </div>
        </div>
      </div>
    </article>
    <h3 v-else class="subtitle has-text-centered">Aucun logiciel n'est disponible pour ce projet</h3>
  </section>
</template>

<script>
import Ajouter from '@/components/Options/Ajouter.vue'
import Supprimer from '@/components/Options/Supprimer.vue'
import Avatar from '@/components/Avatar.vue'
import Modifier from '@/components/Options/Modifier.vue'
import UploadImage from '@/components/Formulaire/UploadImage.vue'
import axios from 'axios'

export default {
  name: 'Logiciels',
  props: ['projet'],
  components: {
    Ajouter,
    Supprimer,
    Avatar,
    Modifier,
    UploadImage
  },
  data () {
    return {
      listeLogiciel: [],
      Logiciels: [],
      ajouterForm: false,
      nouveauLogo: null,
      nouveauNom: '',
      nouveauLien: '',
      nouveauDescription: '',
      nouveauAffiche: false,
      nouveauIdTemp: this.projet + '_' + this.$store.getters.numMembre
    }
  },
  created: function () {
    axios.get(this.$store.getters.url + 'back/Logiciels/Charger.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.listeLogiciel = reponse.data.donnees
        this.Logiciels = reponse.data.logiciels
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de lecture sur cette onglets.', type: 'attention' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  methods: {
    // Fonctions de base
    ouvrirLogiciel: function (logiciel) {
      if (!logiciel.aModifier && !logiciel.aSupprimer) {
        window.open(logiciel.lien, '_blank')
      }
    },
    // Fonctions de modification d'input
    updateText: function (event, item) {
      if (item === 'nouveau') {
        this.nouveauDescription = event.target.innerHTML
      } else {
        item.description = event.target.innerHTML
      }
    },
    choisir: function (valeur, logiciel) {
      if (logiciel === 'nouveau') {
        this.nouveauNom = this.Logiciels[valeur].nom
        this.nouveauLogo = this.Logiciels[valeur].logo
        this.nouveauAffiche = false
      } else {
        logiciel.nom = this.Logiciels[valeur].nom
        logiciel.logo = this.Logiciels[valeur].logo
        logiciel.afficheListe = false
      }
    },
    chercherListe: function (event, logiciel) {
      if (event.target.value.length > 2) {
        let nbr = 0
        for (const prop in this.Logiciels) {
          if (this.Logiciels[prop].nom.toLowerCase().includes(event.target.value.toLowerCase())) {
            this.Logiciels[prop].afficher = true
            nbr++
          } else {
            this.Logiciels[prop].afficher = false
          }
        }
        if (logiciel === 'nouveau') {
          if (nbr > 0) {
            this.nouveauAffiche = true
          } else {
            this.nouveauAffiche = false
          }
        } else {
          if (nbr > 0) {
            logiciel.afficheListe = true
          } else {
            logiciel.afficheListe = false
          }
        }
      } else {
        if (logiciel === 'nouveau') {
          this.nouveauAffiche = false
        } else {
          logiciel.afficheListe = false
        }
      }
    },
    validerForm: function (logiciel) {
      let i = 0
      let message = ''
      if (logiciel.nom === '') {
        i++
        message += 'Le logiciel n\'a pas de nom<br>'
      }
      if (logiciel.lien === '') {
        i++
        message += 'Le logiciel n\'a pas de lien<br>'
      }
      if (!this.$store.state.regex.lien.test(logiciel.lien)) {
        i++
        message += 'Le lien du logiciel n\'est conforme.'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    // Gestion du logo
    logoNew: function (logiciel) {
      if (logiciel === 'nouveau') {
        this.nouveauLogo = this.$refs.changerLogicielNouveau.base64
      } else {
        logiciel.logo = this.$refs['changerLogiciel' + logiciel.id].base64
      }
    },
    logoDelete: function (logiciel) {
      if (logiciel === 'nouveau') {
        this.nouveauLogo = null
      } else {
        logiciel.logo = null
      }
    },
    logoAncien: function (logiciel) {
      if (logiciel === 'nouveau') {
        this.nouveauLogo = null
      } else {
        logiciel.logo = this.$refs['logiciel' + logiciel.id].recupererAncienneValeur().logo
      }
    },
    // Modifier un logiciel
    modifier: function (item) {
      item.aModifier = true
      this.$refs['logiciel' + item.id].valeur(JSON.parse(JSON.stringify(item)))
    },
    annulerModification: function (anciennesValeur) {
      for (var i = 0; i < this.listeLogiciel.length; i++) {
        if (anciennesValeur.id === this.listeLogiciel[i].id) {
          this.listeLogiciel[i] = anciennesValeur
          this.listeLogiciel[i].aModifier = false
          break
        }
      }
    },
    validerModification: function (logiciel) {
      if (this.validerForm(logiciel)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Logiciels/Modifier.php?i=' + this.$store.getters.numMembre, {
          logiciel: logiciel,
          projet: this.projet
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Si création d'un nouveau logiciel alors ajout dans liste logiciel
            if (logiciel.id_logiciel !== reponse.data.id_logiciel) {
              this.Logiciels[reponse.data.id_logiciel] = {
                id: reponse.data.id_logiciel,
                nom: logiciel.nom,
                logo: logiciel.logo === null ? null : reponse.data.id_logiciel,
                afficher: false
              }
            }
            // Changer l'identifiant du logiciel
            logiciel.id_logiciel = reponse.data.id_logiciel
            // Remettre par défaut
            logiciel.aModifier = false
            this.$refs['logiciel' + logiciel.id].validerOk()

            console.log(logiciel)
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Logiciel modifié avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    // Supprimer un logiciel
    supprimer: function () {
      for (var i = 0; i < this.listeLogiciel.length; i++) {
        this.listeLogiciel[i].aSupprimer = true
      }
    },
    annulerSuppression: function () {
      for (var i = 0; i < this.listeLogiciel.length; i++) {
        this.listeLogiciel[i].aSupprimer = false
        this.listeLogiciel[i].aSelectionner = false
      }
    },
    aSelectionner: function (logiciel) {
      if (logiciel.aSupprimer) {
        if (logiciel.aSelectionner) {
          logiciel.aSelectionner = false
        } else {
          logiciel.aSelectionner = true
        }
      }
    },
    validerSuppression: function () {
      const liste = []
      for (var i = 0; i < this.listeLogiciel.length; i++) {
        if (this.listeLogiciel[i].aSelectionner) {
          liste.push(this.listeLogiciel[i].id)
        }
      }
      if (liste.length > 0) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Logiciels/Supprimer.php?i=' + this.$store.getters.numMembre, {
          liste: liste,
          projet: this.projet
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Supprimer la valeur
            const nonSupprimer = []
            for (var i = 0; i < this.listeLogiciel.length; i++) {
              if (liste.indexOf(this.listeLogiciel[i].id) === -1) {
                nonSupprimer.push(this.listeLogiciel[i])
              }
            }
            this.listeLogiciel = nonSupprimer
            this.$refs.supprimer.validerOk()
            this.annulerSuppression()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Logiciel(s) supprimé(s) avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien a supprimer !', type: 'info' })
      }
    },
    // Ajouter un projet
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouveauLogo = null
      this.nouveauNom = ''
      this.nouveauDescription = ''
      this.nouveauLien = ''
    },
    validerAjout: function () {
      const logiciel = {
        id: 0,
        description: this.nouveauDescription,
        id_logiciel: 0,
        nom: this.nouveauNom,
        logo: this.nouveauLogo,
        lien: this.nouveauLien,
        aModifier: false,
        aSelectionner: false,
        aSupprimer: false,
        afficheListe: false
      }
      if (this.validerForm(logiciel)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Logiciels/Ajouter.php?i=' + this.$store.getters.numMembre, {
          logiciel: logiciel,
          projet: this.projet
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Ajouter un id
            logiciel.id = reponse.data.id
            // Ajouter un id_logiciel
            logiciel.id_logiciel = reponse.data.id_logiciel
            // Vérifier si id_logiciel n'existe pas dans Logiciel
            let trouver = false
            for (const prop in this.Logiciels) {
              if (prop === reponse.data.id_logiciel) {
                trouver = true
              }
            }
            if (trouver) {
              this.Logiciels[reponse.data.id_logiciel] = {
                id: reponse.data.id_logiciel,
                nom: logiciel.nom,
                logo: logiciel.logo === null ? null : reponse.data.id_logiciel,
                afficher: false
              }
            }
            // Mettre le bon logo
            if (logiciel.logo !== null) {
              logiciel.logo = reponse.data.id_logiciel
            }
            // Ajouter dans la liste
            this.listeLogiciel.push(logiciel)
            // Remise par défaut
            this.$refs.ajouter.validerOk()
            this.annulerAjout()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Logiciel ajouté avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    }
  }
}
</script>

<style scoped>
@media (prefers-color-scheme: light) {
  .unSeul>div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}

@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}

.unSeul > .container {
  border-radius: 6px;
  position: relative;
}

.unSeul > .container[aModifier=true] {
  height: auto;
}
/*Sans Logo*/
.avatar {
  height: 8rem !important;
  font-size: 3rem !important;
  width: auto !important;
  font-weight: bold;
}

/*Avec Logo*/
.avatar img {
  width: auto !important;
}
.unSeul .container[aSupprimer=true] {
  cursor: pointer;
}
.unSeul .container[aSupprimer=true][aSelectionner=true] {
  border: 3px dashed #f14668;
}
</style>
