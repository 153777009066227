<template>
  <section>
    <div class="boutonOptionDM">
      <Trier @trier="changeTrie"/>
      <Cacher @cacher="cacherArchive" @afficher="afficherArchive" />
    </div>
    <div class="boutonOptionGM">
      <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouter"/>
      <Supprimer @supprimer="supprimer" @annulerSuppression="annulerSuppression" @validerSuppression="validerSuppression" ref="supprimer"/>
    </div>
    <article>
      <h1 class="title has-text-centered">Gestion des Projets</h1>
    </article>
    <!-- Nouveau projet -->
    <div v-if="ajouterForm" class="tile is-ancestor" style="margin-bottom: 3vh;">
      <div class="tile is-12 is-vertical is-parent">
        <div class='une tile is-child box nouveau'>
          <div class="columns is-centered is-vcentered">
            <div class="column nouveauLogo" style="position: relative;height: 20rem;">
              <Avatar :avatar="nouveauLogo" :initial="nouveauNom"/>
              <UploadImage :num="nouveauIdTemp" :inputId="'Projet_Logo_0'" :position="'abs'" @uploadOk="logoNew('nouveau')" @deleteOk="logoDelete('nouveau')" @backOk="logoAncien('nouveau')" ref="changerLogoNouveau"/>
            </div>
            <div class="column is-5">
              <!-- Nom du projet -->
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Nom</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input type='text' class='input' v-model="nouveauNom">
                    </div>
                  </div>
                </div>
              </div>
              <!-- Date de creation projet -->
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Créé le</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input type='date' class='input' pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="nouveauDate">
                    </div>
                  </div>
                </div>
              </div>
              <!-- Qui créé du projet -->
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Crée par</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input type='text' class='input' v-model="nouveauCreation">
                    </div>
                  </div>
                </div>
              </div>
              <!-- Description du projet -->
              <div class="field">
                <div class="field-label is-normal">
                  <label class="label has-text-left">Description</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div class='textarea' @focusout="updateText($event, 'nouveau')" contenteditable="true" v-html="nouveauDescription"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <table class="table is-fullwidth">
                <tbody>
                  <tr v-for="onglet in $store.state.listeOnglet" :key="onglet">
                    <th class="titreOnglet">{{ onglet.nom }}</th>
                    <td class="iconOnglet">
                      <div class="field">
                        <input type="checkbox" class="is-checkradio" :name="'nouveau' + onglet.id" :id="'nouveau' + onglet.id" v-model="nouveauOnglets[onglet.id]">
                        <label :for="'nouveau' + onglet.id"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Liste des projets -->
    <h1 v-if="$store.state.membre.listeProjet.length===0" class="has-text-centered subtitle rien">Aucun projet trouvé !</h1>
    <article v-else class="columns is-multiline listeProjet">
      <div
      v-for="projet in $store.state.membre.listeProjet"
      v-show="parseInt(projet.statut) === 1 ||  (parseInt(projet.statut) === 0 && archiveAffiche)"
      :key="projet" class="column unSeul is-12-tablet is-6-desktop">
        <div
        :aSupprimer="projet.aSupprimer"
        :aSelectionner="projet.aSelectionner"
        @click="aSelectionner(projet)"
        class="container">
          <div class="boutonOptionGM">
            <Modifier tooltipSens="top" @modifier="modifier(projet)" @annulerModification="annulerModification" @validerModification="validerModification(projet)" :ref="'projet' + projet.numProjet" />
          </div>
          <div class="columns is-vcentered">
            <!-- Logo du projet P1 -->
            <div class="column is-2">
              <Avatar :avatar="projet.logo !== null ? projet.logo : null" :initial="projet.nom"/>
            </div>
            <!-- Nom du projet -->
            <div class="column is-offset-1 is-6">
              <h3 class="subtitle has-text-centered" v-if="!projet.aModifier"><b>{{ projet.nom }}</b></h3>
              <div v-else class="field">
                <div class="control">
                  <input type='text' placeholder="Nom du projet" class='input' v-model="projet.nom">
                </div>
              </div>
            </div>
            <div class="column is-3"></div>
          </div>
          <div class="columns is-centered">
            <!-- Description du projet -->
            <div class="column has-text-centered">
              <div v-if="!projet.aModifier && projet.description!==''" v-html="projet.description"></div>
              <div v-else class="field">
                <div class="control">
                  <div class='textarea' @focusout="updateText($event, projet)" contenteditable="true" placeholder="Description du projet" v-html="projet.description"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <!-- Date de creation -->
            <div class="column has-text-centered">
              <p v-if="!projet.aModifier">
                <b>Créé le :</b> {{ projet.dateCreationHtml }}
              </p>
              <div v-else class="field">
                <div class="control">
                  <input type='date' class='input' placeholder="Date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="projet.dateCreationInput">
                </div>
              </div>
            </div>
            <!-- Créateur -->
            <div class="column has-text-centered">
              <p v-if="!projet.aModifier">
                <b>Par :</b> {{ projet.createur }}
              </p>
              <div v-else class="field">
                <div class="control">
                  <input type='text' class='input' placeholder="Createurs" v-model="projet.createur">
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-centered">
            <!-- Statut -->
            <div class="column has-text-centered">
              <p v-if="!projet.aModifier">
                <b>Statut :</b> {{ parseInt(projet.statut) === 0 ? 'Archivé' : 'Actif' }}
              </p>
              <div v-else class="field">
                <Switch :html="['Archivé', 'Actif']" :etat="parseInt(projet.statut)" :ref="'switch' + projet.numProjet" :switchId="'switch' + projet.numProjet"/>
              </div>
            </div>
            <!-- Ordre -->
            <div class="column has-text-centered">
              <p v-if="!projet.aModifier">
                <b>Rang :</b> #{{ projet.ordre }}
              </p>
              <div v-else class="field">
                <div class="control has-icons-left">
                  <input @change='verifOrdre($event, projet)' type='number' class='input' v-model="projet.ordre">
                  <span class="icon is-left">
                    <i class="fad fa-hashtag"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <!-- Liste des onglets actifs -->
          <div class="columns is-centered">
            <div class="column is-12">
              <h3 class="subtitle has-text-centered"><b>Gestion des onglets</b></h3>
              <table class="table is-fullwidth">
                <tbody>
                  <tr v-for="onglet in $store.state.listeOnglet" :key="onglet">
                    <th class="titreOnglet">{{ onglet.nom }}</th>
                    <td v-if="!projet.aModifier" class="iconOnglet">
                      <span v-if="projet.onglets[onglet.id]" class="icon has-text-success" style="font-size: 1em;"><i class="fad fa-check"></i></span>
                      <span v-else class="icon has-text-danger" style="font-size: 1em;"><i class="fad fa-times"></i></span>
                    </td>
                    <td v-else class="iconOnglet">
                      <div class="field">
                        <input type="checkbox" class="is-checkradio" :name="projet.numProjet + '_' + onglet.id" :id="projet.numProjet + '_' + onglet.id" v-model="projet.onglets[onglet.id]">
                        <label :for="projet.numProjet + '_' + onglet.id"></label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Logo du projet P2 -->
          <div class="uploadImage" v-if='projet.aModifier'>
            <UploadImage :num="projet.numProjet" :inputId="'Projet_Logo_' + projet.numProjet" :position="'ini'" @uploadOk="logoNew(projet)" @deleteOk="logoDelete(projet)" @backOk='logoAncien(projet)' :ref="'changerLogo' + projet.numProjet" />
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>

import UploadImage from '@/components/Formulaire/UploadImage.vue'
import Avatar from '@/components/Avatar.vue'
import Switch from '@/components/Formulaire/Switch.vue'
import Modifier from '@/components/Options/Modifier.vue'
import Trier from '@/components/Options/Trier.vue'
import Cacher from '@/components/Options/Cacher.vue'
import Ajouter from '@/components/Options/Ajouter.vue'
import Supprimer from '@/components/Options/Supprimer.vue'
import axios from 'axios'

export default {
  name: 'GestionProjet',
  components: {
    UploadImage,
    Avatar,
    Switch,
    Modifier,
    Trier,
    Cacher,
    Ajouter,
    Supprimer
  },
  data () {
    return {
      sens: 'ASC',
      sensListe: { ASC: 'DESC', DESC: 'ASC' },
      archiveAffiche: true,
      ajouterForm: false,
      nouveauLogo: null,
      nouveauNom: '',
      nouveauDate: '',
      nouveauCreation: '',
      nouveauOnglets: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 },
      nouveauDescription: '',
      nouveauIdTemp: this.$store.state.membre.groupeEnCours.idGroupe + '_' + this.$store.getters.numMembre
    }
  },
  methods: {
    // Options générales
    changeTrie: function () {
      this.sens = this.sensListe[this.sens]
      this.trier()
    },
    trier: function () {
      this.$store.state.membre.listeProjet.sort(function (a, b) {
        return a.ordre - b.ordre
      })
      if (this.sens === 'DESC') {
        this.$store.state.membre.listeProjet.reverse()
      }
    },
    cacherArchive: function () {
      this.archiveAffiche = false
    },
    afficherArchive: function () {
      this.archiveAffiche = true
    },
    // Fonctions de modification d'input
    updateText: function (event, item) {
      if (item === 'nouveau') {
        this.nouveauDescription = event.target.innerHTML
      } else {
        item.description = event.target.innerHTML
      }
    },
    verifOrdre: function (e, projet) {
      if (e.target.value <= 0) {
        projet.ordre = 1
      } else if (projet.ordre > this.$store.state.membre.listeProjet.length) {
        projet.ordre = this.$store.state.membre.listeProjet.length
      }
    },
    validerForm: function (projet) {
      let i = 0
      let message = ''
      if (projet.nom === '') {
        i++
        message += 'Le projet n\'a pas de nom<br>'
      }
      if (projet.dateCreationInput === '') {
        i++
        message += 'Pas de date de création<br>'
      }
      if (projet.createur === '') {
        i++
        message += 'Pas de créateur<br>'
      }
      if (projet.ordre <= 0) {
        i++
        message += 'Le rang ne peut être égal ou inférieur à 0<br>'
      } else if (projet.ordre > this.$store.state.membre.listeProjet.length && this.$store.state.membre.listeProjet.length !== 0) {
        i++
        message += 'Le rang ne peut être supérieur au nombre de projet (' + this.$store.state.membre.listeProjet.length + ')<br>'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    // Gestion du logo
    logoNew: function (projet) {
      if (projet === 'nouveau') {
        this.nouveauLogo = this.$refs.changerLogoNouveau.base64
      } else {
        projet.logo = this.$refs['changerLogo' + projet.numProjet].base64
      }
    },
    logoDelete: function (projet) {
      if (projet === 'nouveau') {
        this.nouveauLogo = null
      } else {
        projet.logo = null
      }
    },
    logoAncien: function (projet) {
      if (projet === 'nouveau') {
        this.nouveauLogo = null
      } else {
        projet.logo = this.$refs['projet' + projet.numProjet].recupererAncienneValeur().logo
      }
    },
    // Modifier un projet
    modifier: function (item) {
      item.aModifier = true
      this.$refs['projet' + item.numProjet].valeur(JSON.parse(JSON.stringify(item)))
    },
    annulerModification: function (anciennesValeur) {
      for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
        if (anciennesValeur.numProjet === this.$store.state.membre.listeProjet[i].numProjet) {
          this.$store.state.membre.listeProjet[i] = anciennesValeur
          this.$store.state.membre.listeProjet[i].aModifier = false
          break
        }
      }
    },
    validerModification: function (projet) {
      if (this.validerForm(projet)) {
        this.$store.commit('chargement')
        // Récupérer Switch
        const statut = this.$refs['switch' + projet.numProjet].getValeur()
        axios.post(this.$store.getters.url + 'back/Projet/Modifier.php?i=' + this.$store.getters.numMembre, {
          projet: projet,
          statut: statut
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Remettre le statut
            projet.statut = statut
            // Changer les données de date
            projet.dateCreationHtml = reponse.data.dateCreationHtml
            projet.dateCreation = reponse.data.dateCreation
            // Remettre par défaut
            projet.aModifier = false
            this.$refs['projet' + projet.numProjet].validerOk()
            // Calculer les ordres
            if (projet.ordre !== reponse.data.ancienOrdre) {
              if (projet.ordre > reponse.data.ancienOrdre) {
                for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
                  if (this.$store.state.membre.listeProjet[i].groupe === projet.groupe) {
                    if (this.$store.state.membre.listeProjet[i].numProjet !== projet.numProjet) {
                      this.$store.state.membre.listeProjet[i].ordre--
                    }
                  }
                }
              } else {
                for (var j = 0; j < this.$store.state.membre.listeProjet.length; j++) {
                  if (this.$store.state.membre.listeProjet[j].groupe === projet.groupe) {
                    if (this.$store.state.membre.listeProjet[j].numProjet !== projet.numProjet) {
                      this.$store.state.membre.listeProjet[j].ordre++
                    }
                  }
                }
              }
            }
            // Trier par ordre
            this.trier()

            this.$store.commit('afficherNotification', { affiche: true, texte: 'Projet modifié avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    supprimer: function () {
      for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
        this.$store.state.membre.listeProjet[i].aSupprimer = true
      }
    },
    annulerSuppression: function () {
      for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
        this.$store.state.membre.listeProjet[i].aSupprimer = false
        this.$store.state.membre.listeProjet[i].aSelectionner = false
      }
    },
    aSelectionner: function (projet) {
      if (projet.aSupprimer) {
        if (projet.aSelectionner) {
          projet.aSelectionner = false
        } else {
          projet.aSelectionner = true
        }
      }
    },
    validerSuppression: function () {
      const liste = []
      for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
        if (this.$store.state.membre.listeProjet[i].aSelectionner) {
          liste.push(this.$store.state.membre.listeProjet[i].numProjet)
        }
      }
      if (liste.length > 0) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Projet/Supprimer.php?i=' + this.$store.getters.numMembre, {
          liste: liste,
          groupe: this.$store.state.membre.groupeEnCours
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // A réparer
            const nonSupprimer = []
            for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
              if (liste.indexOf(this.$store.state.membre.listeProjet[i].numProjet) === -1) {
                nonSupprimer.push(this.$store.state.membre.listeProjet[i])
              }
            }
            this.$store.state.membre.listeProjet = nonSupprimer
            // Recalculer les ordres
            let ordre = 1
            for (var j = 0; j < this.$store.state.membre.listeProjet.length; j++) {
              this.$store.state.membre.listeProjet.ordre = ordre
              ordre++
            }
            this.trier()
            this.$refs.supprimer.validerOk()
            this.annulerSuppression()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Projet(s) supprimé(s) avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien a supprimer !', type: 'info' })
      }
    },
    // Ajouter un projet
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouveauLogo = null
      this.nouveauNom = ''
      this.nouveauDate = ''
      this.nouveauCreation = ''
      this.nouveauOnglets = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0 }
      this.nouveauDescription = ''
    },
    validerAjout: function () {
      const projet = {
        aModifier: false,
        class: 'accordion',
        createur: this.nouveauCreation,
        dateCreation: 0,
        dateCreationHtml: '',
        dateCreationInput: this.nouveauDate,
        description: this.nouveauDescription,
        groupe: this.$store.state.membre.groupeEnCours.idGroupe,
        logo: this.nouveauLogo,
        nom: this.nouveauNom,
        numProjet: this.nouveauIdTemp,
        onglets: this.nouveauOnglets,
        ordre: 1,
        statut: 1
      }
      if (this.validerForm(projet)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Projet/Ajouter.php?i=' + this.$store.getters.numMembre, {
          projet: projet,
          nbrProjet: this.$store.state.membre.listeProjet.length
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Ajouter un numProjet
            projet.numProjet = reponse.data.numProjet
            // Modifier les valeurs de dates
            projet.dateCreation = reponse.data.dateCreation
            projet.dateCreationHtml = reponse.data.dateCreationHtml
            // Mettre le bon logo
            if (projet.logo !== null) {
              projet.logo = reponse.data.numProjet
            }
            // Modifier l'ordre
            projet.ordre = this.$store.state.membre.listeProjet.length + 1
            // Ajouter dans la liste
            this.$store.state.membre.listeProjet.push(projet)
            // Ajouter les droits
            this.$store.state.membre.listeDroit[reponse.data.numProjet] = reponse.data.droit
            // Remise par défaut
            this.$refs.ajouter.validerOk()
            this.annulerAjout()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Projet ajouté avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    }
  }
}

</script>

<style scoped>
@media (prefers-color-scheme: light) {
  .unSeul>div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}

@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}

.unSeul > .container {
  border-radius: 6px;
  position: relative;
}

/*Sans Logo*/
.listeProjet .avatar {
  height: 2rem !important;
  font-size: 1.2rem !important;
  width: 2rem !important;
  font-weight: bold;
}

/*Avec Logo*/
.listeProjet .avatar img {
  width: auto !important;
}

.titreOnglet {
  width: 80%;
}
.iconOnglet {
  width: 20%;
}
table {
  background-color: transparent!important;
  color: initial!important;
}
th {
  color: inherit!important;
}
.unSeul .container[aSupprimer=true] {
  cursor: pointer;
}
.unSeul .container[aSupprimer=true][aSelectionner=true] {
  border: 3px dashed #f14668;
}
</style>
