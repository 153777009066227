<template>
  <section class="bottom">
    <ul>
      <li v-if="$store.state.membre.groupeEnCours.statutMembre==1" class="fauxLien goDroite" data="gestionMembre" @click="changerDroite('GestionGroupe')" id="GestionGroupe">
        <span class="icon is-medium"><i class="fad fa-user-shield"></i></span> <span class="goDroiteTexte">Gestion du groupe</span>
      </li>
      <li v-if="$store.state.membre.groupeEnCours.statutMembre==1" class="fauxLien goDroite" data="gestionProjet" @click="changerDroite('GestionProjet')" id="GestionProjet">
        <span class="icon is-medium"><i class="fad fa-tasks-alt"></i></span> <span class="goDroiteTexte">Gestion des projets</span>
      </li>
      <li class="fauxLien goDroite" @click="changerDroite('Annuaire')" id="Annuaire">
        <span class="icon is-medium">
          <i class="fad fa-address-book"></i>
        </span>
        <span class="goDroiteTexte"> Annuaire</span>
      </li>
      <li class="fauxLien goDroite" @click="changerDroite('MentionLegale')" id="MentionLegale">
        <span class="icon is-medium">
          <i class="fad fa-balance-scale-right"></i>
        </span>
        <span class="goDroiteTexte"> Mentions légales</span>
      </li>
      <li class="fauxLien goDroite" @click="changerDroite('APropos')" id="APropos">
        <span class="icon is-medium">
          <i class="fad fa-book-heart"></i>
        </span>
        <span class="goDroiteTexte"> &Agrave; propos</span>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: 'Bottom',
  emits: ['vers-app'],
  methods: {
    changerDroite: function (compo) {
      this.$emit('vers-app', compo)
    }
  }
}
</script>

<style scoped>
/*Tablette*/
@media (max-width: 1215px) {
  .bottom {
    height: 24%;
    position: relative;
  }
}

/*PC*/
@media(min-width:1216px) and (max-width: 1504px) {
  .bottom {
    height: 20%;
    position: relative;
  }
}

/*Ecran*/
@media (min-width: 1505px) {
  .bottom {
    height: 20%;
    position: relative;
  }
}
.bottom {
  display: grid;
}
ul {
  align-self: end;
}
</style>
