<template>
    <h1 class="title has-text-centered">MOT DE PASSE OUBLIE</h1>
    <Etape1 v-if="etape==1" @etatSuite='etatSuite' ref='etape1' @suiteOK='suiteOK' @setEmail='setEmail'/>
    <Etape2 v-if="etape==2" @etatSuite='etatSuite' ref='etape2' @suiteOK='suiteOK' @setIdentifiant='setIdentifiant'/>
    <Etape3 v-if="etape==3" @etatSuite='etatSuite' ref='etape3' @suiteOK='suiteOK'/>
    <footer class="card-footer">
      <span class="card-footer-item">
        <button @click="retour" class="button retour"><b>Retour</b></button>
      </span>
      <span v-if='afficheSuite==1' class="card-footer-item">
        <button @click="suite" class="button suite"><b>Suite</b></button>
      </span>
    </footer>
</template>

<script>
import Etape1 from '@/components/Connexion/Etape1.vue'
import Etape2 from '@/components/Connexion/Etape2.vue'
import Etape3 from '@/components/Connexion/Etape3.vue'
export default {
  name: 'FormulaireMDPoublie',
  emits: ['changerEtape'],
  components: {
    Etape1,
    Etape2,
    Etape3
  },
  data () {
    return {
      etape: 1,
      afficheSuite: 0,
      email: '',
      id: 0
    }
  },
  methods: {
    retour: function () {
      if (this.etape === 1) {
        this.$emit('changerEtape', 'connexion')
      } else {
        this.etape--
      }
    },
    suite: function () {
      if (this.etape === 1) {
        this.$refs.etape1.envoieMail()
      } else if (this.etape === 2) {
        this.$refs.etape2.envoieCode(this.email)
      } else if (this.etape === 3) {
        this.$refs.etape3.envoieMdp(this.id)
      }
    },
    suiteOK: function () {
      if (this.etape === 3) {
        this.$emit('changerEtape', 'connexion')
      } else {
        this.etape++
        this.afficheSuite = 0
      }
    },
    etatSuite: function (etat) {
      this.afficheSuite = etat
    },
    setEmail: function (email) {
      this.email = email
    },
    setIdentifiant: function (id) {
      this.id = id
      console.log(this.id)
    }
  }
}
</script>

<style scoped>
footer {
  border:none;
  margin-top: 3%;
}
.card-footer-item {
  border:none;
}
button {
  width: 10vw;
  border: none;
}
.retour {
  background-color: black;
  color: white;
}
.suite {
  background-color: #47479F;
  color: white;
}
.suite:hover, .retour:hover {
  background-color: #6E0E67;
  color: white;
  cursor: pointer;
}
</style>
