<template>
  <section v-show="!afficherPetit" class="accordions">
    <article v-for="projet in $store.state.membre.listeProjet" :key="projet" :class="projet.class">
      <div v-if="projet.groupe === $store.state.membre.groupeEnCours.idGroupe && parseInt(projet.statut) === 1" class="accordion-header toggle" @click="afficher(projet)">
        <div style="display: flex;">
          <span>
            <Avatar :avatar="projet.logo !== null ? projet.logo : null" :initial="projet.nom"/>
          </span>
        </div>
      </div>
    </article>
  </section>
  <section v-show="afficherPetit" class="accordions">
      <article :class="projetPetit.class">
        <div v-if="projetPetit.groupe === $store.state.membre.groupeEnCours.idGroupe && parseInt(projetPetit.statut) === 1" class="accordion-header toggle" @click="retour(projetPetit)">
          <div style="display: flex;">
            <span>
              <Avatar :avatar="projetPetit.logo !== null ? projetPetit.logo : null" :initial="projetPetit.nom"/>
            </span>
          </div>
        </div>
        <div v-if="projetPetit.groupe === $store.state.membre.groupeEnCours.idGroupe && parseInt(projetPetit.statut) === 1" class="accordion-body accordionPetit">
          <div class="accordion-content">
            <ul>
              <li
                v-for="onglet in $store.state.listeOnglet"
                :key="onglet"
                class="fauxLien"
                @click="changerDroiteProjet(onglet.nom + '_' + projetPetit.numProjet)"
                :id="onglet.nom + '_' + projetPetit.numProjet">
                <div v-if="projetPetit.onglets[onglet.id] && $store.state.membre.listeDroit[projetPetit.numProjet][onglet.id] > 0">
                  <span class="icon is-medium">
                    <i :class="onglet.icon"></i>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </article>
  </section>
</template>

<script>
import Avatar from '@/components/Avatar.vue'
import 'bulma-accordion/dist/css/bulma-accordion.min.css'
export default {
  name: 'BarreLateralePetit',
  components: {
    Avatar
  },
  emits: ['vers-app-projet'],
  data () {
    return {
      afficherPetit: false,
      projetPetit: {}
    }
  },
  methods: {
    afficher: function (projet) {
      projet.class = 'accordion is-active'
      this.projetPetit = projet
      this.afficherPetit = true
    },
    retour: function (projet) {
      projet.class = 'accordion'
      this.projetPetit = {}
      this.afficherPetit = false
    },
    changerDroiteProjet: function (compo) {
      this.$emit('vers-app-projet', compo)
    },
    charger: function () {
      for (var i = 0; i < this.$store.state.membre.listeProjet.length; i++) {
        if (this.$store.state.membre.listeProjet[i].class.includes('is-active')) {
          this.afficher(this.$store.state.membre.listeProjet[i])
        }
      }
    }
  }
}
</script>
<style scoped>
/*Accordions général*/
.accordions {
  overflow: auto;
  overflow-x: hidden;
  margin-right: 0.3vw;
}
.accordionPetit {
  overflow: hidden;
  padding-top: 1rem;
}

.accordions .accordion {
  background-color: transparent !important;
}

.accordions .accordion .accordion-header {
  background-color: transparent !important;
  color: black !important;
  font-weight: bold;
}

.accordions .accordion .accordion-header+.accordion-body {
  border: none !important;
}

.accordions .accordion .accordion-header+.accordion-body .accordion-content {
  padding: 0 !important;
}

.afficheListe {
  font-size: 1.5em;
}

.accordion.is-active .afficheListe {
  transform: rotate(90deg);
}

.accordion-content ul li {
  text-indent: 1vw;
  line-height: 2rem;
}

li {
  text-indent: 0!important;
}

/*Sans Logo*/
.avatar {
  height: 1.6rem!important;
  font-size: 1.2rem!important;
  min-width: 1.6rem!important;
  max-width: fit-content;
}

/*Avec Logo*/
.avatar img {
  width:auto!important;
}

.goDroite.active {
  font-weight: bolder;
}

.listeGoDroite ul {
  list-style: none;
}

.listeGoDroite {
  padding-top: 3vh;
}

.figure_logo {
  cursor: pointer;
}
</style>
