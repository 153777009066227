<template>
  <section class="accordions">
    <article v-for="projet in $store.state.membre.listeProjet" :key="projet" :class="projet.class">
      <div v-if="projet.groupe === $store.state.membre.groupeEnCours.idGroupe && parseInt(projet.statut) === 1" class="accordion-header toggle" @click="changerAffiche(projet.numProjet)">
        <div style="display: flex;">
          <Avatar :avatar="projet.logo !== null ? projet.logo : null" :initial="projet.nom" :s="'contain'"/>
          <span style="padding-left: 1rem;" class="goDroiteTexte">{{ projet.nom }}</span>
        </div>
        <span class="is-toggle afficheListe icon is-medium">
          <i class="fad fa-chevron-right"></i>
        </span>
      </div>
      <div v-if="projet.groupe === $store.state.membre.groupeEnCours.idGroupe && parseInt(projet.statut) === 1" class="accordion-body">
        <div class="accordion-content">
          <ul>
            <li
              v-for="onglet in $store.state.listeOnglet"
              :key="onglet"
              class="fauxLien"
              @click="changerDroiteProjet(onglet.nom + '_' + projet.numProjet)"
              :id="onglet.nom + '_' + projet.numProjet">
              <div v-if="projet.onglets[onglet.id] && $store.state.membre.listeDroit[projet.numProjet][onglet.id] > 0">
                <span class="icon is-medium newPage" :namePage="onglet.nom">
                  <i :class="onglet.icon"></i>
                </span>
                <span class="newPage" :namePage="onglet.nom">{{ onglet.nom }}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </article>
  </section>
  <p v-if="$store.state.membre.listeProjet.length===0" class="has-text-centered">Aucun projet trouvé !</p>
</template>
<script>
import 'bulma-accordion/dist/css/bulma-accordion.min.css'
import Avatar from '@/components/Avatar.vue'

export default {
  name: 'BarreLaterale',
  components: {
    Avatar
  },
  emits: ['vers-app-projet'],
  props: ['taille'],
  data () {
    return {
      afficher: 0
    }
  },
  methods: {
    changerAffiche: function (comp) {
      if (this.afficher === comp) {
        this.afficher = 0
        this.$store.state.membre.listeProjet.filter(function (data) {
          if (data.numProjet === comp) {
            data.class = 'accordion'
          }
        })
      } else {
        if (this.afficher !== 0) {
          this.$store.state.membre.listeProjet.filter((data) => {
            if (data.numProjet === this.afficher) {
              data.class = 'accordion'
            }
          })
        }
        this.afficher = comp
        this.$store.state.membre.listeProjet.filter(function (data) {
          if (data.numProjet === comp) {
            data.class = 'accordion is-active'
          }
        })
      }
    },
    changerDroiteProjet: function (compo) {
      this.$emit('vers-app-projet', compo)
    }
  }
}

</script>
<style scoped>
/*Accordions général*/
.accordions {
  overflow: auto;
  overflow-x: hidden;
  margin-right: 0.3vw;
}

.accordions .accordion {
  background-color: transparent !important;
}

.accordions .accordion .accordion-header {
  background-color: transparent !important;
  color: black !important;
  font-weight: bold;
}

.accordions .accordion .accordion-header+.accordion-body {
  border: none !important;
}

.accordions .accordion .accordion-header+.accordion-body .accordion-content {
  padding-top: 0 !important;
}

.afficheListe {
  font-size: 1.5em;
}

.accordion.is-active .afficheListe {
  transform: rotate(90deg);
}

.accordion-content ul li {
  text-indent: 1vw;
  line-height: 2rem;
}

/*Sans Logo*/
.avatar {
  height: 1.6rem!important;
  font-size: 1rem!important;
  min-width: 1.6rem!important;
  width: 3rem;
}

/*Avec Logo*/

.goDroite.active {
  font-weight: bolder;
}
</style>
