<template>
  <section>
    <div class="boutonOptionDM">
      <Quadrillage @ligne="ligner()" @quadrillage="quadriller()"/>
      <div class="option">
        <span title="Général" @click="changerAffiche('GE')" id="GE" class="icon is-medium has-text-link fauxLien active">
          <i class="fad fa-chart-area"></i>
        </span>
      </div>
      <div class="option">
        <span title="Précise" @click="changerAffiche('PR')" id="PR" class="icon is-medium has-text-link fauxLien">
          <i class="fad fa-chart-network"></i>
        </span>
      </div>
    </div>
    <div class="boutonOptionGM" v-if="$store.getters.droitOnglet(projet, 3) === 2">
      <div class="option kitActivation">
        <span title="Gestion" class="icon is-medium has-text-primary fauxLien">
          <SwitchLive :html="['Kit désactivé', 'Kit activé']" :etat="projetInfo.activeKit" ref="switchInscription" switchId="kitActivation" @changerValeur="changeActivation"/>
        </span>
      </div>
      <div class="option">
        <span title="Gestion" @click="ouvrirGestion()" class="icon is-medium has-text-primary fauxLien">
          <i class="fad fa-cogs"></i>
        </span>
      </div>
    </div>
    <article>
      <h1 class="title has-text-centered">Statistiques</h1>
      <h6 class="subtitle has-text-centered" v-if="$store.getters.droitOnglet(projet, 3) === 2">Identifiant du kit : {{ projetInfo.numKit }}</h6>
    </article>
    <div v-if="affichageGestion.PR === 1">
      <GestionPrecise :projet="projetInfo" @fermer="cacherGestion('PR')"/>
    </div>
    <div v-if="affichageGestion.GE === 1">
      <GestionGeneral :projet="projetInfo" @fermer="cacherGestion('GE')"/>
    </div>
    <article class="columns is-multiline" v-show="affiche==='GE'">
      <div v-if="projetInfo.statistique_inscrit > 0 & affiche==='GE'" class="column unSeul is-12-tablet is-12-desktop">
        <div class="container">
          <div class="columns is-centered">
            <div class="column">
              <h1 class="subtitle"><b>Nombre d'inscrit</b></h1>
            </div>
            <div class="column" v-if="erreurConnexionApi === ''">
              <h1 class="subtitle nombre">
                <Compteur v-if="totalInsrit > -1" :end="totalInsrit" thousand=" "/>
              </h1>
            </div>
            <div class="column" v-if="erreurConnexionApi !== ''">
              <p class="has-text-danger clignetement"><i class="fad fa-exclamation-triangle"></i> Echec de connexion à l'API : {{ erreurConnexionApi }}</p>
            </div>
            <div class="column is-1">
              <span :class="cRefreshApi" @click="afficherTotalInscrit()">
                <i class="fad fa-sync"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="projetInfo.statistique_requetesite > 0" :class="unSeulGraph">
        <div class="container">
          <RequeteSite :projet="projet"/>
        </div>
      </div>
      <div v-if="projetInfo.statistique_connexion > 0" :class="unSeulGraph">
        <div class="container">
          <Connexion :projet="projet"/>
        </div>
      </div>
      <div v-if="projetInfo.statistique_inscription > 0" :class="unSeulGraph">
        <div class="container">
          <Inscription :projet="projet" @getTotal="afficherTotalInscrit"/>
        </div>
      </div>
      <div v-if="projetInfo.statistique_localisation > 0" :class="unSeulGraph">
        <div class="container">
          <Localisation :projet="projet"/>
        </div>
      </div>
    </article>
    <article class="columns is-multiline" v-show="affiche==='PR'">
      <div v-if="projetInfo.statistique_page > 0 && affiche==='PR'" class="column unSeul is-12-tablet is-12-desktop">
        <div class="container">
          <div class="columns is-centered is-vcentered">
            <div class="column is-6">
              <h1 class="subtitle"><b>Temps sur site (minutes)</b></h1>
            </div>
            <div class="column">
              <h1 class="subtitle nombre">
                Min <Compteur v-if="tempsSiteMin > -1" :end="tempsSiteMin" thousand=" "/>
              </h1>
            </div>
            <div class="column">
              <h1 class="subtitle nombre">
                Moy <Compteur v-if="tempsSiteMoy > -1" :end="tempsSiteMoy" thousand=" "/>
              </h1>
            </div>
            <div class="column">
              <h1 class="subtitle nombre">
                Max <Compteur v-if="tempsSiteMax > -1" :end="tempsSiteMax" thousand=" "/>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="projetInfo.statistique_page > 0" :class="unSeulGraph">
        <div class="container">
          <Pages :projet="projet" @getTempsSite="afficherTempsTotal"/>
        </div>
      </div>
      <Bouton v-if="projetInfo.statistique_bouton > 0" :projet="projet" :c="unSeulGraph"/>
    </article>
  </section>
</template>

<script>
import RequeteSite from '@/components/PartieDroite/Statistiques/RequeteSite.vue'
import Connexion from '@/components/PartieDroite/Statistiques/Connexion.vue'
import Inscription from '@/components/PartieDroite/Statistiques/Inscription.vue'
import Localisation from '@/components/PartieDroite/Statistiques/Localisation.vue'
import Pages from '@/components/PartieDroite/Statistiques/Pages.vue'
import Bouton from '@/components/PartieDroite/Statistiques/Bouton.vue'
import GestionGeneral from '@/components/PartieDroite/Statistiques/GestionGeneral.vue'
import GestionPrecise from '@/components/PartieDroite/Statistiques/GestionPrecise.vue'
import Quadrillage from '@/components/Options/Quadrillage.vue'
import SwitchLive from '@/components/Formulaire/SwitchLive.vue'

import Compteur from '@/components/Options/Compteur.vue'
import axios from 'axios'

export default {
  name: 'Statistiques',
  props: ['projet'],
  components: {
    Quadrillage,
    RequeteSite,
    Connexion,
    Inscription,
    Localisation,
    Pages,
    Bouton,
    GestionGeneral,
    GestionPrecise,
    Compteur,
    SwitchLive
  },
  data () {
    return {
      // Base
      affiche: 'GE',
      unSeulGraph: 'column unSeul is-12-tablet is-6-desktop',
      projetInfo: {},
      cRefreshApi: 'icon is-small fauxLien',
      // Données
      totalInsrit: -1,
      erreurConnexionApi: '',
      tempsSiteMin: -1,
      tempsSiteMoy: -1,
      tempsSiteMax: -1,
      // Gestion
      affichageGestion: {
        GE: 0,
        PR: 0
      }
    }
  },
  created: function () {
    this.projetInfo = this.$store.getters.getProjetInfo(this.projet)
  },
  methods: {
    // Base des statistiques
    changerAffiche: function (comp) {
      document.getElementById(this.affiche).classList.remove('active')
      this.affiche = comp
      document.getElementById(comp).classList.add('active')
    },
    ligner: function () {
      this.unSeulGraph = 'column unSeul is-12-tablet is-12-desktop'
    },
    quadriller: function () {
      this.unSeulGraph = 'column unSeul is-12-tablet is-6-desktop'
    },
    afficherTotalInscrit: function (total = 0) {
      if (this.projetInfo.statistique_inscrit === 1) {
        this.totalInsrit = total
      } else if (this.projetInfo.statistique_inscrit === 2) {
        this.cRefreshApi = 'icon is-small fauxLien loadingIcon'
        this.totalInsrit = -1
        axios.get(this.projetInfo.statistique_inscritApi).then((reponse) => {
          this.erreurConnexionApi = ''
          this.totalInsrit = parseInt(reponse.data.nbrInscrit)
        }).catch((error) => {
          this.erreurConnexionApi = error.message
        }).then(() => {
          setTimeout(() => {
            this.cRefreshApi = 'icon is-small fauxLien'
          }, 1000)
        })
      }
    },
    afficherTempsTotal: function (tempsSite) {
      this.tempsSiteMin = tempsSite.min
      this.tempsSiteMoy = tempsSite.moy
      this.tempsSiteMax = tempsSite.max
    },
    // Gestion des statistiques
    ouvrirGestion: function () {
      this.affichageGestion[this.affiche] = 1
    },
    cacherGestion: function (type) {
      this.affichageGestion[type] = 0
    },
    changeActivation: function (valeur) {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Statistiques/KitActivation.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet, {
        activation: valeur.valeur
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.projetInfo.activeKit = valeur.valeur
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Un problème est survenu', type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    }
  }
}
</script>

<style scoped>
@media (prefers-color-scheme: light) {
  .unSeul>div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .nombre div {
    color:#47479F!important;
  }
}

@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .nombre div {
    color:#01BBC8!important;
  }
}

.unSeul > .container {
  border-radius: 6px;
  position: relative;
  padding: 1rem 1rem;
  height: 100%;
}
.nombre div {
  font-weight: bold;
}
.option.kitActivation > span {
  width: auto!important;
}
.clignetement {
  animation: clignote 2s linear infinite;
}
@keyframes clignote {
  50% {
    opacity: 0;
  }
}
.loadingIcon {
  animation:  rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
