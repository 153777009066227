<template>
  <div v-if="$store.state.loader" class="chargementGoDroite">
  </div>
</template>
<script>

export default {
  name: 'Loader'
}
</script>

<style>
.chargementGoDroite {
  position: absolute;
  right: 1vh;
  bottom: 1vh;
  background-image: url('/images/site/loader.gif');
  height: 8vh;
  width: 4vw;
  z-index: 99;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
