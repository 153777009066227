<template>
  <div>
    <apexchart
        width="100%"
        height="400px"
        type="donut"
        :options="chartOptions"
        :series="series"
      />
    <div v-show="chargementDonnees" class="columns is-centered" style="position: absolute;width: 100%;top: 50%;background-image: url('/images/site/loader.gif');background-repeat: no-repeat;background-position: center;background-size: contain;height: 4rem;">
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'

export default {
  name: 'RequeteSite',
  props: ['projet'],
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      requeteSiteDonnees: {},
      chargementDonnees: true,
      chartOptions: {
        chart: {
          id: 'Localisation',
          toolbar: {
            show: true
          },
          dropShadow: {
            enabled: true,
            color: '#111',
            top: -1,
            left: 3,
            blur: 3,
            opacity: 0.2
          }
        },
        tooltip: {
          enabled: true
        },
        labels: [],
        title: {
          text: 'Localisation',
          style: {
            color: '#ffffff'
          }
        },
        legend: {
          labels: {
            useSeriesColors: true
          }
        }
      },
      series: []
    }
  },
  created: function () {
    axios.get(this.$store.getters.url + 'back/Statistiques/ChargerLocalisation.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.requeteSiteDonnees = reponse.data.requeteSiteDonnees
        const serie = []
        const labels = []
        for (var i = 0; i < this.requeteSiteDonnees.length; i++) {
          labels.push(this.requeteSiteDonnees[i][0])
          serie.push(this.requeteSiteDonnees[i][1])
        }
        this.chartOptions = {
          ...this.chartOptions,
          labels: labels
        }
        this.series = serie
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de lecture sur cette onglets.', type: 'attention' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
      this.suite()
      this.chargementDonnees = false
    })
  },
  methods: {
    suite: function () {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        this.changerTheme(event.matches)
      })
      this.changerTheme(window.matchMedia('(prefers-color-scheme: dark)').matches)
    },
    changerTheme: function (e) {
      if (e) {
        // Theme = dark
        this.chartOptions = {
          ...this.chartOptions,
          title: {
            text: 'Localisation',
            style: {
              color: '#ffffff'
            }
          }
        }
      } else {
        // Theme = light
        this.chartOptions = {
          ...this.chartOptions,
          title: {
            text: 'Localisation',
            style: {
              color: '#4a4a4a'
            }
          }
        }
      }
    }
  }
}
</script>

<style type="text/css" scoped>
@media (prefers-color-scheme: dark) {
  .apexcharts-menu > div {
    color: black!important;
  }
  .button[selected='true'] {
    background-color: #0174CF!important;
    color: white!important;
    border: none!important;
  }
}
@media (prefers-color-scheme: light) {
  .button[selected='true'] {
    background-color: #47479F!important;
    color: white!important;
    border: none!important;
  }
}
</style>
