import { createStore } from 'vuex'

export default createStore({
  state: {
    // url: 'http://109.24.187.82:80/',
    url: '',
    chargerOk: false,
    membre: {
      numMembre: 0,
      groupe: [],
      groupeEnCours: 0,
      adresseMail: '',
      avatar: null,
      nom: '',
      prenom: '',
      dateInscription: '',
      dateDiffMdp: 0,
      createur: 0,
      biographie: '',
      public: 0,
      competences: [],
      listeProjet: [],
      listeDroit: []
    },
    listeStatut: [
      { id: 1, Nom: 'Administrateur' },
      { id: 2, Nom: 'Membre' },
      { id: 3, Nom: 'Collaborateur' },
      { id: 4, Nom: 'Contact' }
    ],
    listeOnglet: [
      { id: 1, nom: 'Administration', icon: 'fad fa-tools' },
      { id: 2, nom: 'Logiciels', icon: 'fad fa-code-branch' },
      { id: 3, nom: 'Statistiques', icon: 'fad fa-chart-pie' },
      { id: 4, nom: 'Newsletters', icon: 'fad fa-envelope' },
      { id: 5, nom: 'Feedback', icon: 'fad fa-question' },
      { id: 6, nom: 'Maintenance & MàJ', icon: 'fad fa-wrench' }
    ],
    regex: {
      email: /^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\.[a-z]{2,4}$/,
      lien: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/
    },
    Notif: { affiche: false, texte: '', couleur: 'notification is-light formOk columns ', icon: '', fondProgress: 'progressDiv ' },
    couleurListe: { ok: 'is-success', erreur: 'is-danger', info: 'is-info', attention: 'is-warning' },
    fondProgress: { ok: 'has-background-success', erreur: 'has-background-danger', info: 'has-background-info', attention: 'has-background-warning' },
    iconListe: { ok: 'fa-check', erreur: 'fa-times', info: 'fa-info', attention: 'fa-exclamation' },
    loader: false
  },
  getters: {
    url: function (state) {
      return state.url
    },
    numMembre: function (state) {
      return state.membre.numMembre
    },
    infoMembre: (state) => (quoi) => {
      return state.membre[quoi]
    },
    isCreateur: function (state) {
      return state.membre.createur === 1
    },
    getStatut: (state) => (idStatut = 0) => {
      if (idStatut === 0) {
        idStatut = state.membre.groupeEnCours.statutMembre
      }
      for (var i = 0; i < state.listeStatut.length; i++) {
        if (state.listeStatut[i].id === parseInt(idStatut)) {
          return state.listeStatut[i].Nom
        }
      }
    },
    lienGroupe: function (state) {
      return state.membre.groupeEnCours.lienGroupe
    },
    statutGroupe: function (state) {
      return state.membre.groupeEnCours.ouvert
    },
    droitOnglet: (state) => (projet, onglet) => {
      return state.membre.listeDroit[projet][onglet]
    },
    getProjetInfo: (state) => (numProjet) => {
      for (var i = 0; i < state.membre.listeProjet.length; i++) {
        if (state.membre.listeProjet[i].numProjet === numProjet) {
          return state.membre.listeProjet[i]
        }
      }
    }
  },
  mutations: {
    deconnexion: function (state) {
      // Suppression des informations du membre
      state.membre.numMembre = 0
      state.membre.groupe = []
      state.membre.groupeEnCours = 0
      state.membre.adresseMail = ''
      state.membre.avatar = 'images/avatar/user.svg'
      state.membre.nom = ''
      state.membre.prenom = ''
      state.membre.dateInscription = ''
      state.membre.dateDiffMdp = 0
      state.membre.createur = 0
      state.membre.biographie = ''
      state.membre.public = 0
      state.membre.competences.length = 0
      // Suppression de la liste des projets dont le membre fait partie tout groupe confondus
      state.membre.listeProjet = []
      // Suppression de la liste des droits aux projets
      state.membre.listeDroit = []
      // Vider le cache
      window.localStorage.clear()
      // Message
      this.commit('afficherNotification', { affiche: true, texte: 'Vous êtes déconnecté !', type: 'ok' })
    },
    connexion: function (state, donnees) {
      // Enregistrement des informations du membre
      state.membre.numMembre = parseInt(donnees.informations.numMembre)
      state.membre.groupe = donnees.informations.groupes
      state.membre.groupeEnCours = donnees.informations.groupes[0]
      state.membre.adresseMail = donnees.informations.adresseMail
      state.membre.avatar = donnees.informations.avatar !== null ? 'images/avatar/' + donnees.informations.avatar + '.png' : null
      state.membre.nom = donnees.informations.nom.toUpperCase()
      state.membre.prenom = donnees.informations.prenom
      state.membre.dateInscription = donnees.informations.dateInscription
      state.membre.dateDiffMdp = parseInt(donnees.informations.dateDiffMdp)
      state.membre.createur = parseInt(donnees.informations.createur)
      state.membre.biographie = donnees.informations.biographie
      state.membre.public = parseInt(donnees.informations.public)
      state.membre.competences = donnees.informations.competences
      // Enregistrement de la liste des projets dont le membre fait partie tout groupe confondus
      state.membre.listeProjet = donnees.projets
      // Enregistrement de la liste des droits aux projets
      state.membre.listeDroit = donnees.droits
      // Mise en cache de l'identifiant
      window.localStorage.setItem('n', donnees.informations.n)
    },
    modifierProfil: function (state, donnees) {
      state.membre.competences = donnees.data.competences
      state.membre.typeCompte = donnees.typeCompte
      // if (donnees.avatar !== '') {
      //   state.membre.avatar = donnees.data.avatar !== null ? 'images/avatar/' + donnees.data.avatar + '.png' + '?' + Math.random() : null
      // }
      if (donnees.mdp !== '') {
        state.membre.dateDiffMdp = 0
      }
    },
    changerStatutGroupeEnCours: function (state) {
      state.membre.groupeEnCours.ouvert = parseInt(state.membre.groupeEnCours.ouvert) === 1 ? 0 : 1
    },
    supprimerGroupeEnCours: function (state) {
      const idGroupe = state.membre.groupeEnCours.id
      for (var i = 0; i < state.membre.groupe.length; i++) {
        if (state.membre.groupe[i].id === idGroupe) {
          state.membre.groupe.splice(i, 1)
        }
      }
      state.membre.groupeEnCours = state.membre.groupe[0]
    },
    afficherNotification: function (state, donnees) {
      state.Notif.affiche = donnees.affiche
      state.Notif.texte = donnees.texte
      state.Notif.couleur = 'notification is-light formOk columns ' + state.couleurListe[donnees.type]
      state.Notif.fondProgress = 'progressDiv ' + state.fondProgress[donnees.type]
      state.Notif.icon = '<i class="fad fa-2x ' + state.iconListe[donnees.type] + '"></i>'
    },
    cacherNotification: function (state) {
      state.Notif.affiche = false
      setTimeout(() => {
        state.Notif.texte = ''
      }, 1000)
    },
    chargement: function (state) {
      state.loader = true
    },
    finChargement: function (state, temps = 0) {
      setTimeout(function () {
        state.loader = false
      }, temps)
    }
  },
  actions: {
  },
  modules: {
  }
})
