<template>
  <Rechercher @recherche="rechercher" @annulerRecherche="annulerRecherche" :filtres="filtres"/>
  <h1 v-if="liste.length === 0" class="has-text-centered title">Aucun résultat !</h1>
  <article v-else class="columns is-multiline">
    <div
      v-for="membre in liste"
      :key="membre"
      v-show="membre.afficher"
      @click="voirProfil(membre)"
      class="column unSeul is-12-tablet is-4-desktop">
      <div class="columns is-vcentered">
        <div class="column">
          <p>{{ membre.prenom }} {{ membre.nom.toUpperCase() }}</p>
        </div>

        <div class="column" style="height: 7rem;">
          <div class="tags">
            <span
            v-for="tag in membre.competences.slice(0,3)"
            :key="tag"
            class="tag is-dark is-normal"
            > {{ tag }}</span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios'
import Rechercher from '@/components/Options/Rechercher.vue'
export default {
  name: 'AnnuairePublic',
  components: {
    Rechercher
  },
  emits: ['afficher-profil'],
  data () {
    return {
      liste: [],
      filtres: ['Nom', 'Prénom', 'Compétences']
    }
  },
  created: function () {
    this.$store.commit('chargement')
    axios.post(this.$store.getters.url + 'back/Annuaire/ChargerPublic.php?i=' + this.$store.getters.numMembre, {
      groupe: this.$store.getters.infoMembre('groupe')
    }).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.liste = reponse.data.donnees
        this.trier('nom')
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  methods: {
    trier: function (quoi) {
      this.liste.sort(function (a, b) {
        return a[quoi].toLowerCase() < b[quoi].toLowerCase() ? -1 : a[quoi].toLowerCase() > b[quoi].toLowerCase() ? 1 : 0
      })
    },
    voirProfil: function (membre) {
      this.$emit('afficher-profil', membre)
    },
    rechercher: function (e) {
      for (var i = 0; i < this.liste.length; i++) {
        let mot = ''
        let competences = []
        if (e.filtre === 'competences') {
          competences = this.liste[i][e.filtre]
          let estPresent = false
          for (var j = 0; j < competences.length; j++) {
            mot = competences[j].toLowerCase()
            if (mot.indexOf(e.valeur.toLowerCase()) > -1) {
              estPresent = true
            }
          }
          if (estPresent) {
            this.liste[i].afficher = true
          } else {
            this.liste[i].afficher = false
          }
        } else {
          mot = this.liste[i][e.filtre].toLowerCase()
          if (mot.indexOf(e.valeur.toLowerCase()) > -1) {
            this.liste[i].afficher = true
          } else {
            this.liste[i].afficher = false
          }
        }
      }
    },
    annulerRecherche: function () {
      for (var i = 0; i < this.liste.length; i++) {
        this.liste[i].afficher = true
      }
    },
    ajouterAnnuaire: function (numMembre) {
      for (var i = 0; i < this.liste.length; i++) {
        if (this.liste[i].numero_membre === numMembre) {
          this.liste[i].annuaire = true
          break
        }
      }
    },
    supprimerAnnuaire: function (numMembre) {
      for (var i = 0; i < this.liste.length; i++) {
        if (this.liste[i].numero_membre === numMembre) {
          this.liste[i].annuaire = false
          break
        }
      }
    }
  }
}
</script>

<style scoped>
.boutonListe {
  text-align: center;
}
.unSeul > div {
  padding: 0.3rem!important;
  cursor: pointer;
}
@media (prefers-color-scheme: light) {
  .unSeul > div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
.unSeul > div {
  height: 100%;
  border-radius: 6px;
}
.tags {
  margin-left: 0.5rem;
  display: grid!important;
}
.tag {
  margin-right: 0!important;
}
</style>
