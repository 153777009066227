<template>
  <div class="columns is-centered cadre">
      <div class="column is-two-fifths-widescreen is-three-fifths-tablet zone card">
        <FormulaireConnexion
        @changerEtape='changerEtape'
        v-if='affichage=="connexion"'/>
        <FormulaireMDPoublie
        @changerEtape='changerEtape'
        v-if='affichage=="mdpoublie"'/>
    </div>
  </div>
</template>

<script>
import FormulaireConnexion from '@/components/Connexion/FormulaireConnexion.vue'
import FormulaireMDPoublie from '@/components/Connexion/FormulaireMDPoublie.vue'
export default {
  name: 'Connexion',
  components: {
    FormulaireConnexion,
    FormulaireMDPoublie
  },
  data () {
    return {
      affichage: 'connexion'
    }
  },
  methods: {
    changerEtape: function (etape) {
      this.affichage = etape
    }
  }
}
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
  .codeEntrer {
    border-bottom-color: white;
  }
  .control.has-icons-left .icon > svg > path {
    color: #363636!important;
  }
}
@media (prefers-color-scheme: light) {
  .codeEntrer {
    border-bottom-color: black;
  }
}
.cadre {
  height: 100%;
}
.zone {
  height: auto;
  margin: auto auto;
  border-radius: 10px;
  padding: 2% 1% 2% 1%;
}

.messageIcon {
  vertical-align: middle;
  margin-right: 5%;
}
</style>
