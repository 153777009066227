<template>
  <div class="option">
    <span
      v-if="etat === 1"
      @click="cacher"
      class="icon is-medium has-text-primary fauxLien"
      title="Cacher">
      <i class="fad fa-eye"></i>
    </span>
    <span
      v-else
      @click="afficher"
      class="icon is-medium has-text-primary fauxLien"
      title="Afficher">
      <i class="fad fa-eye-slash"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Cacher',
  emits: ['cacher', 'afficher'],
  data () {
    return {
      etat: 1
    }
  },
  methods: {
    cacher: function () {
      this.$emit('cacher')
      this.etat = 0
    },
    afficher: function () {
      this.$emit('afficher')
      this.etat = 1
    }
  }
}
</script>
