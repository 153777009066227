<template>
  <div class="modal is-active">
    <div class="modal-background" @click="fermerModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered"><b>Gestion des statistiques de pages</b></p>
      </header>
      <section class="modal-card-body">
        <p class="has-text-centered">Toutes les données recueillies sont anonymes. Certaines statistiques ont besoin de manipulation de code, veuillez lire la documentation technique.</p>
        <br>
        <div class="columns is-centered">
          <div class="column is-9">
            <p><b>Récupérer le temps d'affiche des pages</b></p>
          </div>
          <div class="column has-text-right">
             <SwitchLive :html="['', '']" :etat="page" ref="switchPage" switchId="switchPage" @changerValeur="getValeurSwitch"/>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-9">
            <p><b>Récupérer le nombre de clic sur les boutons</b></p>
          </div>
          <div class="column has-text-right">
             <SwitchLive :html="['', '']" :etat="bouton" ref="switchBouton" switchId="switchBouton" @changerValeur="getValeurSwitch"/>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <span class="card-footer-item fauxLien">
          <span class="icon has-text-danger is-small" @click="annuler()" title="Annuler">
            <i class="fad fa-ban fa-2x"></i>
          </span>
        </span>
        <span class="card-footer-item fauxLien">
          <span class="icon has-text-primary is-small" @click="valider()" title="Valider">
            <i class="fad fa-check fa-2x"></i>
          </span>
        </span>
      </footer>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="fermerModal"></button>
  </div>
</template>

<script>
import SwitchLive from '@/components/Formulaire/SwitchLive.vue'
import axios from 'axios'

export default {
  name: 'GestionPrecise',
  props: ['projet'],
  emits: ['fermer'],
  components: {
    SwitchLive
  },
  data () {
    return {
      projetInfo: this.projet,
      page: 0,
      bouton: 0
    }
  },
  created: function () {
    this.page = this.projetInfo.statistique_page
    this.bouton = this.projetInfo.statistique_bouton
  },
  methods: {
    fermerModal: function () {
      this.$emit('fermer')
    },
    getValeurSwitch: function (item) {
      this[item.ref.replace('switch', '').toLowerCase()] = parseInt(item.valeur)
    },
    annuler: function () {
      this.page = this.projetInfo.statistique_page
      this.bouton = this.projetInfo.statistique_bouton
    },
    valider: function () {
      this.$store.commit('chargement')
      const donnees = {
        page: this.page,
        bouton: this.bouton
      }
      axios.post(this.$store.getters.url + 'back/Statistiques/ModifierPrecise.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet.numProjet, {
        donnees: donnees
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          // Changement des valeurs en front
          this.projetInfo.statistique_page = this.page
          this.projetInfo.statistique_bouton = this.bouton
          // Notification
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Mise à jour du kit avec succès.', type: 'ok' })
          this.fermerModal()
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Un problème est survenu', type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    }
  }
}
</script>

<style scoped>
.modal-background {
  cursor: pointer;
}
@media (prefers-color-scheme: light) {
  .modal-card-body {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .modal-card-body {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .modal-card-head, .modal-card-foot {
    background-color: hsl(0, 0%, 16%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
</style>
