<template>
  <div class="boutonOptionDM">
    <Trier @trier="changeTrie"/>
  </div>
  <div v-if="$store.getters.isCreateur" class="boutonOptionGM">
    <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouter"/>
    <Supprimer @supprimer="supprimer" @annulerSuppression="annulerSuppression" @validerSuppression="validerSuppression" ref="supprimer"/>
  </div>
  <article>
    <!-- Nouveau -->
    <div v-if="ajouterForm" class="tile is-ancestor" style="margin-bottom: 3vh;">
      <div class="tile is-12 is-vertical is-parent">
        <div class='une tile is-child box nouveau'>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Version</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input type='text' class='input' placeholder="Version" v-model="nouveauVersion">
                  <span class="icon is-left">
                    <i class="fad fa-hashtag"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Date</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control has-icons-left">
                  <input type='date' class='input' name='date' id="dateInput" placeholder="Date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="nouveauDate">
                  <span class="icon is-left">
                    <i class="fad fa-calendar-day"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="field is-horizontal">
            <div class="field-label is-normal">
              <label class="label">Description</label>
            </div>
            <div class="field-body">
              <div class="field">
                <div class="control">
                  <textarea class='textarea' placeholder="Description de la mise à jour" v-model="nouveauDesc"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Liste -->
    <div class="tile tile is-ancestor">
      <div class="tile is-12 is-vertical is-parent">
        <div
          v-for="maj in majs"
          :key="maj"
          :aSupprimer="maj.aSupprimer"
          :aSelectionner="maj.aSelectionner"
          @click="aSelectionner(maj.id)"
          class='une tile is-child box'>
          <div v-if="$store.getters.isCreateur" class="boutonOptionGM">
            <Modifier tooltipSens="top" @modifier="modifier(maj.id)" @annulerModification="annulerModification" @validerModification="validerModification(maj.id)" :ref="maj.id" />
          </div>
          <div class="columns">
            <div class="column is-6">
              <h3 v-if="!maj.aModifier" class="subtitle"><b>Version {{ maj.version }}</b></h3>
              <div v-else class="field">
                <div class="control has-icons-left">
                  <input type='text' class='input' placeholder="Version" v-model="maj.version">
                  <span class="icon is-left">
                    <i class="fad fa-hashtag"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-4">
              <h3 v-if="!maj.aModifier" class="subtitle"><b>Date de sortie: {{ maj.dateHtml }}</b></h3>
              <div v-else class="field">
                <div class="control has-icons-left">
                  <input type='date' class='input' name='date' id="dateInput" placeholder="Date" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}" v-model="maj.dateInput">
                  <span class="icon is-left">
                    <i class="fad fa-calendar-day"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="!maj.aModifier" data="texte" v-html="maj.texte"></div>
            <div v-else class="field">
              <div class="control">
                <div class='textarea' @focusout="updateText($event, maj.id)" contenteditable="true" placeholder="Description de la mise à jour" v-html="maj.texte"></div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios'
import Ajouter from '@/components/Options/Ajouter.vue'
import Supprimer from '@/components/Options/Supprimer.vue'
import Trier from '@/components/Options/Trier.vue'
import Modifier from '@/components/Options/Modifier.vue'
export default {
  name: 'Maj',
  components: {
    Ajouter,
    Supprimer,
    Trier,
    Modifier
  },
  data () {
    return {
      majs: [],
      sens: 'DESC',
      sensListe: { ASC: 'DESC', DESC: 'ASC' },
      ajouterForm: false,
      nouveauVersion: '',
      nouveauDate: 0,
      nouveauDesc: ''
    }
  },
  created () {
    this.chargerDonnees()
  },
  methods: {
    chargerDonnees: function () {
      this.$store.commit('chargement')
      axios.get(this.$store.getters.url + 'back/Maj/Charger.php?i=' + this.$store.getters.numMembre).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.majs = reponse.data.donnees
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    changeTrie: function () {
      this.sens = this.sensListe[this.sens]
      this.trier()
    },
    trier: function () {
      this.majs.sort(function (a, b) {
        return a.date - b.date
      })
      if (this.sens === 'DESC') {
        this.majs.reverse()
      }
    },
    verifForm: function (version, date, desc) {
      let i = 0
      let message = ''
      if (version === '') {
        i++
        message += 'Aucune description.'
      }
      if (typeof parseInt(date) !== 'number') {
        i++
        message += 'La date n\'est pas un nombre.<br>'
      }
      if (desc === '') {
        i++
        message += 'Aucune description.'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouveauDate = 0
      this.nouveauDesc = ''
    },
    validerAjout: function () {
      if (this.verifForm(this.nouveauDate, this.nouveauDesc)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Maj/Ajouter.php?i=' + this.$store.getters.numMembre, {
          version: this.nouveauVersion,
          date: this.nouveauDate,
          desc: this.nouveauDesc
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.majs.push({
              id: reponse.data.id,
              version: this.nouveauVersion,
              date: reponse.data.date,
              dateHtml: reponse.data.dateHtml,
              dateInput: reponse.data.dateInput,
              texte: this.nouveauDesc,
              aSupprimer: false,
              aSelectionner: false,
              aModifier: false
            })
            this.trier()
            this.$refs.ajouter.validerOk()
            this.ajouterForm = false
            this.nouveauVersion = ''
            this.nouveauDate = 0
            this.nouveauDesc = ''
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Version ajoutée avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    supprimer: function () {
      for (var i = 0; i < this.majs.length; i++) {
        this.majs[i].aSupprimer = true
      }
    },
    annulerSuppression: function () {
      for (var i = 0; i < this.majs.length; i++) {
        this.majs[i].aSupprimer = false
        this.majs[i].aSelectionner = false
      }
    },
    aSelectionner: function (item) {
      for (var i = 0; i < this.majs.length; i++) {
        if (this.majs[i].id === item) {
          if (this.majs[i].aSelectionner) {
            this.majs[i].aSelectionner = false
          } else {
            this.majs[i].aSelectionner = true
          }
          break
        }
      }
    },
    validerSuppression: function () {
      const liste = []
      for (var i = 0; i < this.majs.length; i++) {
        if (this.majs[i].aSelectionner) {
          liste.push(this.majs[i].id)
        }
      }
      if (liste.length > 0) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Maj/Supprimer.php?i=' + this.$store.getters.numMembre, {
          liste: liste
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            const nonSupprimer = []
            for (var i = 0; i < this.majs.length; i++) {
              if (liste.indexOf(this.majs[i].id) === -1) {
                nonSupprimer.push(this.majs[i])
              }
            }
            this.majs = nonSupprimer
            this.trier()
            this.$refs.supprimer.validerOk()
            this.annulerSuppression()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Version(s) supprimée(s) avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien a supprimer !', type: 'info' })
      }
    },
    modifier: function (item) {
      for (var i = 0; i < this.majs.length; i++) {
        if (this.majs[i].id === item) {
          this.majs[i].aModifier = true
          this.$refs[this.majs[i].id].valeur({
            id: this.majs[i].id,
            version: this.majs[i].version,
            date: this.majs[i].date,
            dateHtml: this.majs[i].dateHtml,
            dateInput: this.majs[i].dateInput,
            texte: this.majs[i].texte
          })
          break
        }
      }
    },
    annulerModification: function (item) {
      for (var i = 0; i < this.majs.length; i++) {
        if (this.majs[i].id === item.id) {
          this.majs[i].aModifier = false
          this.majs[i].version = item.version
          this.majs[i].date = item.date
          this.majs[i].dateHtml = item.dateHtml
          this.majs[i].dateInput = item.dateInput
          this.majs[i].texte = item.texte
          break
        }
      }
    },
    updateText: function (event, item) {
      for (var i = 0; i < this.majs.length; i++) {
        if (this.majs[i].id === item) {
          this.majs[i].texte = event.target.innerHTML
          break
        }
      }
    },
    validerModification: function (item) {
      let version = ''
      let date = 0
      let texte = ''
      for (var i = 0; i < this.majs.length; i++) {
        if (this.majs[i].id === item) {
          version = this.majs[i].version
          date = this.majs[i].dateInput
          texte = this.majs[i].texte
          break
        }
      }
      if (this.verifForm(date, texte)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Maj/Modifier.php?i=' + this.$store.getters.numMembre, {
          id: item,
          version: version,
          date: date,
          desc: texte
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            for (var i = 0; i < this.majs.length; i++) {
              if (this.majs[i].id === item) {
                this.majs[i].date = reponse.data.date
                this.majs[i].dateHtml = reponse.data.dateHtml
                this.majs[i].aModifier = false
                break
              }
            }
            this.trier()
            this.$refs[item].validerOk()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Verison modifiée avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    }
  }
}
</script>

<style>
.box {
  position: relative;
}
.box[aSupprimer=true] {
  cursor: pointer;
}
.box[aSupprimer=true][aSelectionner=true] {
  border: 3px dashed #f14668;
}
</style>
