<template>
  <div>
    <apexchart
        width="100%"
        height="400px"
        type="area"
        :options="chartOptions"
        :series="series"
      />
    <div v-show="afficheRien" class="columns is-centered" style="position: absolute;width: 100%;top: 50%;">
      <h6 class="subtitle has-text-info" style="font-weight: bold;">Aucune donnée</h6>
    </div>
    <div v-show="chargementDonnees" class="columns is-centered" style="position: absolute;width: 100%;top: 50%;background-image: url('/images/site/loader.gif');background-repeat: no-repeat;background-position: center;background-size: contain;height: 4rem;">
    </div>
  </div>
  <div class="columns is-centered">
    <div class="column is-5">
      <div class="buttons has-addons">
        <button class="button is-small is-active" :selected="trie==='annee'" @click="changeAxeX('annee')">Année</button>
        <button class="button is-small is-active" :selected="trie==='mois'" @click="changeAxeX('mois')">Mois</button>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <div class="control">
          <div class="select is-small is-fullwidth">
            <select @change="changementMois()" v-model="mois">
              <option v-for="m in listeMois" :key="m" :value="listeMois.indexOf(m)"> {{ m }} </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div class="field">
        <div class="control">
          <div class="select is-small is-fullwidth">
            <select @change="changementAnnee()" v-model="annee">
              <option v-for="a in listeAnnees" :key="a" :value="a"> {{ a }} </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'

export default {
  name: 'RequeteSite',
  props: ['projet'],
  emits: ['getTotal'],
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      requeteSiteDonnees: {},
      listeMois: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
      listeAnnees: [],
      trie: 'annee',
      annee: 0,
      mois: 0,
      afficheRien: false,
      chargementDonnees: true,
      chartOptions: {
        chart: {
          id: 'BoutonInscription',
          toolbar: {
            show: true
          },
          events: {
            updated: () => {
              this.chargementDonnees = false
            }
          }
        },
        tooltip: {
          enabled: false
        },
        xaxis: {
          categories: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
          labels: {
            style: {
              colors: '#47479F'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: '#47479F'
            }
          }
        },
        colors: ['#47479F'],
        title: {
          text: 'Nombre d\'inscription',
          style: {
            color: '#ffffff'
          }
        }
      },
      series: [{
        data: []
      }]
    }
  },
  created: function () {
    axios.get(this.$store.getters.url + 'back/Statistiques/ChargerInscription.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.requeteSiteDonnees = this.ranger(reponse.data.requeteSiteDonnees)
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de lecture sur cette onglets.', type: 'attention' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
      this.suite()
    })
  },
  methods: {
    suite: function () {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        this.changerTheme(event.matches)
      })
      this.changerTheme(window.matchMedia('(prefers-color-scheme: dark)').matches)
      // Trouver l'année et le mois du jour
      const date = new Date()
      this.annee = date.getFullYear()
      this.mois = date.getMonth()
      // Mettre les premieres valeurs
      this.serieParAnnee(this.annee)
    },
    ranger: function (donnees) {
      const retour = {}
      let annee = 0
      let mois = 0
      let jour = 0
      let total = 0
      for (var i = 0; i < donnees.length; i++) {
        if (parseInt(donnees[i][0].split('/')[2]) !== annee) {
          annee = parseInt(donnees[i][0].split('/')[2])
          this.listeAnnees.push(annee)
          retour[annee] = {}
        }
        if (parseInt(donnees[i][0].split('/')[1]) !== mois) {
          mois = parseInt(donnees[i][0].split('/')[1])
          retour[annee][mois] = {}
        }
        if (parseInt(donnees[i][0].split('/')[0]) !== jour) {
          jour = parseInt(donnees[i][0].split('/')[0])
          retour[annee][mois][jour] = donnees[i][1]
          total += donnees[i][1]
        }
      }
      this.$emit('getTotal', total)
      return retour
    },
    changerTheme: function (e) {
      const cat = []
      if (e) {
        // Theme = dark
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: cat,
            labels: {
              style: {
                colors: '#01BBC8'
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#01BBC8'
              }
            }
          },
          colors: ['#01BBC8'],
          title: {
            text: 'Nombre d\'inscription',
            style: {
              color: '#ffffff'
            }
          }
        }
      } else {
        // Theme = light
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: cat,
            labels: {
              style: {
                colors: '#47479F'
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#47479F'
              }
            }
          },
          colors: ['#47479F'],
          title: {
            text: 'Nombre d\'inscription',
            style: {
              color: '#4a4a4a'
            }
          }
        }
      }
      if (this.trie === 'annee') {
        this.serieParAnnee(this.annee)
      } else {
        this.serieParMois(this.mois)
      }
    },
    changeAxeX: function (trie) {
      this.trie = trie
      if (trie === 'annee') {
        this.serieParAnnee(this.annee)
      } else {
        this.serieParMois(this.mois)
      }
    },
    calculerNbrJour: function () {
      return new Date(this.annee, this.mois + 1, -1).getDate() + 1
    },
    serieParAnnee: function (annee) {
      const liste = this.requeteSiteDonnees[annee]
      const serie = []
      const categories = []
      const couleur = this.chartOptions.colors[0]
      for (const mois in liste) {
        serie.push(this.additionner(liste[mois]))
        categories.push(this.listeMois[mois - 1])
      }
      if (serie.length > 0) {
        this.afficheRien = false
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: categories,
            labels: {
              style: {
                colors: couleur
              }
            }
          }
        }
      } else {
        this.afficheRien = true
      }
      this.series = [{
        data: serie
      }]
    },
    changementAnnee: function () {
      if (this.trie === 'annee') {
        this.serieParAnnee(this.annee)
      } else {
        this.serieParMois(this.mois)
      }
    },
    serieParMois: function (mois) {
      const liste = this.requeteSiteDonnees[this.annee][mois + 1]
      const serie = []
      const categories = []
      const couleur = this.chartOptions.colors[0]
      for (const jour in liste) {
        serie.push(liste[jour])
        categories.push(jour)
      }
      if (serie.length > 0) {
        this.afficheRien = false
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: categories,
            labels: {
              style: {
                colors: couleur
              }
            }
          }
        }
      } else {
        this.afficheRien = true
      }
      this.series = [{
        data: serie
      }]
    },
    changementMois: function () {
      if (this.trie === 'mois') {
        this.serieParMois(this.mois)
      }
    },
    additionner: function (liste) {
      let total = 0
      for (const jour in liste) {
        total += liste[jour]
      }
      return total
    },
    getTotal: function () {
      return this.total
    }
  }
}
</script>

<style type="text/css">
@media (prefers-color-scheme: dark) {
  .apexcharts-menu > div {
    color: black!important;
  }
  .button[selected='true'] {
    background-color: #0174CF!important;
    color: white!important;
    border: none!important;
  }
}
@media (prefers-color-scheme: light) {
  .button[selected='true'] {
    background-color: #47479F!important;
    color: white!important;
    border: none!important;
  }
}
</style>
