<template>
  <div class="option">
    <span
      v-if="!active"
      @click="modifier"
      class="icon is-medium has-text-primary fauxLien"
      title="Modifier">
      <i class="fad fa-pen"></i>
    </span>
    <span
      v-if="active"
      @click="annuler"
      class="icon is-medium has-text-danger fauxLien"
      title="Annuler la modification">
      <i class="fad fa-ban"></i>
    </span>
    <span v-if="active" style="width: 1vh;"></span>
    <span
      v-if="active"
      @click="valider"
      class="icon is-medium has-text-primary fauxLien"
      title="Valider la modification">
      <i class="fad fa-check"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Modifier',
  emits: ['modifier', 'annulerModification', 'validerModification'],
  data () {
    return {
      active: false,
      ancienneValeur: {}
    }
  },
  methods: {
    modifier: function () {
      this.active = true
      this.$emit('modifier')
    },
    valeur: function (valeur) {
      this.ancienneValeur = valeur
    },
    recupererAncienneValeur: function () {
      return this.ancienneValeur
    },
    annuler: function () {
      this.active = false
      this.$emit('annulerModification', this.ancienneValeur)
    },
    valider: function () {
      this.$emit('validerModification')
    },
    validerOk: function () {
      this.active = false
      this.ancienneValeur = {}
    }
  }
}
</script>

<style>
.option {
  display: inline-flex!important;
}
</style>
