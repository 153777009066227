<template>
  <form spellcheck="false">
    <div class="field">
      <div class="control has-text-centered">
        <input class="codeEntrer" @keydown='viderInput($event, 0)' @keyup='changerInput($event, 0)' type="text" v-model="p0" maxlength="1" autofocus spellcheck="false">
        <input class="codeEntrer" @keydown='viderInput($event, 1)' @keyup='changerInput($event, 1)' type="text" v-model="p1" maxlength="1" spellcheck="false">
        <input class="codeEntrer" @keydown='viderInput($event, 2)' @keyup='changerInput($event, 2)' type="text" v-model="p2" maxlength="1" spellcheck="false">
        <input class="codeEntrer" @keydown='viderInput($event, 3)' @keyup='changerInput($event, 3)' type="text" v-model="p3" maxlength="1" spellcheck="false">
        <input class="codeEntrer" @keydown='viderInput($event, 4)' @keyup='changerInput($event, 4)' type="text" v-model="p4" maxlength="1" spellcheck="false">
        <input class="codeEntrer" @keydown='viderInput($event, 5)' @keyup='changerInput($event, 5)' type="text" v-model="p5" maxlength="1" spellcheck="false">
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Etape2',
  emits: ['etatSuite', 'suiteOK', 'setIdentifiant'],
  data () {
    return {
      p0: '',
      p1: '',
      p2: '',
      p3: '',
      p4: '',
      p5: '',
      input: 0,
      nonAction: [17, 16, 91, 18, 32, 93, 37, 38, 40, 39, 16, 13, 20, 9, 8, 46],
      etat: 0
    }
  },
  watch: {
    input (n, a) {
      if (this.input < 6) {
        document.getElementsByClassName('codeEntrer')[this.input].focus()
      } else {
        this.input = 5
      }
    }
  },
  methods: {
    changerInput: function (touche, input) {
      if (this.nonAction.indexOf(touche.keyCode) === -1) {
        this.input = input + 1
      }
      this.verifierInput()
    },
    verifierInput: function () {
      if (this.p0 !== '' && this.p1 !== '' && this.p2 !== '' && this.p3 !== '' && this.p4 !== '' && this.p5 !== '') {
        this.etat = 1
        this.$emit('etatSuite', 1)
      } else {
        this.etat = 0
        this.$emit('etatSuite', 0)
      }
    },
    viderInput: function (touche, input) {
      if (this.nonAction.indexOf(touche.keyCode) === -1) {
        document.getElementsByClassName('codeEntrer')[input].value = ''
      }
    },
    envoieCode: function (email) {
      if (this.etat === 0) {
        // Si le code est incoplet
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Votre code est incomplet !', type: 'erreur' })
      } else {
        // Si tout est OK alors on verifie si le code correspond
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/MDPoublie/Etape2.php', {
          code: this.p0 + this.p1 + this.p2 + this.p3 + this.p4 + this.p5,
          email: email
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Si tout correspond
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Code bon, vous pouvez modifier votre mot de passe', type: 'ok' })
            this.$emit('setIdentifiant', reponse.data.id)
            this.$emit('suiteOK')
          } else if (reponse.data.etat === 2) {
            // Si le code ne correspond pas et qu'il reste des essaies
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Le code ne correspond pas (' + reponse.data.nbrEssaie + ' essaie(s) restants avant le changement du code) !', type: 'attention' })
          } else if (reponse.data.etat === 3) {
            // Si le code ne correspond pas et qu'il ne reste plus d'essaie
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Le code ne correspond pas ! Suite à de très nombreux echecs, un nouveau code a été généré et envoyé par mail !', type: 'attention' })
          } else {
            // En cas d'erreur
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Il y a eu un problème dans les informations envoyées !', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    }
  }
}
</script>

<style scoped>
.codeEntrer {
  -webkit-appearance: none;
  border: none;
  border-bottom: 2px solid;
  background: none;
  width: 13%;
  text-align: center;
  font-size: 200%;
  margin-right: 1%;
}
.codeEntrer:focus {
  outline: none;
}
</style>
