<template>
  <footer v-show="affichage==='input'" :class="p">
    <span class="card-footer-item fauxLien">
      <input type="file" name="upload" class="is-hidden" :id="inputId" @change="chargerImage($event)">
      <span class="icon is-large has-text-primary" type="file" @click='clickInput' title='Téléverser une image'>
        <i class="fad fa-upload fa-3x"></i>
      </span>
    </span>
    <span class="card-footer-item fauxLien" v-if="changement">
      <span class="icon has-text-info is-large" @click="reinitialiser" title="Réinitialiser l'image">
        <i class="fad fa-undo-alt fa-3x"></i>
      </span>
    </span>
    <span class="card-footer-item fauxLien">
      <span class="icon has-text-danger is-large" @click="viderImage" title="Supprimer l'image">
        <i class="fad fa-trash-alt fa-3x"></i>
      </span>
    </span>
  </footer>
  <footer v-show="affichage==='loader'" :class="p">
    <span class="card-footer-item fauxLien">
      <progress class="progress is-primary" :value="pourcent" max="100">{{ pourcent }}</progress>
    </span>
  </footer>
</template>

<script>
import axios from 'axios'
export default {
  name: 'UploadImage',
  emits: ['uploadOk', 'deleteOk', 'backOk'],
  props: ['inputId', 'position', 'num'],
  data () {
    return {
      affichage: 'input',
      base64: '',
      idSupp: 0,
      pourcent: 0,
      changement: false,
      p: 'card-footer '
    }
  },
  created: function () {
    if (this.position === 'abs') {
      this.p += 'abs'
    } else {
      this.p += 'ini'
    }
    if (this.num !== undefined) {
      this.idSupp = this.num
    }
  },
  watch: {
    base64: function () {
      this.envoieImage()
    }
  },
  methods: {
    clickInput: function () {
      document.getElementById(this.inputId).click()
    },
    chargerImage: function (e) {
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.onload = (evt) => {
        this.base64 = evt.target.result
      }
      reader.readAsDataURL(image)
    },
    envoieImage: function () {
      if (this.base64 !== '') {
        this.$store.commit('chargement')
        this.affichage = 'loader'
        const config = {
          onUploadProgress: (progressEvent) => {
            this.pourcent = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          }
        }
        axios.post(this.$store.getters.url + 'back/' + this.inputId.split('_')[0] + '/Image.php?i=' + this.$store.getters.numMembre, {
          image: this.base64,
          num: this.idSupp,
          info: this.inputId
        }, config).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Image uploadée avec succès !', type: 'ok' })
            this.$emit('uploadOk')
            this.changement = true
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'L\'image ne se trouve pas dans un bon format ! L\'image doit être inférieure ou égale à 12Mo et doit être sous un des formats suivants : \'png\', \'jpg\', \'jpeg\', \'gif\'.', type: 'attention' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
          this.affichage = 'input'
          this.pourcent = 0
        })
      }
    },
    viderImage: function () {
      this.$emit('deleteOk')
      this.changement = true
    },
    reinitialiser: function () {
      this.$emit('backOk')
      this.changement = false
    }
  }
}
</script>
<style scoped>
footer {
  width: 100%;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
footer.abs {
  position: absolute;
}
footer.ini {
  position: initial;
}
@media (prefers-color-scheme: light) {
  footer {
    background-color: #e6e6e6b3;
  }
}
@media (prefers-color-scheme: dark) {
  footer {
    background-color: #040404b3;
  }
}
</style>
