<template>
  <section>
    <div class="boutonOptionGM">
      <div class="option">
        <span title="Copier le lien de partage" @click="partagerLien" class="icon is-medium has-text-link fauxLien has-tooltip-bottom">
          <i class="fad fa-link"></i>
        </span>
      </div>
      <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouter"/>
      <Supprimer @supprimer="supprimer" @annulerSuppression="annulerSuppression" @validerSuppression="validerSuppression" ref="supprimer"/>
    </div>
    <div class="boutonOptionDM">
      <Bloquer :etatDebut="$store.getters.statutGroupe" @bloquer="bloquerCode" @debloquer="debloquerCode" />
      <div class="option">
        <span title="Informations" @click="afficherModal" class="icon is-medium has-text-info fauxLien has-tooltip-bottom">
          <i class="fad fa-info"></i>
        </span>
      </div>
    </div>
    <article>
      <h1 class="title has-text-centered">Gestion du groupe</h1>
    </article>
    <div :class="modal">
      <div class="modal-background" @click="fermerModal"></div>
      <div class="modal-content content">
        <p>Pour ajouter une personne dans le groupe :</p>
        <ul>
          <li>L'ajouter via l'annuaire public</li>
          <li>Partager votre lien d'affiliation</li>
          <li>Créer un nouveau compte</li>
        </ul>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="fermerModal"></button>
    </div>
    <!-- Nouveau -->
    <div v-if="ajouterForm" class="tile is-ancestor">
      <div class="tile is-12 is-vertical is-parent">
        <div class='une tile is-child box nouveau'>
          <h3 class="subtitle has-text-centered">Création d'un compte utilisateur</h3>
          <div class="columns">
            <!-- Nom du membre -->
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Nom</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input type='text' class='input' v-model="nouveauNom">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Prénom du membre -->
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Prénom</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input type='text' class='input' v-model="nouveauPrenom">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Statut du membre -->
            <div class="column">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Statut</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <div class="select">
                        <select v-model="nouveauStatut">
                          <option v-for="statut in $store.state.listeStatut" :key="statut" :value="statut.id"> {{ statut.Nom }} </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns scrollable" id="scrollableMembre">
            <div
            v-for="projet in $store.state.membre.listeProjet"
            v-show="projet.groupe === $store.state.membre.groupeEnCours.idGroupe"
            :key="projet"
            class="column is-6-tablet is-4-widescreen">
              <div class="card">
                <div class="card-image has-text-centered">
                  <h3 class="subtitle">
                    <b>{{ projet.nom }}</b>
                  </h3>
                </div>
                <div class="card-content">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th style="width: 80%"></th>
                        <td><span class="icon"><i class="fad fa-eye"></i></span></td>
                        <td><span class="icon"><i class="fad fa-pencil-paintbrush"></i></span></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      v-for="onglet in $store.state.listeOnglet"
                      :key="onglet">
                        <th class="titreDroit">{{ onglet.nom }}</th>
                        <td class="iconOnglet">
                          <div class="field">
                            <input @change="lecture(nouveauDroit[projet.numProjet][onglet.id])" type="checkbox" class="is-checkradio" :name="'nouveau_' + onglet.id + '_' + projet.numProjet + '_l'" :id="'nouveau_' + onglet.id + '_' + projet.numProjet + '_l'" v-model="nouveauDroit[projet.numProjet][onglet.id].lecture">
                            <label :for="'nouveau_' + onglet.id + '_' + projet.numProjet + '_l'"></label>
                          </div>
                        </td>
                        <td class="iconOnglet">
                          <div class="field">
                            <input @change="ecriture(nouveauDroit[projet.numProjet][onglet.id])" type="checkbox" class="is-checkradio" :name="'nouveau_' + onglet.id + '_' + projet.numProjet + '_e'" :id="'nouveau_' + onglet.id + '_' + projet.numProjet + '_e'" v-model="nouveauDroit[projet.numProjet][onglet.id].ecriture">
                            <label :for="'nouveau_' + onglet.id + '_' + projet.numProjet + '_e'"></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Adresse Email -->
          <div class="columns">
            <!-- Nom du membre -->
            <div class="column is-6">
              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">Email</label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input type='email' class='input' v-model="nouveauEmail">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Liste -->
    <article class="tile is-ancestor">
      <div class="tile is-12 is-vertical is-parent">
        <div
        v-for="membre in listeMembre"
        :key="membre"
        :aSupprimer="membre.aSupprimer"
        :aSelectionner="membre.aSelectionner"
        @click="aSelectionner(membre)"
        class="tile is-child box unSeul">
          <div class="boutonOptionGM">
            <Modifier tooltipSens="top" @modifier="modifier(membre)" @annulerModification="annulerModification" @validerModification="validerModification(membre)" :ref="'membre' + membre.numMembre" />
          </div>
          <div class="columns">
            <!-- Nom du membre -->
            <div class="column">
              <p v-if="!membre.aModifier"><b>{{ membre.nom.toUpperCase() }}</b></p>
              <div v-else class="field">
                <div class="control">
                  <input type='text' placeholder="Nom du membre" class='input' v-model="membre.nom">
                </div>
              </div>
            </div>
            <!-- Prénom du membre -->
            <div class="column">
              <p v-if="!membre.aModifier"><b>{{ membre.prenom }}</b></p>
              <div v-else class="field">
                <div class="control">
                  <input type='text' placeholder="Nom du membre" class='input' v-model="membre.prenom">
                </div>
              </div>
            </div>
            <!-- Statut du membre -->
            <div class="column">
              <p v-if="!membre.aModifier"><b>{{ $store.getters.getStatut(membre.statut) }}</b></p>
              <div v-else class="field">
                <div class="control">
                  <div class="select">
                    <select v-model="membre.statut">
                      <option v-for="statut in $store.state.listeStatut" :key="statut" :value="statut.id"> {{ statut.Nom }} </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="membre.detail" class="columns scrollable" id="scrollableMembre">
            <div
            v-for="projet in $store.state.membre.listeProjet"
            v-show="projet.groupe === $store.state.membre.groupeEnCours.idGroupe"
            :key="projet"
            class="column is-6-tablet is-4-widescreen">
              <div class="card">
                <div class="card-image has-text-centered">
                  <h3 class="subtitle">
                    <b>{{ projet.nom }}</b>
                  </h3>
                </div>
                <div class="card-content">
                  <table class="table is-fullwidth">
                    <thead>
                      <tr>
                        <th style="width: 80%"></th>
                        <td><span class="icon"><i class="fad fa-eye"></i></span></td>
                        <td><span class="icon"><i class="fad fa-pencil-paintbrush"></i></span></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                      v-for="onglet in $store.state.listeOnglet"
                      :key="onglet">
                        <th class="titreDroit">{{ onglet.nom }}</th>
                        <td v-if="!membre.aModifier">
                          <span v-if="membre.droitMembre[projet.numProjet][onglet.id].lecture" class="icon has-text-success" style="font-size: 1em;"><i class="fad fa-check"></i></span>
                          <span v-else class="icon has-text-danger" style="font-size: 1em;"><i class="fad fa-times"></i></span>
                        </td>
                        <td v-else class="iconOnglet">
                          <div class="field">
                            <input @change="lecture(membre.droitMembre[projet.numProjet][onglet.id])" type="checkbox" class="is-checkradio" :name="membre.numMembre + '_' + onglet.id + '_' + projet.numProjet + '_l'" :id="membre.numMembre + '_' + onglet.id + '_' + projet.numProjet + '_l'" v-model="membre.droitMembre[projet.numProjet][onglet.id].lecture">
                            <label :for="membre.numMembre + '_' + onglet.id + '_' + projet.numProjet + '_l'"></label>
                          </div>
                        </td>
                        <td v-if="!membre.aModifier">
                          <span v-if="membre.droitMembre[projet.numProjet][onglet.id].ecriture" class="icon has-text-success" style="font-size: 1em;"><i class="fad fa-check"></i></span>
                          <span v-else class="icon has-text-danger" style="font-size: 1em;"><i class="fad fa-times"></i></span>
                        </td>
                        <td v-else class="iconOnglet">
                          <div class="field">
                            <input @change="ecriture(membre.droitMembre[projet.numProjet][onglet.id])" type="checkbox" class="is-checkradio" :name="membre.numMembre + '_' + onglet.id + '_' + projet.numProjet + '_e'" :id="membre.numMembre + '_' + onglet.id + '_' + projet.numProjet + '_e'" v-model="membre.droitMembre[projet.numProjet][onglet.id].ecriture">
                            <label :for="membre.numMembre + '_' + onglet.id + '_' + projet.numProjet + '_e'"></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Adresse Email -->
          <div class="columns">
            <!-- Nom du membre -->
            <div class="column">
              <p v-if="!membre.aModifier"><b><a class="fauxLien" :href='"mailto:" + membre.adresseMail'>{{ membre.adresseMail }}</a></b></p>
              <div v-else class="field">
                <div class="control">
                  <input type='email' placeholder="Email du membre" class='input' v-model="membre.adresseMail">
                </div>
              </div>
            </div>
            <!-- Date d'inscription du membre -->
            <div class="column has-text-right">
              <p><b>Membre depuis le {{ membre.dateInscription }}</b></p>
            </div>
          </div>
          <div @click="afficherDetail(membre)" class="has-text-centered detailAffiche" :data-affiche="membre.detail">
            <span class="icon is-medium">
              <i class="fad fa-2x fa-chevron-right"></i>
            </span>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script>

import Modifier from '@/components/Options/Modifier.vue'
import Ajouter from '@/components/Options/Ajouter.vue'
import Supprimer from '@/components/Options/Supprimer.vue'
import Bloquer from '@/components/Options/Bloquer.vue'
import axios from 'axios'

export default {
  name: 'GestionMembre',
  components: {
    Modifier,
    Ajouter,
    Supprimer,
    Bloquer
  },
  data () {
    return {
      listeMembre: [],
      ajouterForm: false,
      etatGroupe: 1,
      nouveauNom: '',
      nouveauPrenom: '',
      nouveauStatut: 2,
      nouveauDroit: {},
      nouveauDroitDefaut: {},
      nouveauEmail: '',
      modal: 'modal'
    }
  },
  created: function () {
    axios.get(this.$store.getters.url + 'back/Groupe/Charger.php?i=' + this.$store.getters.numMembre + '&g=' + this.$store.state.membre.groupeEnCours.idGroupe).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.nouveauDroit = reponse.data.nouveauDroit
        this.nouveauDroitDefaut = reponse.data.nouveauDroit
        this.listeMembre = reponse.data.donnees
        this.etatGroupe = reponse.data.etatGroupe
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  mounted: function () {
    setTimeout(this.activateScroll, 100)
  },
  methods: {
    // Functions de base
    afficherModal: function () {
      this.modal = 'modal is-active'
    },
    fermerModal: function () {
      this.modal = 'modal'
    },
    activateScroll: function () {
      const projets = document.querySelectorAll('#scrollableMembre')
      projets.forEach(function (p) {
        p.addEventListener('wheel', e => {
          e.preventDefault()
          const scroll = e.deltaY - e.wheelDeltaY
          p.scrollLeft += scroll
        })
      })
    },
    afficherDetail: function (membre) {
      membre.detail = !membre.detail
    },
    ecriture: function (onglet) {
      if (onglet.ecriture) {
        onglet.lecture = true
        onglet.point = 2
      } else {
        onglet.point = 1
      }
    },
    lecture: function (onglet) {
      if (!onglet.lecture) {
        onglet.ecriture = false
        onglet.point = 0
      } else {
        onglet.point = 1
      }
    },
    // Vérification des inputs
    validerForm: function (membre) {
      let i = 0
      let message = ''
      if (membre.nom === '') {
        i++
        message += 'Le membre n\'a pas de nom<br>'
      }
      if (membre.prenom === '') {
        i++
        message += 'Le membre n\'a pas de prenom<br>'
      }
      if (!this.$store.state.regex.email.test(membre.adresseMail)) {
        i++
        message += 'Email non conforme.<br>'
      }
      if (i > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
        return false
      } else {
        return true
      }
    },
    // Modifier
    modifier: function (membre) {
      membre.aModifier = true
      this.$refs['membre' + membre.numMembre].valeur(JSON.parse(JSON.stringify(membre)))
    },
    annulerModification: function (anciennesValeur) {
      for (var i = 0; i < this.listeMembre.length; i++) {
        if (anciennesValeur.numMembre === this.listeMembre[i].numMembre) {
          this.listeMembre[i] = anciennesValeur
          this.listeMembre[i].aModifier = false
          break
        }
      }
    },
    validerModification: function (membre) {
      if (this.validerForm(membre)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Groupe/Modifier.php?i=' + this.$store.getters.numMembre, {
          membre: membre,
          groupe: this.$store.state.membre.groupeEnCours.idGroupe
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Remettre par défaut
            membre.aModifier = false
            // Regarder si la modification est pour nous
            if (parseInt(membre.numMembre) === parseInt(this.$store.getters.numMembre)) {
              this.$store.state.membre.adresseMail = membre.adresseMail
              this.$store.state.membre.nom = membre.nom.toUpperCase()
              this.$store.state.membre.prenom = membre.prenom
              this.$store.state.membre.groupeEnCours.statutMembre = membre.statut
              for (var i = 0; i < this.$store.state.membre.groupe.length; i++) {
                if (this.$store.state.membre.groupe[i].idGroupe === this.$store.state.membre.groupeEnCours.idGroupe) {
                  this.$store.state.membre.groupe[i].statutMembre = membre.statut
                }
              }
              for (const numProjet in membre.droitMembre) {
                this.$store.state.membre.listeDroit[numProjet][1] = parseInt(membre.droitMembre[numProjet][1].point)
                this.$store.state.membre.listeDroit[numProjet][2] = parseInt(membre.droitMembre[numProjet][2].point)
                this.$store.state.membre.listeDroit[numProjet][3] = parseInt(membre.droitMembre[numProjet][3].point)
                this.$store.state.membre.listeDroit[numProjet][4] = parseInt(membre.droitMembre[numProjet][4].point)
                this.$store.state.membre.listeDroit[numProjet][5] = parseInt(membre.droitMembre[numProjet][5].point)
                this.$store.state.membre.listeDroit[numProjet][6] = parseInt(membre.droitMembre[numProjet][6].point)
              }
            }
            this.$refs['membre' + membre.numMembre].validerOk()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Membre modifié avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    // Supprimer
    supprimer: function () {
      for (var i = 0; i < this.listeMembre.length; i++) {
        this.listeMembre[i].aSupprimer = true
      }
    },
    annulerSuppression: function () {
      for (var i = 0; i < this.listeMembre.length; i++) {
        this.listeMembre[i].aSupprimer = false
        this.listeMembre[i].aSelectionner = false
      }
    },
    aSelectionner: function (membre) {
      if (membre.aSupprimer) {
        membre.aSelectionner = !membre.aSelectionner
      }
    },
    validerSuppression: function () {
      const liste = []
      const idListe = []
      for (var i = 0; i < this.listeMembre.length; i++) {
        if (this.listeMembre[i].aSelectionner) {
          liste.push({ id: this.listeMembre[i].id, prenom: this.listeMembre[i].prenom, adresseMail: this.listeMembre[i].adresseMail })
          idListe.push(this.listeMembre[i].id)
        }
      }
      if (liste.length > 0) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Groupe/Supprimer.php?i=' + this.$store.getters.numMembre, {
          liste: liste,
          groupe: this.$store.state.membre.groupeEnCours
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Supprimer les membre du groupe
            const nonSupprimer = []
            for (var i = 0; i < this.listeMembre.length; i++) {
              if (this.listeMembre[i].id.indexOf(idListe) === -1) {
                nonSupprimer.push(this.listeMembre[i])
              }
            }
            this.listeMembre = nonSupprimer
            // Défaut
            this.$refs.supprimer.validerOk()
            this.annulerSuppression()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Membre(s) supprimé(s) du groupe avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien a supprimer !', type: 'info' })
      }
    },
    // Ajouter un projet
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouveauNom = ''
      this.nouveauPrenom = ''
      this.nouveauStatut = 2
      this.nouveauDroit = this.nouveauDroitDefaut
      this.nouveauEmail = ''
    },
    validerAjout: function () {
      const membre = {
        aModifier: false,
        aSelectionner: false,
        aSupprimer: false,
        detail: false,
        id: 0,
        numMembre: 0,
        adresseMail: this.nouveauEmail,
        nom: this.nouveauNom,
        prenom: this.nouveauPrenom,
        dateInscription: 0,
        statut: this.nouveauStatut,
        droitMembre: this.nouveauDroit
      }
      if (this.validerForm(membre)) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Groupe/Creer.php?i=' + this.$store.getters.numMembre, {
          membre: membre,
          groupe: this.$store.state.membre.groupeEnCours
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Ajouter un numMembre
            membre.numMembre = reponse.data.numMembre
            membre.id = reponse.data.id
            // Modifier les valeurs de dates
            membre.dateInscription = reponse.data.dateInscription
            // Mettre les idDo aux droits
            const listeIdDo = reponse.data.idDo
            for (var i = 0; i < listeIdDo.length; i++) {
              membre.droitMembre[listeIdDo[i].numProjet].id = listeIdDo[i].idDo
            }
            // Ajouter dans la liste
            this.listeMembre.push(membre)
            console.log(membre)
            // Remise par défaut
            this.$refs.ajouter.validerOk()
            this.annulerAjout()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Membre créée avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      }
    },
    // Copie du code d'affiliation
    partagerLien: function () {
      if (!navigator.clipboard) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'La copie ne fonctionne pas avec votre navigateur, votre code groupe est : <b>' + this.$store.getters.lienGroupe + '</b>', type: 'attention' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Code d\'affiliation copié !', type: 'info' })
        navigator.clipboard.writeText(this.$store.getters.lienGroupe)
      }
    },
    // Statut groupe
    bloquerCode: function () {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Groupe/Bloquer.php?i=' + this.$store.getters.numMembre, {
        groupe: this.$store.state.membre.groupeEnCours
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.$store.commit('changerStatutGroupeEnCours')
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Le groupe est désormais fermé !', type: 'ok' })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    debloquerCode: function () {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Groupe/Debloquer.php?i=' + this.$store.getters.numMembre, {
        groupe: this.$store.state.membre.groupeEnCours
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.$store.commit('changerStatutGroupeEnCours')
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Le groupe est désormais ouvert !', type: 'ok' })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    }
  }
}
</script>

<style scoped>
.scrollable .image {
  width: 90%;
  height: 7vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 0.5rem;
}
.scrollable .card-content {
  padding: 0.5rem!important;
}
.scrollable .card-content .icon.is-medium img {
  height: 1.5rem!important;
  width: 1.5rem!important;
}
.titreDroit {
  text-align: left!important;
}
table {
  background-color: transparent!important;
  color: initial!important;
}
th {
  color: inherit!important;
}
.unSeul .container[aSupprimer=true] {
  cursor: pointer;
}
.unSeul .container[aSupprimer=true][aSelectionner=true] {
  border: 3px dashed #f14668;
}

.detailAffiche {
  position: absolute;
  bottom: 0;
  left: 45%;
  width: 10%;
  cursor: pointer;
}
.detailAffiche[data-affiche=false] .icon {
  transform: rotate(90deg);
}
.detailAffiche[data-affiche=true] .icon {
  transform: rotate(-90deg);
}
</style>
