<template>
  <section>
    <article>
      <h1 class="title has-text-centered">Gestion des campagnes</h1>
    </article>
    <div class="boutonOptionDM" v-show="listeCampagne.length > 0">
      <div class="option">
        <span title="Gestion" class="icon is-medium has-text-info fauxLien" @click="retour()">
          <i class="fad fa-arrow-left"></i>
        </span>
      </div>
      <div class="option" style="padding: 0.06rem 0.3rem;">
        <div class="select is-normal">
          <select v-model="campagneId" class="selectCampagne">
            <option v-for="campagne in listeCampagne" :key="campagne" :value="campagne.id"> {{ campagne.nom }} </option>
          </select>
        </div>
      </div>
    </div>
    <div class="boutonOptionGM">
      <Ajouter @ajouter="ajouter" @annulerAjout="annulerAjout" @validerAjout="validerAjout" ref="ajouterCampagne"/>
      <Modifier @modifier="modifier" @annulerModification="annulerModification" @validerModification="validerModification" ref="modifierCampagne" v-if="listeCampagne.length > 0"/>
      <Supprimer @validerSuppression="validerSuppression" ref="supprimerCampagne" v-if="listeCampagne.length > 0"/>
    </div>
    <article class="tile is-ancestor">
      <div class="tile is-12 is-vertical is-parent">
        <!-- Ajout -->
        <div class="tile is-child box unSeul" v-if="ajouterForm">
          <div class="columns is-vcentered">
            <div class="column is-one-quarter">
              <p><b>Ajouter une nouvelle campagne :</b></p>
            </div>
            <div class="column is-half">
              <input type="input" class="input" v-model="nouvelleCampagne" placeholder="Titre de la campagne">
            </div>
          </div>
        </div>
        <div class="tile is-child box unSeul" v-if="campagne !== 0">
          <h3 class="subtitle">Paramètres</h3>
          <div class="columns is-vcentered">
            <div class="column is-one-quarter">
              <p><b>Email d'envoie : </b></p>
            </div>
            <div v-if="!modifierForm" class="column">
              <span v-if="email === '' || email === null">Non spécifié</span>
              <span v-else>{{ email }}</span>
            </div>
            <div v-else class="column">
              <div class="control has-icons-left">
                <input type="email" class="input" v-model="email" placeholder="Email d'envoie">
                <span class="icon is-left">
                  <i class="fad fa-at"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-one-quarter">
              <p><b>Lien API : </b></p>
            </div>
            <div v-if="!modifierForm" class="column">
              <span v-if="api === '' || api === null">Non spécifié</span>
              <span v-else>{{ api }}</span>
            </div>
            <div v-else class="column">
              <div class="field">
                <div class="control has-icons-left">
                  <input type='text' class='input' placeholder="https://www.exemple.com/api/newsletters.php" v-model="api">
                  <span class="icon is-left">
                    <i class="fad fa-link"></i>
                  </span>
                </div>
                <p class="help is-info" v-if="helps.info">Connexion à votre API ...</p>
                <p class="help is-success" v-if="helps.success">Connexion réussi, vous avez {{ nbrAbonne }} abonné(s)</p>
                <p class="help is-danger" v-if="helps.danger">Echec de la connexion : {{ erreurConnexionApi }}</p>
              </div>
            </div>
          </div>
          <div class="columns is-vcentered">
            <div class="column is-one-quarter">
              <p><b>Lien désinscription : </b></p>
            </div>
            <div v-if="!modifierForm" class="column">
              <span v-if="lien === '' || lien === null">Non spécifié</span>
              <span v-else>{{ lien }}</span>
            </div>
            <div v-else class="column">
              <div class="field">
                <div class="control has-icons-left">
                  <input type='text' class='input' placeholder="https://www.exemple.com/api/desinscription.php" v-model="lien">
                  <span class="icon is-left">
                    <i class="fad fa-link"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tile is-child box unSeul" v-if="campagne !== 0">
          <h3 class="subtitle">Image d'entête</h3>
          <div class="columns is-centered">
            <div class="column is-8 entete">
              <span v-if="entete === 0 && !modifierForm">Non spécifié</span>
              <img v-if="entete === 1" :src="enteteLien" style="height: 20vh!important;">
              <UploadImage  v-if="modifierForm" :inputId="'Newsletters_'+projet+'_'+campagneId" :position="'abs'" @uploadOk="enteteNew" @deleteOk="enteteDelete" @backOk='enteteAncien' ref="changerEntete"/>
            </div>
          </div>
        </div>
        <div class="tile is-child box unSeul" v-if="campagne !== 0">
          <h3 class="subtitle">Bas de page</h3>
          <div class="columns is-centered">
            <div class="column is-11 has-text-centered" v-if="!modifierForm">
              <span v-if="basDePage === '' || basDePage === null">Non spécifié</span>
              <div v-else>{{ basDePage }}</div>
            </div>
            <div class="column is-11" v-else>
              <textarea class="textarea" placeholder="Texte de bas de page" v-model="basDePage"></textarea>
            </div>
          </div>
        </div>
      </div>
    </article>
  </section>
</template>

<script type="text/javascript">
import Supprimer from '@/components/Options/Supprimer.vue'
import Ajouter from '@/components/Options/Ajouter.vue'
import Modifier from '@/components/Options/Modifier.vue'
import UploadImage from '@/components/Formulaire/UploadImage.vue'
import axios from 'axios'

export default {
  name: 'Gestion',
  props: ['projet', 'listeCampagne', 'campagne'],
  emits: ['retour-liste', 'ajout-campagne', 'supprimer-campagne'],
  components: {
    Supprimer,
    Ajouter,
    Modifier,
    UploadImage
  },
  data () {
    return {
      ajouterForm: false,
      nouvelleCampagne: '',
      campagneId: 0,
      modifierForm: false,
      email: '',
      api: '',
      nbrAbonne: 0,
      erreurConnexionApi: '',
      helps: {
        info: false,
        success: false,
        erreur: false
      },
      lien: '',
      entete: null,
      enteteLien: this.$store.getters.url + 'back/Newsletters/Liste/' + this.projet + '/' + this.campagne + '/entete.png',
      basDePage: ''
    }
  },
  mounted () {
    this.campagneId = this.campagne
    if (this.listeCampagne.length > 0) {
      this.chargerInfo()
    } else {
      this.$refs.ajouterCampagne.ajouter()
    }
  },
  watch: {
    campagneId (n, a) {
      if (n !== a && n !== 0) {
        this.chargerInfo()
      } else if (n === 0) {
        this.$refs.ajouterCampagne.ajouter()
      }
    }
  },
  methods: {
    retour: function () {
      if (this.listeCampagne.length > 0) {
        // Retour
        this.$emit('retour-liste')
      }
    },
    ajouter: function () {
      this.ajouterForm = true
    },
    annulerAjout: function () {
      this.ajouterForm = false
      this.nouvelleCampagne = ''
    },
    validerAjout: function () {
      if (this.nouvelleCampagne !== '') {
        let campagne = {
          id: 0,
          nom: this.nouvelleCampagne
        }
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Newsletters/NouvelleCampagne.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet, {
          nom: this.nouvelleCampagne
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            campagne = reponse.data.campagne
            this.campagneId = campagne.id
            this.$refs.ajouterCampagne.validerOk()
            this.annulerAjout()
            this.$emit('ajout-campagne', campagne)
          } else if (reponse.data.etat === -2) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits !', type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Aucun titre n\'a été donné à la nouvelle campagne !', type: 'erreur' })
      }
    },
    chargerInfo: function () {
      this.$store.commit('chargement')
      axios.get(this.$store.getters.url + 'back/Newsletters/ChargerCampagne.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&c=' + this.campagneId).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.email = reponse.data.email
          this.emailInput = reponse.data.email
          this.api = reponse.data.api
          this.apiInput = reponse.data.api
          this.lien = reponse.data.lien
          this.lienInput = reponse.data.lien
          this.entete = parseInt(reponse.data.entete)
          this.basDePage = reponse.data.basdepage
        } else if (reponse.data.etat === -2) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits !', type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    validerSuppression: function () {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Newsletters/SupprimerCampagne.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet, {
        id: this.campagneId
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.$emit('supprimer-campagne', this.campagneId)
          this.$refs.supprimerCampagne.validerOk()
          this.campagneId = this.campagne
        } else if (reponse.data.etat === -2) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits !', type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
        this.campagneId = this.campagne
      })
    },
    modifier: function () {
      this.modifierForm = true
      const anciennesValeur = {
        email: this.email,
        api: this.api,
        lien: this.lien,
        entete: this.entete,
        basDePage: this.basDePage
      }
      this.$refs.modifierCampagne.valeur(JSON.parse(JSON.stringify(anciennesValeur)))
    },
    annulerModification: function (anciennesValeur) {
      this.modifierForm = false
      this.email = anciennesValeur.email
      this.api = anciennesValeur.api
      this.lien = anciennesValeur.lien
      this.entete = anciennesValeur.entete
      this.basDePage = anciennesValeur.basDePage
    },
    verifForm: function () {
      let i = 0
      let message = ''
      // Test de l'email
      if (this.email !== '' && !this.$store.state.regex.email.test(this.email)) {
        i++
        message += 'Email non conforme.<br>'
      }
      // Test du lien de désinscription
      if (this.lien !== '' && !this.$store.state.regex.lien.test(this.lien)) {
        i++
        message += 'Le lien de désinscription n\'est conforme.<br>'
      }
      // Test de l'API
      if (this.api !== '') {
        if (!this.$store.state.regex.lien.test(this.api)) {
          i++
          message += 'Le lien de l\'API n\'est conforme.<br>'
          this.verifFormRetour(i, message)
        } else if (this.api !== '') {
          // Si le lien est OK tester la réponse du lien
          this.helps.info = true
          axios.get(this.api).then((reponse) => {
            this.helps.info = false
            this.helps.success = true
            this.helps.danger = false
            this.nbrInscritApi = parseInt(reponse.data.nbrInscrit)
            this.verifFormRetour(i, message)
          }).catch((error) => {
            i++
            message += 'Votre API est introuvable !<br>'
            this.helps.info = false
            this.helps.danger = true
            this.helps.success = false
            this.erreurConnexionApi = error.message
            this.verifFormRetour(i, message)
          })
        }
      } else {
        this.verifFormRetour(i, message)
      }
    },
    verifFormRetour: function (i, message) {
      if (parseInt(i) > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
      } else {
        this.validerModification(true)
      }
    },
    validerModification: function (valide = false) {
      // Vérifier le formulaire
      if (valide) {
        const nouvelleValeur = {
          email: this.email,
          api: this.api,
          lien: this.lien,
          entete: this.entete,
          basDePage: this.basDePage
        }
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Newsletters/ModifierCampagne.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&c=' + this.campagneId, {
          campagne: nouvelleValeur
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.modifierForm = false
            this.$refs.modifierCampagne.validerOk()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Modification enregistré !', type: 'ok' })
          } else if (reponse.data.etat === -2) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits !', type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.verifForm()
      }
    },
    enteteNew: function () {
      this.entete = 1
      this.enteteLien = this.$store.getters.url + 'back/Newsletters/Liste/' + this.projet + '/' + this.campagneId + '/entete.png?' + performance.now()
    },
    enteteDelete: function () {
      this.entete = 0
    },
    enteteAncien: function () {
      this.entete = this.$refs.modifierCampagne.recupererAncienneValeur().entete
    }
  }
}
</script>

<style scoped>
.selectCampagne {
  border: none;
  background: transparent;
}
.selectCampagne:focus, .selectCampagne:active {
  border: none;
  background: transparent;
  box-shadow: none;
}
/*Sans Logo*/
.afficheLogo .avatar {
  height: auto !important;
  font-size: 1.2rem !important;
  width: 4rem !important;
  font-weight: bold;
}

/*Avec Logo*/
.afficheLogo .avatar img {
  width: auto !important;
}
.entete {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  padding-bottom: 5rem;
}
.imageSection {
  min-height: 8rem;
}
.textarea.is-transparent {
  background-color: rgba(255, 255, 255, 0.6);
}
input.is-static {
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 0;
}
</style>
