<template>
  <article>
    <div class="columns">
      <div class="column is-paddingless is-half is-offset-one-quarter">
        <h1 class="title has-text-centered">
          <textarea class="input is-static" rows="2" v-model="titre" placeholder="Titre - Maximum 50 caractères" maxlength="50" autofocus @focusout="changerTitre()"></textarea>
        </h1>
      </div>
    </div>
  </article>
  <div class="boutonOptionDM">
    <div class="option">
      <span title="Retour" class="icon is-medium has-text-info fauxLien" @click="retour()">
        <i class="fad fa-arrow-left"></i>
      </span>
    </div>
    <div class="option" style="padding: 0.06rem 0.3rem;">
      <div class="select is-normal" v-if="$store.getters.droitOnglet(projet, 4) === 2">
        <select v-model="campagneId" class="selectCampagne">
          <option v-for="campagne in listeCampagne" :key="campagne" :value="campagne.id"> {{ campagne.nom }} </option>
        </select>
      </div>
    </div>
  </div>
  <div class="boutonOptionGM">
    <div class="option">
      <span title="Test" class="icon is-medium has-text-info fauxLien" @click="test()">
        <i class="fad fa-vials"></i>
      </span>
    </div>
    <Planifier @planifier="planifier" @annulerPlanification="annulerPlanification" @validerPlanification="validerPlanification" @supprimerPlanification="supprimerPlanification" ref="planifier" :estPlanifier="dateEnvoie !== null" v-if="$store.getters.droitOnglet(projet, 4) === 2"/>
  </div>
  <article class="tile is-ancestor">
    <div class="tile is-12 is-vertical is-parent">
      <div class="tile is-child box unSeul">
        <div class="columns is-vcentered">
            <div class="column is-one-quarter">
              <p><b>Planification de l'envoie :</b></p>
            </div>
            <div class="column">
              <span v-if="dateEnvoie === null && !planification">Non programmé.</span>
              <span v-else-if="dateEnvoie !== null && !planification">Progammé pour le {{ dateEnvoie }} à 8h.</span>
              <input v-else type="date" class="input" v-model="dateEnvoieInput" pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}">
            </div>
          </div>
      </div>
      <div class="tile is-child box unSeul">
        <Tiptap :texte="texte" quoi="Newsletters" :info="this.projet+'_'+this.campagneId+'_'+this.newsId" :droit="$store.getters.droitOnglet(projet, 4)" @change-texte="changerTexte" v-if="ready" />
      </div>
    </div>
  </article>
</template>

<script type="text/javascript">
import Planifier from '@/components/Options/Planifier.vue'
import Tiptap from '@/components/PartieDroite/Newsletters/Tiptap.vue'
import axios from 'axios'

export default {
  name: 'Creation',
  components: {
    Tiptap,
    Planifier
  },
  props: ['projet', 'news', 'listeCampagne', 'campagne'],
  emits: ['retour-liste', 'ajout-news', 'changer-titre', 'changer-date', 'changer-campagne'],
  data () {
    return {
      projetInfo: {},
      campagneId: this.campagne,
      newsId: 0,
      titre: '',
      texte: '',
      ready: false,
      lien: '',
      dateEnvoie: null,
      dateEnvoieHtml: '',
      dateEnvoieInput: '',
      planification: false
    }
  },
  created () {
    if (this.news === 0) {
      console.log('Création')
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Newsletters/NouvelleNews.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet, { campagne: this.campagneId }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.newsId = reponse.data.news.id
          this.lien = reponse.data.lien
          this.$emit('ajout-news', reponse.data.news)
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
        this.ready = true
      })
    } else {
      console.log('Modifier')
      this.newsId = this.news
      this.$store.commit('chargement')
      axios.get(this.$store.getters.url + 'back/Newsletters/ChargerNews.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&n=' + this.newsId).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.titre = reponse.data.titre
          this.texte = reponse.data.texte
          this.lien = reponse.data.lien
          this.dateEnvoie = reponse.data.dateEnvoie
          this.dateEnvoieHtml = reponse.data.dateEnvoieInput
          this.dateEnvoieInput = reponse.data.dateEnvoieInput
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
        this.ready = true
      })
    }
    this.projetInfo = this.$store.getters.getProjetInfo(this.projet)
  },
  watch: {
    titre (n, a) {
      if (n !== null && n.length > 50 && this.ready) {
        this.titre = a
      }
    },
    campagneId (n, a) {
      if (n !== a && this.ready) {
        this.changerCampagne(a)
      }
    }
  },
  methods: {
    retour: function () {
      this.$emit('retour-liste')
    },
    changerTitre: function () {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Newsletters/ChangerTitre.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&n=' + this.newsId, {
        titre: this.titre
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.$emit('changer-titre', { newsId: this.newsId, titre: this.titre })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Votre titre est trop long ! (max: 50 caractères)', type: 'erreur' })
        } else if (reponse.data.etat === -1) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur lors de la sauvegarde du titre !', type: 'erreur' })
        } else if (reponse.data.etat === -2) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de modification !', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    changerCampagne: function (a) {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Newsletters/ChangerCampagne.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&n=' + this.newsId, {
        campagne: this.campagneId,
        campagneAncien: a
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.lien = reponse.data.lien
          this.$emit('changer-campagne', { newsId: this.newsId, campagne: this.campagneId })
        } else if (reponse.data.etat === -1) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur lors du changement de campagne !', type: 'erreur' })
        } else if (reponse.data.etat === -2) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de modification !', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    changerTexte: function (texte) {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Newsletters/ChangerTexte.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&n=' + this.newsId + '&c=' + this.campagneId, {
        texte: texte
      }).then((reponse) => {
        if (reponse.data.etat === -1) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur lors de la sauvegarde automatique du texte !', type: 'erreur' })
        } else if (reponse.data.etat === -2) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de modification !', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    test: function () {
      window.open(this.$store.getters.url + 'back/Newsletters/Affichage.php?' + this.lien, '_blank')
    },
    planifier: function () {
      this.planification = true
    },
    annulerPlanification: function () {
      this.planification = false
      this.dateEnvoieInput = this.dateEnvoieHtml
    },
    validerPlanification: function () {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Newsletters/Planifier.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&n=' + this.newsId, {
        dateEnvoie: this.dateEnvoieInput
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.dateEnvoie = reponse.data.dateEnvoie
          this.dateEnvoieHtml = this.dateEnvoieInput
          this.planification = false
          this.$refs.planifier.validerOk()
          this.$emit('changer-date', { newsId: this.newsId, dateEnvoie: this.dateEnvoie, etat: reponse.data.etatNews })
          this.$store.commit('afficherNotification', { affiche: true, texte: 'La planification d\'envoie a été modifiée !', type: 'ok' })
        } else if (reponse.data.etat === -1) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur lors de la planification !', type: 'erreur' })
        } else if (reponse.data.etat === -2) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de modification !', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    supprimerPlanification: function () {
      this.dateEnvoie = null
      this.dateEnvoieHtml = ''
      this.dateEnvoieInput = ''
      this.validerPlanification()
    }
  }
}
</script>

<style scoped>
.selectCampagne {
  border: none;
  background: transparent;
}
.selectCampagne:focus, .selectCampagne:active {
  border: none;
  background: transparent;
  box-shadow: none;
}
/*Sans Logo*/
.afficheLogo .avatar {
  height: auto !important;
  font-size: 1.2rem !important;
  width: 4rem !important;
  font-weight: bold;
}

/*Avec Logo*/
.afficheLogo .avatar img {
  width: auto !important;
}
.entete {
  min-height: 7rem;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
  align-content: center;
  justify-content: center;
}
.imageSection {
  min-height: 8rem;
}
.textarea.is-transparent {
  background-color: rgba(255, 255, 255, 0.6);
}
textarea.is-static {
  font-weight: bold;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 0;
  height: auto;
  resize: none!important;
}
</style>
