<template>
  <div v-for="page in listePage" :key="page" :class="c">
    <div class="container">
      <h4 class="subtitle has-text-centered"><b>Nombre de clic sur les boutons de la page :<br>{{ page.page }}</b></h4>
      <div v-for="bouton in page.boutons" :key="bouton" class="columns is-centered is-vcentered">
        <div class="column is-9">
          <h1 class="subtitle">{{ bouton.nom }}</h1>
        </div>
        <div class="column">
          <h1 class="subtitle nombre">
            <Compteur :end="bouton.nbr" thousand=" "/>
          </h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import axios from 'axios'
import Compteur from '@/components/Options/Compteur.vue'

export default {
  name: 'Bouton',
  props: ['projet', 'c'],
  components: {
    Compteur
  },
  data () {
    return {
      listePage: []
    }
  },
  created: function () {
    axios.get(this.$store.getters.url + 'back/Statistiques/ChargerBouton.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.listePage = reponse.data.donnees
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de lecture sur cette onglets.', type: 'attention' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  methods: {
  }
}
</script>

<style scoped>
@media (prefers-color-scheme: light) {
  .unSeul>div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .nombre div {
    color:#47479F!important;
  }
}

@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .nombre div {
    color:#01BBC8!important;
  }
}

.unSeul > .container {
  border-radius: 6px;
  position: relative;
  padding: 1rem 1rem;
  height: 100%;
}
.nombre div {
  font-weight: bold;
}
</style>
