<template>
  <div class="option">
    <span
      v-if="etat === 1"
      @click="ligner"
      class="icon is-medium has-text-primary fauxLien"
      title="Quadrillage">
      <i class="fad fa-grip-vertical"></i>
    </span>
    <span
      v-else
      @click="quadrillager"
      class="icon is-medium has-text-primary fauxLien"
      title="Ligne par ligne">
      <i class="fad fa-grip-lines"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Quadrillage',
  emits: ['ligne', 'quadrillage'],
  data () {
    return {
      etat: 1
    }
  },
  methods: {
    ligner: function () {
      this.$emit('ligne')
      this.etat = 0
    },
    quadrillager: function () {
      this.$emit('quadrillage')
      this.etat = 1
    }
  }
}
</script>
