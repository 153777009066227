<template>
  <div class="tile is-child box">
    <h1 class="subtitle has-text-centered">Vos appareils autorisés</h1>
    <div class="columns scrollable" id="scrollable">
      <div class="column is-7-tablet is-5-widescreen" v-for="appareil in appareilsOk" :key="appareil">
        <div class="card">
          <div class="card-image">
            <figure class="image" :style="logoPlateforme(appareil.idPlateforme)"></figure>
          </div>
          <div class="card-content">
            <div class="content has-text-centered">
              <p>
                <span class="icon is-medium">
                  <img :src="logoNavigateur(appareil.idNavigateur)" alt="navigateur">
                </span>
                {{ appareil.navigateur }} <br> v{{ appareil.version.split('.')[0] }}
              </p>
              <div>
                <span v-if="appareil.pos === 1" class='icon is-small' title="Appareil actuel">
                  <i class='fad fa-diamond'></i>
                </span>
                <br v-else style="height: 1rem;">
                <p>{{ appareil.date }}</p>
              </div>
            </div>
          </div>
          <footer v-if="!appareil.bloquer" class="card-footer bloquerDemande">
            <span class="card-footer-item fauxLien" @click="afficherBloque(appareil.id)">Bloquer</span>
          </footer>
          <footer v-else class="card-footer bloquerValidation">
            <span class="card-footer-item fauxLien">
              <span @click="validerBloque(appareil.id)" class="icon has-text-success" style="font-size: 1em;">
                <i class="fad fa-check"></i>
              </span>
            </span>
            <span class="card-footer-item fauxLien bloquerAnnuler">
              <span @click="annulerBloque(appareil.id)" class="icon">
                <span class="icon has-text-danger" style="font-size: 1em;">
                  <i class="fad fa-times"></i>
                </span>
              </span>
            </span>
          </footer>
        </div>
      </div>
    </div>
  </div>
  <div class="tile is-child box">
    <h1 class="subtitle has-text-centered">Vos appareils bloqués</h1>
    <div v-if="appareilsNok.length > 0" class="columns scrollable">
      <div class="column is-7-tablet is-5-widescreen" v-for="appareil in appareilsNok" :key="appareil">
        <div class="card">
          <div class="card-image">
            <figure class="image" :style="logoPlateforme(appareil.idPlateforme)"></figure>
          </div>
          <div class="card-content">
            <div class="content has-text-centered">
              <p>
                <span class="icon is-medium">
                  <img :src="logoNavigateur(appareil.idNavigateur)" alt="navigateur">
                </span>
                {{ appareil.navigateur }} <br> v{{ appareil.version.split('.')[0] }}
              </p>
              <div>
                <p>{{ appareil.date }}</p>
              </div>
            </div>
          </div>
          <footer v-if="!appareil.bloquer" class="card-footer bloquerDemande">
            <span class="card-footer-item fauxLien" @click="afficherDebloque(appareil.id)">Débloquer</span>
          </footer>
          <footer v-else class="card-footer bloquerValidation">
            <span class="card-footer-item fauxLien">
              <span @click="validerDebloque(appareil.id)" class="icon has-text-success" style="font-size: 1em;">
                <i class="fad fa-check"></i>
              </span>
            </span>
            <span class="card-footer-item fauxLien bloquerAnnuler">
              <span @click="annulerDebloque(appareil.id)" class="icon">
                <span class="icon has-text-danger" style="font-size: 1em;">
                  <i class="fad fa-times"></i>
                </span>
              </span>
            </span>
          </footer>
        </div>
      </div>
    </div>
    <h3 v-else class="has-text-centered">Aucun appareil</h3>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Appareil',
  data () {
    return {
      appareilsOk: [],
      appareilsNok: []
    }
  },
  created: function () {
    this.$store.commit('chargement')
    axios.get(this.$store.getters.url + 'back/Profil/Appareils.php?i=' + this.$store.getters.numMembre).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.appareilsOk = reponse.data.appareilsOk
        this.appareilsNok = reponse.data.appareilsNok
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  mounted: function () {
    const s = document.querySelector('.scrollable')
    s.addEventListener('wheel', e => {
      const scroll = e.deltaY - e.wheelDeltaY
      s.scrollLeft += scroll
    })
  },
  methods: {
    logoPlateforme: function (id) {
      return 'background-image: url(\'images/platforme/' + id + '.png\')'
    },
    logoNavigateur: function (id) {
      return 'images/navigateur/' + id + '.png'
    },
    afficherBloque: function (item) {
      for (var i = 0; i < this.appareilsOk.length; i++) {
        if (this.appareilsOk[i].id === item) {
          this.appareilsOk[i].bloquer = true
          if (i === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Si vous bloquez votre appareil actuel, vous serez déconnectez !', type: 'attention' })
          }
        }
      }
    },
    annulerBloque: function (item) {
      for (var i = 0; i < this.appareilsOk.length; i++) {
        if (this.appareilsOk[i].id === item) {
          this.appareilsOk[i].bloquer = false
        }
      }
    },
    validerBloque: function (item) {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Profil/BloquerAppareil.php?i=' + this.$store.getters.numMembre, {
        id: item
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          let appareil = []
          for (var i = 0; i < this.appareilsOk.length; i++) {
            if (this.appareilsOk[i].id === item) {
              this.appareilsOk[i].bloquer = false
              appareil = this.appareilsOk[i]
              this.appareilsOk.splice(i, 1)
              if (i === 0) {
                this.$store.commit('deconnexion')
              }
            }
          }
          this.appareilsNok.push(appareil)
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Appareil bloqué avec succès', type: 'ok' })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Un problème est survenu', type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    },
    afficherDebloque: function (item) {
      for (var i = 0; i < this.appareilsNok.length; i++) {
        if (this.appareilsNok[i].id === item) {
          this.appareilsNok[i].bloquer = true
        }
      }
    },
    annulerDebloque: function (item) {
      for (var i = 0; i < this.appareilsNok.length; i++) {
        if (this.appareilsNok[i].id === item) {
          this.appareilsNok[i].bloquer = false
        }
      }
    },
    validerDebloque: function (item) {
      this.$store.commit('chargement')
      axios.post(this.$store.getters.url + 'back/Profil/DebloquerAppareil.php?i=' + this.$store.getters.numMembre, {
        id: item
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          let appareil = []
          for (var i = 0; i < this.appareilsNok.length; i++) {
            if (this.appareilsNok[i].id === item) {
              this.appareilsNok[i].bloquer = false
              appareil = this.appareilsNok[i]
              this.appareilsNok.splice(i, 1)
            }
          }
          this.appareilsOk.push(appareil)
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Appareil débloqué avec succès', type: 'ok' })
        } else if (reponse.data.etat === 0) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Un problème est survenu', type: 'erreur' })
        } else {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
        }
      }).catch((error) => {
        console.log(error)
      }).then(() => {
        this.$store.commit('finChargement')
      })
    }
  }
}
</script>

<style scoped>
.scrollable .image {
  width: 90%;
  height: 7vh;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  margin-top: 0.5rem;
}
.scrollable .card-content {
  padding: 0.5rem!important;
}
.scrollable .card-content .icon.is-medium img {
  height: 1.5rem!important;
  width: 1.5rem!important;
}
.bloquerValidation,
.profilValider {
  display: none;
}
.bloquerValidation[style*="block"],
.bloquerDemande[style*="block"] {
  display: flex!important;
}
</style>
