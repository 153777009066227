<template>
  <div class="option">
    <span
      v-if="parseInt(etatDebut) === 1"
      @click="bloquer"
      class="icon is-medium has-text-primary fauxLien has-tooltip-right"
      title="Verrouiller le groupe">
      <i class="fad fa-unlock"></i>
    </span>
    <span
      v-else
      @click="debloquer"
      class="icon is-medium has-text-primary fauxLien has-tooltip-right"
      title="Déverrouiller le groupe">
      <i class="fad fa-lock"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Bloquer',
  emits: ['bloquer', 'debloquer'],
  props: ['etatDebut'],
  methods: {
    bloquer: function () {
      this.$emit('bloquer')
    },
    debloquer: function () {
      this.$emit('debloquer')
    }
  }
}
</script>
