<template>
  <h1 class="title has-text-centered">BIENVENUE SUR L'ESPACE ADMINISTRATION DE DREAM WEB</h1>
  <br>
  <form autocomplete="on">
    <div class="field">
      <div class="control has-icons-left">
        <input class="input" type="email" placeholder="Identifiant" autocomplete="username" autofocus v-model="email">
        <span class="icon is-small is-left">
          <i class="fas fa-user"></i>
        </span>
      </div>
    </div>
    <div class="field">
      <div class="control has-icons-left">
        <input class="input" type="password" placeholder="Mot de passe" autocomplete="current-password" v-model="mdp">
        <span class="icon is-small is-left">
          <i class="fas fa-key"></i>
        </span>
      </div>
      <p @click="changerEtape('mdpoublie')" class="help has-text-right fauxLien mdpOublie"><b>Mot de passe oublié ?</b></p>
    </div>
    <div class="field has-text-centered" style="margin-bottom: 3vh;">
      <button @click="connexion" class="button seconnecter is-info">Se connecter</button>
    </div>
  </form>
</template>

<script>
import axios from 'axios'
export default {
  name: 'FormulaireConnexion',
  data () {
    return {
      email: '',
      mdp: ''
    }
  },
  emits: ['changerEtape'],
  beforeCreate: function () {
    if (window.localStorage.getItem('n') !== null) {
      this.$store.commit('chargement')
      this.$store.commit('afficherNotification', { affiche: true, texte: 'Reconnexion en cours...', type: 'info' })
      axios.post(this.$store.getters.url + 'back/Reconnexion.php', {
        id: window.localStorage.getItem('n')
      }).then((reponse) => {
        if (reponse.data.etat === 1) {
          this.$store.commit('afficherNotification', { affiche: true, texte: 'Bienvenue ' + reponse.data.informations.prenom, type: 'ok' })
          this.$store.commit('connexion', reponse.data)
        } else {
          if (reponse.data.appareil !== 1) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Votre appareil est bloqué !', type: 'attention' })
          }
        }
      }).catch((error) => {
        console.log(error)
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Le serveur n\'a pas pu donné suite à votre requête, veuillez recommencer ultérieurement.', type: 'erreur' })
      }).then(() => {
        this.$store.commit('finChargement')
      })
    }
  },
  methods: {
    changerEtape: function () {
      this.$emit('changerEtape', 'mdpoublie')
    },
    verifierInput: function () {
      if (this.email.length > 0 && this.mdp.length > 0) {
        return true
      } else {
        return false
      }
    },
    connexion: function (e) {
      e.preventDefault()
      if (this.verifierInput()) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Connexion.php', {
          email: this.email,
          mdp: this.mdp
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Bienvenue ' + reponse.data.informations.prenom, type: 'ok' })
            this.$store.commit('connexion', reponse.data)
          } else {
            if (reponse.data.appareil === 1) {
              this.$store.commit('afficherNotification', { affiche: true, texte: 'Les informations fournies ne correpondent pas !', type: 'erreur' })
            } else {
              this.$store.commit('afficherNotification', { affiche: true, texte: 'Votre appareil est bloqué !', type: 'attention' })
            }
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Veuillez remplir le formulaire !', type: 'attention' })
      }
    }
  }
}
</script>

<style scoped>
@media (prefers-color-scheme: dark) {
  .control.has-icons-left .icon > svg > path {
    color: #363636;
  }
}
</style>
