<template>
  <div class="field">
    <div class="control">
      <input @change="changeText($event)" :id="switchId" type="checkbox" class="switch is-rounded is-outlined" :checked='valeur'>
      <label :for="switchId"> {{ text }}</label>
    </div>
  </div>
</template>

<script>
import 'bulma-switch/dist/css/bulma-switch.min.css'

export default {
  name: 'Switch',
  props: ['html', 'etat', 'switchId'],
  data () {
    return {
      text: this.html[this.etat],
      valeur: this.etat
    }
  },
  methods: {
    changeText: function (e) {
      if (e.target.checked) {
        this.text = this.html[1]
        this.valeur = 1
      } else {
        this.text = this.html[0]
        this.valeur = 0
      }
    },
    getValeur: function () {
      return this.valeur
    }
  }
}
</script>
