<template>
  <section v-show="affiche === 'liste'">
    <article>
      <h1 class="title has-text-centered">Liste Newsletters</h1>
    </article>
    <div class="boutonOptionDM">
      <div class="option" style="padding: 0.06rem 0.3rem;">
        <div class="select is-normal">
          <select v-model="campagne" class="selectCampagne">
            <option v-for="campagne in listeCampagne" :key="campagne" :value="campagne.id"> {{ campagne.nom }} </option>
          </select>
        </div>
      </div>
      <div class="option" v-if="$store.getters.droitOnglet(projet, 4) === 2">
        <span title="Gestion" class="icon is-medium has-text-primary fauxLien" @click="affiche = 'gestion'">
          <i class="fad fa-cogs"></i>
        </span>
      </div>
    </div>
    <div class="boutonOptionGM" v-if="$store.getters.droitOnglet(projet, 4) === 2">
      <div class="option">
        <span title="Nouveau" class="icon is-medium has-text-primary fauxLien" @click="aSelectionner({ id: 0 })">
          <i class="fad fa-plus"></i>
        </span>
      </div>
      <Supprimer @supprimer="supprimer" @annulerSuppression="annulerSuppression" @validerSuppression="validerSuppression" ref="supprimer"/>
    </div>
    <!-- Liste -->
    <article class="tile is-ancestor">
      <div class="tile is-12 is-vertical is-parent">
        <div class="tile is-child box unSeul">
          <div class="columns is-paddingless is-marginless">
            <div class="column is-paddingless is-marginless is-5">
              <b>Titre</b>
            </div>
            <div class="column has-text-centered is-paddingless is-marginless is-2">
              <b>Date de création</b>
            </div>
            <div class="column has-text-centered is-paddingless is-marginless is-2">
              <b>Date d'envoie</b>
            </div>
            <div class="column has-text-centered is-paddingless is-marginless">
              <b>Etat</b>
            </div>
          </div>
        </div>
        <div
        v-for="news in newsletters"
        :key="news"
        :aSupprimer="news.aSupprimer"
        :aSelectionner="news.aSelectionner"
        @click="aSelectionner(news)"
        class="tile is-child box unSeul news"
        v-show="news.campagne === campagne">
          <div class="columns">
            <div class="column is-5">
              <b>{{ news.titre === null || news.titre === '' ? 'Sans titre' : news.titre }}</b>
            </div>
            <div class="column has-text-centered is-2">
              <b>{{ news.dateCreation }}</b>
            </div>
            <div class="column has-text-centered is-2">
              <b v-if="news.dateEnvoie !== null">{{ news.dateEnvoie }}</b>
              <b v-else>Non programmé</b>
            </div>
            <div class="column has-text-centered">
              <b v-if="news.etat===1">En écriture</b>
              <b v-if="news.etat===2">Programmé</b>
              <b v-if="news.etat===3">Terminé</b>
            </div>
          </div>
        </div>
        <div v-if="!nbrNews()"><h1 class="subtitle has-text-centered"><b>Aucune newsletter ici !</b></h1></div>
      </div>
    </article>
  </section>
  <Creation v-if="affiche === 'creation'" :projet="projet" :news="newsCreation" :listeCampagne="listeCampagne" :campagne="campagne" @retour-liste="retourListe" @ajout-news="ajoutNews" @changer-titre="changerTitre" @changer-date="changerDate" @changer-campagne="changerCampagne"/>
  <Gestion v-if="affiche === 'gestion'" :projet="projet" :listeCampagne="listeCampagne" :campagne="campagne" @retour-liste="retourListe" @ajout-campagne="ajoutCampagne" @supprimer-campagne="supprimerCampagne"/>
</template>

<script type="text/javascript">
import Supprimer from '@/components/Options/Supprimer.vue'
import Creation from '@/components/PartieDroite/Newsletters/Creation.vue'
import Gestion from '@/components/PartieDroite/Newsletters/Gestion.vue'
import axios from 'axios'

export default {
  name: 'Newsletters',
  props: ['projet'],
  components: {
    Supprimer,
    Creation,
    Gestion
  },
  data () {
    return {
      affiche: 'liste',
      newsCreation: 0,
      newsletters: [],
      listeCampagne: [],
      campagne: 0,
      modeSuppression: false,
      ready: false
    }
  },
  created () {
    this.$store.commit('chargement')
    axios.get(this.$store.getters.url + 'back/Newsletters/Charger.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.newsletters = reponse.data.news
        this.listeCampagne = reponse.data.campagne
        if (this.listeCampagne.length === 0) {
          this.affiche = 'gestion'
        } else {
          this.campagne = this.listeCampagne[0].id
        }
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
      this.nbrNews()
      this.ready = true
    })
  },
  watch: {
    campagne (n, a) {
      if (n !== a && this.ready) {
        this.nbrNews()
      }
    }
  },
  methods: {
    // Supprimer
    supprimer: function () {
      this.modeSuppression = true
      for (var i = 0; i < this.newsletters.length; i++) {
        this.newsletters[i].aSupprimer = true
      }
    },
    annulerSuppression: function () {
      this.modeSuppression = false
      for (var i = 0; i < this.newsletters.length; i++) {
        this.newsletters[i].aSupprimer = false
        this.newsletters[i].aSelectionner = false
      }
    },
    aSelectionner: function (news) {
      if (this.modeSuppression) {
        if (news.aSupprimer) {
          news.aSelectionner = !news.aSelectionner
        }
      } else {
        this.newsCreation = news.id
        this.affiche = 'creation'
      }
    },
    validerSuppression: function () {
      const liste = []
      for (var i = 0; i < this.newsletters.length; i++) {
        if (this.newsletters[i].aSelectionner) {
          liste.push(this.newsletters[i].id)
        }
      }
      if (liste.length > 0) {
        this.$store.commit('chargement')
        axios.post(this.$store.getters.url + 'back/Newsletters/SupprimerNews.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet + '&c=' + this.campagne, {
          liste: liste
        }).then((reponse) => {
          const newslettersTemp = []
          if (reponse.data.etat === 1) {
            for (var i = 0; i < this.newsletters.length; i++) {
              if (liste.indexOf(this.newsletters[i].id) === -1) {
                newslettersTemp.push(this.newsletters[i])
              }
            }
            this.newsletters = newslettersTemp
            this.$refs.supprimer.validerOk()
            this.annulerSuppression()
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Newsletters supprimée(s) avec succès !', type: 'ok' })
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien à supprimer !', type: 'erreur' })
          } else if (reponse.data.etat === -1) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          } else if (reponse.data.etat === -2) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de suppression !', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Rien  supprimer !', type: 'info' })
      }
    },
    ajouter: function () {
      this.newsCreation = 0
      this.affiche = 'creation'
    },
    retourListe: function () {
      this.newsCreation = 0
      this.affiche = 'liste'
    },
    ajoutCampagne: function (campagne) {
      this.listeCampagne.push(campagne)
      this.campagne = campagne.id
    },
    supprimerCampagne: function (campagne) {
      const nonSupprimer = []
      for (var i = 0; i < this.listeCampagne.length; i++) {
        if (this.listeCampagne[i].id !== campagne) {
          nonSupprimer.push(this.listeCampagne[i])
        }
      }
      if (nonSupprimer.length > 0) {
        this.campagne = nonSupprimer[0].id
      } else {
        this.campagne = 0
      }
      this.listeCampagne = nonSupprimer
    },
    nbrNews: function () {
      let nbrNews = 0
      for (var i = 0; i < this.newsletters.length; i++) {
        if (this.newsletters[i].campagne === this.campagne) {
          nbrNews++
        }
      }
      return nbrNews > 0
    },
    ajoutNews: function (news) {
      this.newsletters.push(news)
    },
    changerTitre: function (info) {
      this.newsletters.filter(e => {
        if (e.id === info.newsId) {
          e.titre = info.titre
        }
      })
    },
    changerDate: function (info) {
      this.newsletters.filter(e => {
        if (e.id === info.newsId) {
          e.dateEnvoie = info.dateEnvoie
          e.etat = info.etat
        }
      })
    },
    changerCampagne: function (info) {
      this.newsletters.filter(e => {
        if (e.id === info.newsId) {
          e.campagne = info.campagne
        }
      })
    }
  }
}
</script>

<style scoped>
.news {
  cursor: pointer;
}
.selectCampagne {
  border: none;
  background: transparent;
}
.selectCampagne:focus, .selectCampagne:active {
  border: none;
  background: transparent;
  box-shadow: none;
}
</style>
