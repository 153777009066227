<template>
  <div class="avatar" :style="back()">
    <span v-if="avatar === null" class="avatarInitial">{{ getInitial() }}</span>
    <img v-else :src="avatar" :class="b"/>
  </div>
</template>

<script>
export default {
  name: 'Avatar',
  props: ['avatar', 'initial', 's'],
  data () {
    return {
      colorFont: [
        '#F44336', '#FF4081', '#9C27B0', '#673AB7',
        '#3F51B5', '#2196F3', '#03A9F4', '#00BCD4', '#009688',
        '#4CAF50', '#8BC34A', '#CDDC39', '#FFC107',
        '#FF9800', '#FF5722', '#795548', '#9E9E9E', '#607D8B'],
      b: 'cover'
    }
  },
  created () {
    if (this.s === 'contain') {
      this.b = 'contain'
    }
  },
  methods: {
    back: function () {
      let fond = ''
      if (this.avatar === null) {
        let background = this.randomBackgroundColor()
        if (!window.matchMedia('(prefers-color-scheme: dark)').matches) {
          background = this.lightenColor(background, 80)
        }
        fond = 'background-color:' + background
      }
      return fond
    },
    getInitial: function () {
      const parts = this.initial.split(/[ -]/)
      let initials = ''
      for (var i = 0; i < parts.length; i++) {
        initials += parts[i].charAt(0)
      }
      if (initials.length > 3 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '')
      }
      initials = initials.substr(0, 2).toUpperCase()
      return initials
    },
    randomBackgroundColor: function () {
      return this.colorFont[this.initial.length % (this.colorFont.length)]
    },
    lightenColor (hex, amt) {
      // From https://css-tricks.com/snippets/javascript/lighten-darken-color/
      var usePound = false
      if (hex[0] === '#') {
        hex = hex.slice(1)
        usePound = true
      }
      var num = parseInt(hex, 16)
      var r = (num >> 16) + amt
      if (r > 255) r = 255
      else if (r < 0) r = 0
      var b = ((num >> 8) & 0x00FF) + amt
      if (b > 255) b = 255
      else if (b < 0) b = 0
      var g = (num & 0x0000FF) + amt
      if (g > 255) g = 255
      else if (g < 0) g = 0
      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    }
  }
}
</script>

<style type="text/css">
.avatar {
  height: 100%;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  font-size: 9rem;
}
img.cover {
  height: 100%!important;
  width: 100%!important;
  border-radius: 6px;
  object-fit: cover;
}
img.contain {
  height: 100%!important;
  width: 100%!important;
  border-radius: 6px;
  object-fit: contain;
}
.avatarInitial {
  margin: 0.1rem 0.1rem;
}
</style>
