<template>
  <div class="field is-grouped is-grouped-multiline">
    <div
    v-for="tag in tagsListe"
    :key="tag"
    class="control"
    >
      <div class="tags has-addons">
        <span class="tag is-dark">{{ tag.competence }}</span>
        <a class="tag is-delete" @click="supprimer(tag.id, 'profil')"></a>
      </div>
    </div>
    <div
    v-for="tag in tagsAjouter"
    :key="tag"
    class="control"
    >
      <div class="tags has-addons">
        <span class="tag is-dark">{{ tag }}</span>
        <a class="tag is-delete" @click="supprimer(tag, 'nouveau')"></a>
      </div>
    </div>
  </div>
  <div class="field">
    <div class="control">
      <input type="text" class="input" placeholder="Ajouter une compétence" @keyup.enter="ajouter" v-model="nouveau">
    </div>
  </div>
</template>

<script>
export default {
  name: 'TagsInput',
  props: ['tags'],
  data () {
    return {
      tagsListe: [],
      tagsSupprimer: [], // Liste des tags déjà présent lors du chargement du compte
      tagsAjouter: [],
      nouveau: ''
    }
  },
  mounted: function () {
    for (var i = 0; i < this.tags.length; i++) {
      this.tagsListe.push(this.tags[i])
    }
  },
  methods: {
    ajouter: function () {
      this.tagsAjouter.push(this.nouveau)
      this.nouveau = ''
    },
    supprimer: function (item, type) {
      if (type === 'nouveau') {
        this.tagsAjouter.splice(this.tagsAjouter.indexOf(item), 1)
      } else {
        for (var i = 0; i < this.tagsListe.length; i++) {
          if (this.tagsListe[i].id === item) {
            this.tagsSupprimer.push(item)
            this.tagsListe.splice(i, 1)
            break
          }
        }
      }
    },
    getTagsSupprimer: function () {
      return this.tagsSupprimer
    },
    getTagsAjouter: function () {
      return this.tagsAjouter
    }
  }
}
</script>
