<template>
  <section class="cgu content">
    <article>
      <h1 class="title has-text-centered">CONDITIONS G&Eacute;N&Eacute;RALES D'UTILISATION DU SITE</h1>
      <h3 class="subtitle has-text-centered">Administration de Dream Web</h3>
    </article>
    <article class="has-text-centered">
      <img src="images/site/logo.svg">
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 1. INFORMATIONS L&Eacute;GALES</h3>
      <p>En vertu de l'article 6 de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé dans cet article l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>
      <p>Le site d'administration Dream Web est édité par :</p>
      <p class="spe">Dream Web, dont le siège social est situé à l'adresse suivante : 67000 Strasbourg.</p>
      <p class="spe">Adresse e-mail : <a class="fauxLien" href="mailto:contact@dream-web-agency.com"> contact@dream-web-agency.com</a></p><br>
      <p>Les directeurs de publicationdfgdfgrdg du site sont : M. AMMOUR Florent & M. SCHAUER Anthony.</p><br>
      <p>Le site Dream Web est hébergé par :</p>
      <p class="spe">Hostinger, donthg,futfuktfutfuktf le siège est situé à l'adresse ci-après :</p>
      <p class="spe_double">Hostinger International Ltd. 61 Lordou Vironos Street 6023 Larnaca, Chypre</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 2. PRÉSENTATION DU SITE</h3>
      <p>Le site d'administration d'Dream Web a pour objet :</p>
      <ul>
        <li>Gestion des équipes travaillant pour Dream Web ;</li>
        <li>Gestion des projets Dream Web ;</li>
        <li>Centralisation des services et logiciels Dream Web.</li>
      </ul>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 3. CONTACT</h3>
      <p>Pour toute question ou demande d'information concernant le site, ou tout signalement de contenu ou d'activités illicites, l'utilisateur peut contacter l'éditeur à l'adresse e-mail suivante: <a class="fauxLien" href="mailto:contact@dream-web-agency.com">contact@dream-web-agency.com</a>.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 4. ACCEPTATION DES CONDITIONS D'UTILISATION</h3>
      <p>L'accès et l'utilisation du site sont soumis à l'acceptation et au respect des présentes Conditions Générales d'Utilisation.</p>
      <p>L'éditeur se réserve le droit de modifier, à tout moment et sans préavis, le site et des services ainsi que les présentes CGU, notamment pour s'adapter aux évolutions du site par la mise à disposition de nouvelles fonctionnalités ou la suppression ou la modification de fonctionnalités existantes.</p>
      <p>Il est donc conseillé à l'utilisateur de se référer avant toute navigation à la dernière version des CGU, accessible à tout moment sur le site. En cas de désaccord avec les CGU, aucun usage du site ne saurait être effectué par l'utilisateur.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 5. ACCÈS ET NAVIGATION</h3>
      <p>L'éditeur met en œuvre les solutions techniques à sa disposition pour permettre l'accès au site 24 heures sur 24, 7 jours sur 7. Il pourra néanmoins à tout moment suspendre, limiter ou interrompre l'accès au site ou à certaines pages de celui-ci afin de procéder à des mises à jours, des modifications de son contenu ou tout autre action jugée nécessaire au bon fonctionnement du site.</p>
      <p>La connexion et la navigation sur le site de Dream Web valent acceptation sans réserve des présentes Conditions Générales d'Utilisation, quelques soient les moyens techniques d'accès et les terminaux utilisés.</p>
      <p>Les présentes CGU s'appliquent, en tant que de besoin, à toute déclinaison ou extension du site sur les réseaux sociaux et/ou communautaires existants ou à venir.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 6. GESTION DU SITE</h3>
      <p>Pour la bonne gestion du site, l'éditeur pourra à tout moment :</p>
      <ul>
        <li>Suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internaute ;</li>
        <li>Supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales, ou avec les règles de la Nétiquette ;</li>
        <li>Suspendre le site afin de procéder à des mises à jour.</li>
      </ul>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 7. SERVICES RÉSERVÉS AUX UTILISATEURS INSCRITS</h3>
      <p class="subtitle">1. INSCRIPTION</p>
        <p>L'accès à ce service est possible qu’avec délivrance d’un compte utilisateur par l’éditeur</p>
        <p>L'accès aux services du site sont réservés exclusivement aux personnes physiques capables, ayant reçus un compte utilisateur pour l’utilisation du site administration d’Dream Web. Et qui ont acceptés les présentes Conditions Générales d'Utilisation.</p>
        <p>L'utilisateur devra en outre procéder à une vérification régulière des données le concernant afin d'en conserver l'exactitude</p>
        <p>L'utilisateur doit ainsi fournir impérativement une adresse e-mail valide, sur laquelle le site lui adressera une confirmation de son inscription à ses services. Une adresse de messagerie électronique ne peut être utilisée plusieurs fois pour s'inscrire aux services.</p>
        <p>Toute communication réalisée par Dream Web et ses partenaires est en conséquence réputée avoir été réceptionnée et lue par l'utilisateur. Ce dernier s'engage donc à consulter régulièrement les messages reçus sur cette adresse e-mail et à répondre dans un délai raisonnable si cela est nécessaire.</p>
        <p>Une seule inscription aux services du site est admise par personne physique.</p>
        <p>L'utilisateur se voit attribuer un identifiant lui permettant d'accéder à un espace dont l'accès lui est réservé (ci-après "Espace personnel"), en complément de la saisie de son mot de passe.</p>
        <p>L'identifiant et le mot de passe sont modifiables en ligne par l'utilisateur dans son Espace personnel. Le mot de passe est personnel et confidentiel, l'utilisateur s'engage ainsi à ne pas le communiquer à des tiers.</p>
        <p>Dream Web se réserve en tout état de cause la possibilité de refuser une demande d'inscription aux services en cas de non-respect par l'utilisateur des dispositions des présentes Conditions Générales d'Utilisation.</p>
      <p class="subtitle">2. DÉSINSCRIPTION</p>
        <p>L'utilisateur régulièrement inscrit pourra à tout moment demander sa désinscription en se rendant sur la page dédiée dans son Espace personnel. Toute désinscription du site sera effective immédiatement après que l'utilisateur ait rempli le formulaire prévu à cet effet.</p>
      <p class="subtitle">3. SUPPRESSION DE L'ESPACE PERSONNEL À L'INITIATIVE DE L'ÉDITEUR DU SITE</p>
        <p>Il est porté à la connaissance de l'utilisateur que l'éditeur se réserve le droit de supprimer l'espace personnel de tout Utilisateur qui contreviendrait aux présentes conditions d'utilisation et de vente, et plus particulièrement dans les cas suivants :</p>
        <ul>
          <li>Si l'utilisateur fait une utilisation illicite du site ;</li>
          <li>Si l'utilisateur, lors de la création de son espace personnel, transmet volontairement des informations erronées au site ;</li>
          <li>Si l'utilisateur n'a pas été actif sur son espace personnel depuis au moins un an.</li>
        </ul>
        <p>Dans le cas où l'éditeur déciderait de supprimer l'espace personnel de l'utilisateur pour l'une de ces raisons, celle-ci ne saurait constituer un dommage pour l'utilisateur dont le compte a été supprimé.</p>
        <p>Cette suppression ne saurait constituer une renonciation aux poursuites judiciaires que l'éditeur pourrait entreprendre à l'égard de l'utilisateur étant contrevenu à ces règles.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 8. RESPONSABILITÉS</h3>
      <p>L'éditeur n'est responsable que du contenu qu'il a lui-même édité.</p>
      <p>L'éditeur n'est pas responsable :</p>
      <ul>
        <li>En cas de problématiques ou défaillances techniques, informatiques ou de compatibilité du site avec un matériel ou logiciel quel qu'il soit ;</li>
        <li>Des dommages directs ou indirects, matériels ou immatériels, prévisibles ou imprévisibles résultant de l'utilisation ou des difficultés d'utilisation du site ou de ses services ;</li>
        <li>Des caractéristiques intrinsèques de l'Internet, notamment celles relatives au manque de fiabilité et au défaut de sécurisation des informations y circulant ;</li>
        <li>Des contenus ou activités illicites utilisant son site et ce, sans qu'il en ait pris dûment connaissance au sens de la Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique et la Loi n°2004-801 du 6 août 2004 relative à la protection des personnes physiques à l'égard de traitement de données à caractère personnel.</li>
      </ul>
      <p>Par ailleurs, le site ne saurait garantir l'exactitude, la complétude, et l'actualité des informations qui y sont diffusées.</p>
      <p>L'utilisateur est responsable :</p>
      <ul>
        <li>De la protection de son matériel et de ses données ;</li>
        <li>De l'utilisation qu'il fait du site ou de ses services ;</li>
        <li>S'il ne respecte ni la lettre, ni l'esprit des présentes CGU.</li>
      </ul>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 9. LIENS HYPERTEXTES</h3>
      <p>Le site peut contenir des liens hypertextes pointant vers d'autres sites internet sur lesquels Dream Web n'exerce pas de contrôle. Malgré les vérifications préalables et régulières réalisés par l'éditeur, celui-ci décline tout responsabilité quant aux contenus qu'il est possible de trouver sur ces sites.</p>
      <p>L'éditeur autorise la mise en place de liens hypertextes vers toute page ou document de son site sous réserve que la mise en place de ces liens ne soit pas réalisée à des fins commerciales ou publicitaires.</p>
      <p>En outre, l'information préalable de l'éditeur du site est nécessaire avant toute mise en place de lien hypertexte.</p>
      <p>Sont exclus de cette autorisation les sites diffusant des informations à caractère illicite, violent, polémique, pornographique, xénophobe ou pouvant porter atteinte à la sensibilité du plus grand nombre.</p>
      <p>Enfin, Dream Web se réserve le droit de faire supprimer à tout moment un lien hypertexte pointant vers son site, si le site l'estime non conforme à sa politique éditoriale.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 10. CONFIDENTIALITÉ</h3>
      <p class="subtitle">1. DONNEES COLLECTEES ET TRAITEES, ET MODE DE COLLECTION DES DONNEES</p>
        <p>Conformément aux dispositions de l'article 5 du Règlement européen 2016/679, la collecte et le traitement des données des utilisateurs du site respectent les principes suivants :</p>
        <ul>
          <li>Licéité, loyauté et transparence : les données ne peuvent être collectées et traitées qu'avec le consentement de l'utilisateur propriétaire des données. A chaque fois que des données à caractère personnel seront collectées, il sera indiqué à l'utilisateur que ses données sont collectées, et pour quelles raisons ses données sont collectées ;</li>
          <li>Finalités limitées : la collecte et le traitement des données sont exécutés pour répondre à un ou plusieurs objectifs déterminés dans les présentes conditions générales d'utilisation ;</li>
          <li>Minimisation de la collecte et du traitement des données : seules les données nécessaires à la bonne exécution des objectifs poursuivis par le site sont collectées ;</li>
          <li>Conservation des données réduites dans le temps : les données sont conservées pour une durée limitée, dont l'utilisateur est informé. Si la durée de conservation ne peut être communiquée à l'utilisateur ;</li>
          <li>Intégrité et confidentialité des données collectées et traitées : le responsable du traitement des données s'engage à garantir l'intégrité et la confidentialité des données collectées.</li>
        </ul>
        <p>Afin d'être licite, et ce conformément aux exigences de l'article 6 du règlement européen 2016/679, la collecte et le traitement des données à caractère personnel ne pourront intervenir que s'ils respectent au moins l'une des conditions ci-après énumérées :</p>
        <ul>
          <li>L'utilisateur a expressément consenti au traitement ;</li>
          <li>Le traitement est nécessaire à la bonne exécution d'un contrat ;</li>
          <li>Le traitement répond à une obligation légale ;</li>
          <li>Le traitement s'explique par une nécessité liée à la sauvegarde des intérêts vitaux de la personne concernée ou d'une autre personne physique ;</li>
          <li>Le traitement peut s'expliquer par une nécessité liée à l'exécution d'une mission d'intérêt public ou qui relève de l'exercice de l'autorité publique ;</li>
          <li>Le traitement et la collecte des données à caractère personnel sont nécessaires aux fins des intérêts légitimes et privés poursuivis par le responsable du traitement ou par un tiers.</li>
        </ul>
        <p>Les données à caractère personnel collectées sur le site Dream Web sont les suivantes :</p>
        <ul>
          <li>Adresse email</li>
          <li>Pseudo</li>
          <li>Biographie</li>
          <li>Adresse IP</li>
        </ul>
        <p>Ces données sont collectées lorsque l'utilisateur effectue l'une des opérations suivantes sur le site :</p>
        <ul>
          <li>Connexion échouée</li>
          <li>Modification du profil</li>
          <li>Contact</li>
        </ul>
        <p>Le responsable du traitement conservera dans ses systèmes informatiques du site et dans des conditions raisonnables de sécurité l'ensemble des données collectées :</p> <ul><li>pour une durée de 1 an à partir de la dernière date de connextion sur le compte utilisateur ;</li><li>immédiatement lors de la demande de suppression du compte.</li></ul>
        <p>Lorsque les données à caractère personnel sont enregistrées, l'utilisateur est informé de la durée pour laquelle ses données seront conservées, et lorsque cette durée ne peut être précisée, l'éditeur du site l'informe des critères utilisés pour la déterminer.</p>
        <p>La collecte et le traitement des données répondent aux finalités suivantes :</p>
        <p class="spe">Les données enregistrées sont essentiellement utilisées pour la personnalisation du profil. L'adresse email sert à la connexion</p>
      <p class="subtitle">2. HEBERGEMENT DES DONNÉES</p>
        <p>Tel que mentionné plus haut, le site Dream Web est hébergé par : Hostinger, dont le siège est situé à l'adresse ci-après :</p>
        <p class="spe">Hostinger International Ltd. 61 Lordou Vironos Street 6023 Larnaca, Chypre</p>
        <p>Les données collectées et traitées par le site sont transférées vers le(s) pays suivant(s) : Royaume-Uni.</p>
      <p class="subtitle">3. LE RESPONSABLE DU TRAITEMENT DES DONNÉES</p>
        <p>Le responsable du traitement des données à caractère personnel est : Anthony SCHAUER. Il peut être contacté de la manière suivante :</p>
        <p class="spe">Le responsable du traitement des données peut être contacté par email à l'adresse : <a class="fauxLien" href="mailto:anthony.schauer@dream-web-agency.com">anthony.schauer@dream-web-agency.com</a></p>
      <p class="subtitle">4. LE DÉLÉGUÉ A LA PROTECTION DES DONNÉES</p>
        <p>La personne suivante a été nommée Délégué à la Protection des Données : Florent AMMOUR.</p>
        <p>Le délégué à la protection des données peut être joint de la manière suivante :</p>
        <p class="spe">Par email : <a class="fauxLien" href="mailto:florent.ammour@dream-web-agency.com">florent.ammour@dream-web-agency.com</a></p>
      <p class="subtitle">5. DONNÉES PERSONNELLES DES PERSONNES MINEURES</p>
        <p>Conformément aux dispositions de l'article 8 du règlement européen 2016/679 et à la loi Informatique et Libertés, seuls les mineurs âgés de 15 ans ou plus peuvent consentir au traitement de leurs données personnelles.</p>
        <p>Si l'utilisateur est un mineur de moins de 15 ans, l'accord d'un représentant légal sera requis afin que des données à caractère personnel puissent être collectées et traitées.</p>
        <p>Le site administration d’Dream Web est à caractère professionnel. Les éditeurs ne fourniront pas de compte utilisateur à un mineur de moins de 15 ans.</p>
      <p class="subtitle">6. DROITS DE L'UTILISATEUR ET PROCÉDURES DE MISE EN OEUVRE DES DROITS DE L'UTILISATEUR</p>
        <p>Conformément à la réglementation concernant le traitement des données à caractère personnel, l'utilisateur possède les droits ci-après énumérés.</p>
        <p>Afin que le responsable du traitement des données fasse droit à sa demande, l'utilisateur est tenu de lui communiquer : ses prénom et nom ainsi que son adresse e-mail, et si cela est pertinent, son numéro de compte ou d'espace personnel ou d'abonné.</p>
        <p>Le responsable du traitement des données est tenu de répondre à l'utilisateur dans un délai de 30 (trente) jours maximum.</p>
        <p class="spe"><i>a. Droit d'accès, de rectification et droit à l'oubli</i></p>
        <p>L'utilisateur peut prendre connaissance, mettre à jour, modifier ou demander la suppression des données le concernant, en respectant la procédure ci-après énoncée :</p>
        <p class="spe">L'utilisateur doit envoyer un e-mail au responsable du traitement des données personnelles, en précisant l'objet de sa demande et en utilisant l'adresse e-mail de contact qui est fournie plus haut.</p>
        <p>S'il en possède un, l'utilisateur a le droit de demander la suppression de son espace personnel en suivant la procédure suivante :</p>
        <p class="spe">L'utilisateur doit envoyer un e-mail au responsable du traitement des données, en précisant son numéro d'espace personnel. La demande de suppression des données sera traitée dans un délai de 10 jours ouvrés.</p>
        <p class="spe"><i>b. Droit à la portabilité des données</i></p>
        <p>L'utilisateur a le droit de demander la portabilité de ses données personnelles, détenues par le site, vers un autre site, en se conformant à la procédure ci-après :</p>
        <p class="spe">L'utilisateur doit faire une demande de portabilité de ses données personnelles auprès du responsable du traitement des données, en envoyant un e-mail à l'adresse prévue ci-dessus.</p>
        <p class="spe"><i>c. Droit à la limitation et à l'opposition du traitement des données</i></p>
        <p>Enfin, l'utilisateur a le droit de demander la limitation ou de s'opposer au traitement de ses données par le site, sans que le site ne puisse refuser, sauf à démontrer l'existence de motifs légitimes et impérieux, pouvant prévaloir sur les intérêts et les droits et libertés de l'utilisateur.</p>
        <p class="spe"><i>d. Droit de déterminer le sort des données après la mort</i></p>
        <p>Il est rappelé à l'utilisateur qu'il peut organiser quel doit être le devenir de ses données collectées et traitées s'il décède, conformément à la loi n°2016-1321 du 7 octobre 2016.</p>
        <p class="spe"><i>e. Droit de saisir l'autorité de contrôle compétente</i></p>
        <p>Dans le cas où le responsable du traitement des données décide de ne pas répondre à la demande de l'utilisateur, et que l'utilisateur souhaite contester cette décision, il est en droit de saisir la CNIL (Commission Nationale de l'Informatique et des Libertés, <a class="fauxLien" href="https://www.cnil.fr">https://www.cnil.fr</a>) ou tout juge compétent.</p>
      <p class="subtitle">7. OBLIGATIONS DU RESPONSABLE DU TRAITEMENT DES DONNÉES</p>
          <p>Le responsable du traitement s'engage à protéger les données à caractère personnel collectées, à ne pas les transmettre à des tiers sans que l'utilisateur n'en ait été informé et à respecter les finalités pour lesquelles ces données ont été collectées.</p>
          <p>Le site dispose d'un certificat SSL afin de garantir que les informations et le transfert des données transitant par le site sont sécurisés.</p>
          <p>De plus, le responsable du traitement des données s'engage à notifier l'utilisateur en cas de rectification ou de suppression des données, à moins que cela n'entraîne pour lui des formalités, coûts et démarches disproportionnés.</p>
          <p>Dans le cas où l'intégrité, la confidentialité ou la sécurité des données à caractère personnel de l'utilisateur est compromise, le responsable du traitement s'engage à informer l'utilisateur par tout moyen.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 11. PROPRIÉTÉ INTELLECTUELLE</h3>
      <p>La structuration du site mais aussi les textes, graphiques, images, photographies, sons, vidéos et applications informatiques qui le composent sont la propriété de l'éditeur et sont protégés comme tels par les lois en vigueur au titre de la propriété intellectuelle.</p>
      <p>Toute représentation, reproduction, adaptation ou exploitation partielle ou totale des contenus, marques déposées et services proposés par le site, par quelque procédé que ce soit, sans l'autorisation préalable, expresse et écrite de l'éditeur, est strictement interdite et serait susceptible de constituer une contrefaçon au sens des articles L. 335-2 et suivants du Code de la propriété intellectuelle. Et ce, à l'exception des éléments expressément désignés comme libres de droits sur le site.</p>
      <p>L'accès au site ne vaut pas reconnaissance d'un droit et, de manière générale, ne confère aucun droit de propriété intellectuelle relatif à un élément du site, lesquels restent la propriété exclusive de l'éditeur.</p>
      <p>Il est interdit à l'utilisateur d'introduire des données sur le site qui modifieraient ou qui seraient susceptibles d'en modifier le contenu ou l'apparence.</p>
      <p>Toutes informations ou documents relatives au développement des projets ajoutés sur le site administration d’Dream Web ou sur les sites qui lui sont liés sont réputées appartenir à Dream Web sauf dérogation conventionnelle ou de la loi.</p>
    </article>
    <article>
      <h3 class="subtitle">ARTICLE 12. LOI APPLICABLE ET JURIDICTION COMPÉTENTE</h3>
      <p>Les présentes Conditions Générales d'Utilisation sont régies par la loi française. En cas de différend et à défaut d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.</p>
    </article>
  </section>
</template>

<script>
export default {
  name: 'MentionLegale'
}
</script>

<style>
.cgu article {
  margin-bottom: 2vh;
}

.cgu article img {
  height: 9vh;
}

.cgu .spe {
  text-indent: 3vw;
}

.cgu .spe_double {
  text-indent: 6vw;
}

.cgu h3.subtitle {
  font-weight: bold;
}
</style>
