<template>
  <article>
    <h1 class="title has-text-centered">Annuaire</h1>
  </article>
  <div v-if="afficheProfil">
    <VoirProfil :membre="membreAffiche" @fermer="cacherProfil" @ajouter-annuaire="ajouterAnnuaire" @supprimer-annuaire="supprimerAnnuaire" />
  </div>
  <article class="tile is-ancestor nav">
    <div class="tile is-parent is-vertical is-4">
      <div class="tile box is-child is-12">
        <p @click="changerAffiche('MG')" id="MG" class="fauxLien has-text-centered active">
          <span><b>Membres du groupe</b></span>
        </p>
      </div>
    </div>
    <div class="tile is-parent is-vertical is-4">
      <div class="tile box is-child is-12">
        <p @click="changerAffiche('AG')" id="AG" class="fauxLien has-text-centered">
          <span><b>Annuaire du groupe</b></span>
        </p>
      </div>
    </div>
    <div class="tile is-parent is-vertical is-4">
      <div class="tile box is-child is-12">
        <p @click="changerAffiche('AP')" id="AP" class="fauxLien has-text-centered">
          <span><b>Annuaire public</b></span>
        </p>
      </div>
    </div>
  </article>
  <div v-show="affiche==='MG'">
    <MembreGroupe @afficher-profil="afficherProfil" />
  </div>
  <div v-show="affiche==='AG'">
    <AnnuaireGroupe @afficher-profil="afficherProfil" ref="AG" />
  </div>
  <div v-show="affiche==='AP'">
    <AnnuairePublic @afficher-profil="afficherProfil" ref="AP" />
  </div>
</template>
<script>
import MembreGroupe from '@/components/PartieDroite/Annuaire/MembreGroupe.vue'
import AnnuairePublic from '@/components/PartieDroite/Annuaire/AnnuairePublic.vue'
import AnnuaireGroupe from '@/components/PartieDroite/Annuaire/AnnuaireGroupe.vue'
import VoirProfil from '@/components/PartieDroite/Annuaire/VoirProfil.vue'
export default {
  name: 'Annuaire',
  components: {
    MembreGroupe,
    AnnuairePublic,
    AnnuaireGroupe,
    VoirProfil
  },
  data () {
    return {
      affiche: 'MG',
      afficheProfil: false,
      membreAffiche: []
    }
  },
  methods: {
    changerAffiche: function (comp) {
      document.getElementById(this.affiche).classList.remove('active')
      this.affiche = comp
      document.getElementById(comp).classList.add('active')
    },
    afficherProfil: function (membre) {
      this.afficheProfil = true
      this.membreAffiche = membre
    },
    cacherProfil: function () {
      this.membreAffiche = []
      this.afficheProfil = false
    },
    ajouterAnnuaire: function () {
      this.$refs.AP.ajouterAnnuaire(this.membreAffiche.numero_membre)
      this.$refs.AG.ajouterAnnuaire(this.membreAffiche, 1)
    },
    supprimerAnnuaire: function () {
      this.$refs.AP.supprimerAnnuaire(this.membreAffiche.numero_membre)
      this.$refs.AG.supprimerAnnuaire(this.membreAffiche)
    }
  }
}

</script>
<style scoped>
.nav {
  margin-bottom: 1vh !important;
}

</style>
