<template>
  <article class="columns is-multiline">
    <div
      v-for="membre in liste"
      :key="membre"
      @click="voirProfil(membre)"
      class="column unSeul is-12-tablet is-6-desktop">
      <div class="columns is-vcentered">
        <div class="column">
          <p>{{ membre.prenom }} {{ membre.nom.toUpperCase() }}</p>
        </div>
        <div class="column">
          <p>{{ $store.getters.getStatut(membre.statut) }}</p>
        </div>
        <div class="column" style="height: 7rem;">
          <div class="tags">
            <span
            v-for="tag in membre.competences.slice(0,3)"
            :key="tag"
            class="tag is-dark is-normal"
            > {{ tag }}</span>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import axios from 'axios'
export default {
  name: 'MembreGroupe',
  emits: ['afficher-profil'],
  data () {
    return {
      liste: []
    }
  },
  created: function () {
    this.$store.commit('chargement')
    axios.post(this.$store.getters.url + 'back/Annuaire/ChargerMembre.php?i=' + this.$store.getters.numMembre, {
      groupe: this.$store.getters.infoMembre('groupe')
    }).then((reponse) => {
      if (reponse.data.etat === 1) {
        this.liste = reponse.data.donnees
        this.liste.sort(function (a, b) {
          return a.nom.toLowerCase() < b.nom.toLowerCase() ? -1 : a.nom.toLowerCase() > b.nom.toLowerCase() ? 1 : 0
        })
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: reponse.data.message, type: 'erreur' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
    })
  },
  methods: {
    voirProfil: function (membre) {
      this.$emit('afficher-profil', membre)
    }
  }
}
</script>

<style scoped>
.boutonListe {
  text-align: center;
}
.unSeul > div {
  padding: 0.3rem!important;
  cursor: pointer;
}
@media (prefers-color-scheme: light) {
  .unSeul > div {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .unSeul > div {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
.unSeul > div {
  height: 100%;
  border-radius: 6px;
}
.tags {
  margin-left: 0.5rem;
  display: grid!important;
}
.tag {
  margin-right: 0!important;
}
</style>
