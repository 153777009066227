<template>
  <div class="option columns">
    <div class="field barreRecherche column">
      <div class="control has-icons-left">
        <input @keyup="rechercher" type='text' class='input' ref="barreRecherche" placeholder="Rechercher" v-model="valeur">
        <span class="icon is-left">
          <i class="fad fa-search"></i>
        </span>
      </div>
    </div>
    <div class="field column has-text-right">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="control listeRadio">
            <div
            v-for="filtre in filtres"
            :key="filtre">
              <input @change="rechercher" class="is-checkradio" type="radio" :id="filtre.toLowerCase()" :value="filtre.toLowerCase()" v-model="filtreValeur">
              <label :for="filtre.toLowerCase()">{{ filtre }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'bulma-checkradio/dist/css/bulma-checkradio.min.css'
export default {
  name: 'Rechercher',
  emits: ['recherche', 'annulerRecherche'],
  props: ['filtres'],
  data () {
    return {
      valeur: '',
      filtreValeur: ''
    }
  },
  created: function () {
    this.filtreValeur = this.filtres[0].toLowerCase()
  },
  methods: {
    rechercher: function () {
      if (this.valeur.length > 0) {
        this.$emit('recherche', { valeur: this.valeur, filtre: this.enleverAccent(this.filtreValeur) })
        this.$refs.barreRecherche.focus()
      } else {
        this.$emit('annulerRecherche')
      }
    },
    enleverAccent: function (valeur) {
      return valeur
        .replace('é', 'e')
        .replace('è', 'e')
        .replace('ê', 'e')
        .replace('à', 'a')
        .replace('â', 'a')
    }
  }
}
</script>

<style scoped>
.listeRadio {
  display: inline-flex;
}
.barreRecherche {
  margin-bottom: 0.2rem!important;
}
.option {
  display: flex!important;
}
</style>
