<template>
  <div>
    <apexchart
        width="100%"
        height="400px"
        type="bar"
        :options="chartOptions"
        :series="series"
      />
    <div v-show="afficheRien" class="columns is-centered" style="position: absolute;width: 100%;top: 50%;">
      <h6 class="subtitle has-text-info" style="font-weight: bold;">Aucune donnée</h6>
    </div>
    <div v-show="chargementDonnees" class="columns is-centered" style="position: absolute;width: 100%;top: 50%;background-image: url('/images/site/loader.gif');background-repeat: no-repeat;background-position: center;background-size: contain;height: 4rem;">
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import axios from 'axios'

export default {
  name: 'RequeteSite',
  props: ['projet'],
  emits: ['getTempsSite'],
  components: {
    apexchart: VueApexCharts
  },
  data () {
    return {
      tempsSite: {},
      afficheRien: false,
      chargementDonnees: true,
      chartOptions: {
        chart: {
          id: 'TempsPage',
          toolbar: {
            show: true
          },
          events: {
            updated: () => {
              this.chargementDonnees = false
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 10,
            dataLabels: {
              position: 'top'
            }
          }
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        legend: {
          labels: {
            useSeriesColors: true
          }
        },
        xaxis: {
          categories: ['Accueil'],
          labels: {
            style: {
              colors: '#01BBC8'
            }
          },
          crosshairs: {
            show: false,
            width: 0
          }
        },
        title: {
          text: 'Temps moyen par page (minutes)',
          style: {
            color: '#ffffff'
          }
        }
      },
      series: [{
        name: 'Minimum',
        data: []
      }, {
        name: 'Moyenne',
        data: []
      }, {
        name: 'Maximum',
        data: []
      }]
    }
  },
  created: function () {
    axios.get(this.$store.getters.url + 'back/Statistiques/ChargerPage.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet).then((reponse) => {
      if (reponse.data.etat === 1) {
        // Catégories
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            categories: reponse.data.categories
          }
        }
        // Series
        this.series = [{
          name: 'Minimum',
          data: reponse.data.min
        }, {
          name: 'Moyenne',
          data: reponse.data.moy
        }, {
          name: 'Maximum',
          data: reponse.data.max
        }]
        // Temps site
        this.tempsSite = {
          min: reponse.data.minSite,
          moy: reponse.data.moySite,
          max: reponse.data.maxSite
        }
      } else if (reponse.data.etat === 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Vous n\'avez pas les droits de lecture sur cette onglets.', type: 'attention' })
      } else {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
      }
    }).catch((error) => {
      console.log(error)
    }).then(() => {
      this.$store.commit('finChargement')
      this.suite()
      this.getTempsSite()
    })
  },
  methods: {
    suite: function () {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        this.changerTheme(event.matches)
      })
      this.changerTheme(window.matchMedia('(prefers-color-scheme: dark)').matches)
    },
    changerTheme: function (e) {
      if (e) {
        // Theme = dark
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            labels: {
              style: {
                colors: '#01BBC8'
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#01BBC8'
              }
            }
          },
          title: {
            text: 'Temps moyen par page (minutes)',
            style: {
              color: '#ffffff'
            }
          }
        }
      } else {
        // Theme = light
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            labels: {
              style: {
                colors: '#47479F'
              }
            }
          },
          yaxis: {
            labels: {
              style: {
                colors: '#47479F'
              }
            }
          },
          title: {
            text: 'Temps moyen par page (minutes)',
            style: {
              color: '#4a4a4a'
            }
          }
        }
      }
    },
    getTempsSite: function () {
      return this.$emit('getTempsSite', this.tempsSite)
    }
  }
}
</script>

<style type="text/css">
@media (prefers-color-scheme: dark) {
  .apexcharts-menu > div {
    color: black!important;
  }
  .button[selected='true'] {
    background-color: #0174CF!important;
    color: white!important;
    border: none!important;
  }
  .apexcharts-tooltip.apexcharts-theme-light, .apexcharts-xaxistooltip, .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
    background: #0174CF!important;
  }
  .apexcharts-boxPlot-area {
    stroke: white!important;
  }
}
@media (prefers-color-scheme: light) {
  .button[selected='true'] {
    background-color: #47479F!important;
    color: white!important;
    border: none!important;
  }
}
</style>
