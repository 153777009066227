<template>
  <div class="option lat">
    <span
      v-if="!active"
      @click="supprimer"
      class="icon is-medium has-text-danger fauxLien has-tooltip-bottom"
      title="Supprimer">
      <i class="fad fa-trash"></i>
    </span>
    <span
      v-if="active"
      @click="annuler"
      class="icon is-medium has-text-danger fauxLien has-tooltip-bottom"
      title="Annuler la suppression">
      <i class="fad fa-ban"></i>
    </span>
    <span v-if="active" style="width: 1vh;"></span>
    <span
      v-if="active"
      @click="valider"
      class="icon is-medium has-text-primary fauxLien has-tooltip-left"
      title="Valider la suppression">
      <i class="fad fa-check"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: 'Supprimer',
  emits: ['supprimer', 'annulerSuppression', 'validerSuppression'],
  data () {
    return {
      active: false,
      aide: true
    }
  },
  methods: {
    supprimer: function () {
      this.active = true
      if (this.aide) {
        this.$store.commit('afficherNotification', { affiche: true, texte: 'Selectionnez les éléments en cliquant dessus.', type: 'info' })
        this.aide = false
      }
      this.$emit('supprimer')
    },
    annuler: function () {
      this.active = false
      this.$emit('annulerSuppression')
    },
    valider: function () {
      this.$emit('validerSuppression')
    },
    validerOk: function () {
      this.active = false
    }
  }
}
</script>
