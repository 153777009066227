<template>
  <div class="modal is-active">
    <div class="modal-background" @click="fermerModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered"><b>Gestion des statistiques de base</b></p>
      </header>
      <section class="modal-card-body">
        <p class="has-text-centered">Toutes les données recueillies sont anonymes. Certaines statistiques ont besoin de manipulation de code, veuillez lire la documentation technique.</p>
        <br>
        <div class="columns is-centered">
          <div class="column is-9">
            <p><b>Récupérer le nombre de requête au site par jour</b></p>
          </div>
          <div class="column has-text-right">
             <SwitchLive :html="['', '']" :etat="requete" ref="switchRequete" switchId="switchRequete" @changerValeur="getValeurSwitch"/>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-9">
            <p><b>Localiser le pays de vos utilisateurs (via l'adresse IP)</b></p>
          </div>
          <div class="column has-text-right">
             <SwitchLive :html="['', '']" :etat="localisation" ref="switchLocalisation" switchId="switchLocalisation" @changerValeur="getValeurSwitch"/>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-9">
            <p><b>Récupérer le nombre de connexion par jour</b></p>
            <div class="field is-horizontal" v-show="connexion === 1"><br>
              <div class="field-label is-normal">
                <label class="label">Hash</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type='text' class='input' placeholder="connecte" v-model="connexionHash" @keypress="notKey($event)">
                    <span class="icon is-left">
                      <i class="fad fa-hashtag"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column has-text-right">
             <SwitchLive :html="['', '']" :etat="connexion" ref="switchConnexion" switchId="switchConnexion" @changerValeur="getValeurSwitch"/>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-9">
            <p><b>Récupérer le nombre d'inscription par jour</b></p>
            <div class="field is-horizontal" v-show="inscription === 1"><br>
              <div class="field-label is-normal">
                <label class="label">Hash</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type='text' class='input' placeholder="inscrit" v-model="inscriptionHash" @keypress="notKey($event)">
                    <span class="icon is-left">
                      <i class="fad fa-hashtag"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column has-text-right">
             <SwitchLive :html="['', '']" :etat="inscription" ref="switchInscription" switchId="switchInscription" @changerValeur="getValeurSwitch"/>
          </div>
        </div>
        <div class="columns is-centered" v-show="inscription === 1">
          <div class="column is-12">
            <p><b>Affichage du nombre d'inscrit :</b></p>
            <br>
            <div class="field" style="margin-left: 1.5rem;">
              <div class="field-body">
                <input type="radio" class="is-checkradio" name="afficheNbrInscrit" id="basic" value="1" v-model="nbrInscrit">
                <label for="basic">Calculer le graphique du nombre d'inscription par jour (ne prend pas en compte les désinscriptions)</label>
              </div>
              <div class="field">
                <input type="radio" class="is-checkradio" name="afficheNbrInscrit" id="api" value="2" v-model="nbrInscrit">
                <label for="api">Via une API sur votre serveur (voir documentation technique)</label>
              </div>
            </div>
            <div class="field is-horizontal" v-if="parseInt(nbrInscrit) === 2">
              <div class="field-label is-normal">
                <label class="label">Lien API</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icons-left">
                    <input type='text' class='input' placeholder="https://www.exemple.com/api/nombreInscrit.php" v-model="lienApi">
                    <span class="icon is-left">
                      <i class="fad fa-link"></i>
                    </span>
                  </div>
                  <p class="help is-info" v-if="helps.info">Connexion à votre API ...</p>
                  <p class="help is-success" v-if="helps.success">Connexion réussi, vous avez {{ nbrInscritApi }} inscrits</p>
                  <p class="help is-danger" v-if="helps.danger">Echec de la connexion : {{ erreurConnexionApi }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <span class="card-footer-item fauxLien">
          <span class="icon has-text-danger is-small" @click="annuler()" title="Annuler">
            <i class="fad fa-ban fa-2x"></i>
          </span>
        </span>
        <span class="card-footer-item fauxLien">
          <span class="icon has-text-primary is-small" @click="valider()" title="Valider">
            <i class="fad fa-check fa-2x"></i>
          </span>
        </span>
      </footer>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="fermerModal"></button>
  </div>
</template>

<script>
import SwitchLive from '@/components/Formulaire/SwitchLive.vue'
import axios from 'axios'

export default {
  name: 'GestionGeneral',
  props: ['projet'],
  emits: ['fermer'],
  components: {
    SwitchLive
  },
  data () {
    return {
      projetInfo: this.projet,
      requete: 0,
      localisation: 0,
      connexion: 0,
      connexionHash: '',
      inscription: 0,
      inscriptionHash: '',
      nbrInscrit: 0,
      lienApi: '',
      nbrInscritApi: 0,
      erreurConnexionApi: '',
      helps: {
        info: false,
        success: false,
        erreur: false
      }
    }
  },
  created: function () {
    this.requete = this.projet.statistique_requetesite
    this.localisation = this.projet.statistique_localisation
    this.connexion = this.projet.statistique_connexion
    this.connexionHash = this.projet.statistique_connexionHash
    this.inscription = this.projet.statistique_inscription
    this.inscriptionHash = this.projet.statistique_inscriptionHash
    this.nbrInscrit = this.projet.statistique_inscrit
    this.lienApi = this.projet.statistique_inscritApi
  },
  methods: {
    fermerModal: function () {
      this.$emit('fermer')
    },
    getValeurSwitch: function (item) {
      this[item.ref.replace('switch', '').toLowerCase()] = parseInt(item.valeur)
    },
    annuler: function () {
      this.requete = this.projet.statistique_requetesite
      this.localisation = this.projet.statistique_localisation
      this.connexion = this.projet.statistique_connexion
      this.connexionHash = this.projet.statistique_connexionHash
      this.inscription = this.projet.statistique_inscription
      this.inscriptionHash = this.projet.statistique_inscriptionHash
      this.nbrInscrit = this.projet.statistique_inscrit
      this.lienApi = this.projet.statistique_inscritApi
      this.nbrInscritApi = 0
      this.erreurConnexionApi = ''
      this.helps = {
        info: false,
        success: false,
        erreur: false
      }
    },
    notKey: function (event) {
      if (/^[a-zA-Z0-9]+$/.test(event.key)) {
        event.returnValue = true
      } else {
        event.returnValue = false
      }
    },
    verifForm: function () {
      let i = 0
      let message = ''
      // Test le hash de connexion
      if (this.connexionHash === '' && this.connexion === 1) {
        i++
        message += 'Aucun hash de connexion n\'a été défini.<br>'
      }
      // Test le hash d'inscription
      if (this.inscriptionHash === '' && this.inscription === 1) {
        i++
        message += 'Aucun hash d\'inscription n\'a été défini.<br>'
      }
      // Test le lien vers api
      if (parseInt(this.nbrInscrit) === 2) {
        // Test s'il y a un lien
        if (this.lienApi === '') {
          i++
          message += 'Aucun lien pour l\'API n\'a été défini.<br>'
          this.verifFormRetour(i, message)
        } else if (!this.$store.state.regex.lien.test(this.lienApi)) {
          // Test de la validité du lien
          i++
          message += 'Le lien de l\'API n\'est conforme.<br>'
          this.verifFormRetour(i, message)
        } else {
          // Si le lien est OK tester la réponse du lien
          this.helps.info = true
          axios.get(this.lienApi).then((reponse) => {
            this.helps.info = false
            this.helps.success = true
            this.helps.danger = false
            this.nbrInscritApi = parseInt(reponse.data.nbrInscrit)
            this.verifFormRetour(i, message)
          }).catch((error) => {
            i++
            message += 'Votre API est introuvable !<br>'
            this.helps.info = false
            this.helps.danger = true
            this.helps.success = false
            this.erreurConnexionApi = error.message
            this.verifFormRetour(i, message)
          })
        }
      } else {
        this.verifFormRetour(i, message)
      }
    },
    verifFormRetour: function (i, message) {
      if (parseInt(i) > 0) {
        this.$store.commit('afficherNotification', { affiche: true, texte: message, type: 'erreur' })
      } else {
        this.valider(true)
      }
    },
    valider: function (valide = false) {
      // Vérifier le formulaire
      if (valide) {
        this.$store.commit('chargement')
        const donnees = {
          requete: this.requete,
          localisation: this.localisation,
          connexion: this.connexion,
          connexionHash: this.connexionHash,
          inscription: this.inscription,
          inscriptionHash: this.inscriptionHash,
          nbrInscrit: this.nbrInscrit,
          lienAPI: this.lienApi
        }
        axios.post(this.$store.getters.url + 'back/Statistiques/ModifierGeneral.php?i=' + this.$store.getters.numMembre + '&p=' + this.projet.numProjet, {
          donnees: donnees
        }).then((reponse) => {
          if (reponse.data.etat === 1) {
            // Changement des valeurs en front
            this.projetInfo.statistique_requetesite = this.requete
            this.projetInfo.statistique_localisation = this.localisation
            this.projetInfo.statistique_connexion = this.connexion
            this.projetInfo.statistique_connexionHash = this.connexionHash
            this.projetInfo.statistique_inscription = this.inscription
            this.projetInfo.statistique_inscriptionHash = this.inscriptionHash
            this.projetInfo.statistique_inscrit = this.nbrInscrit
            this.projetInfo.statistique_inscritApi = this.lienApi

            // Remise à zéro
            this.nbrInscritApi = 0
            this.erreurConnexionApi = ''
            this.helps = {
              info: false,
              success: false,
              erreur: false
            }
            // Notification
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Mise à jour du kit avec succès.', type: 'ok' })
            this.fermerModal()
          } else if (reponse.data.etat === 0) {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Un problème est survenu', type: 'erreur' })
          } else {
            this.$store.commit('afficherNotification', { affiche: true, texte: 'Erreur d\'identifiant', type: 'erreur' })
          }
        }).catch((error) => {
          console.log(error)
        }).then(() => {
          this.$store.commit('finChargement')
        })
      } else {
        this.verifForm()
      }
    }
  }
}
</script>

<style scoped>
.modal-background {
  cursor: pointer;
}
@media (prefers-color-scheme: light) {
  .modal-card-body {
    background-color: hsl(0, 0%, 98%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
@media (prefers-color-scheme: dark) {
  .modal-card-body {
    background-color: hsl(0, 0%, 12%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
  .modal-card-head, .modal-card-foot {
    background-color: hsl(0, 0%, 16%);
    -webkit-box-shadow: 0px 10px 13px -7px #000000;
    box-shadow: 0px 10px 13px -7px #000000;
  }
}
</style>
